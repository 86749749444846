import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';

import { utils, writeFileXLSX } from 'xlsx';

import {SelectionModel} from '@angular/cdk/collections';

// Services
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { AdminService, ProblemNode } from '../../services/admin.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-problems',
  templateUrl: './problems.component.html',
  styleUrls: ['./problems.component.scss']
})
export class ProblemsComponent implements OnInit {
  fullSizeMatcher: MediaQueryList;

  adminFlag: boolean;
  adminSub$: Subscription;

  problemList$: Subscription;
  problemGroups$: Subscription;
  problemGroup:number;
  description:string;
  excelProcessing:boolean=false;
  error:boolean=false;
  excelStatus:string;
  unSavedProblem:boolean=false;

  checklistSelection = new SelectionModel<ProblemNode>(true /* multiple */);
  expandedNodes:ProblemNode[];

  treeControl = new NestedTreeControl<ProblemNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ProblemNode>();

  hasChild = (_: number, node: ProblemNode) => !!node.children && node.children.length > 0;  
  hasNoContent = (_: number, _nodeData: ProblemNode) => _nodeData.Problem === '';

  constructor(    
    public dialogRef: MatDialogRef<ProblemsComponent>,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private svcAdmin: AdminService,
    private mediaMatcher: MediaMatcher,
    private geaMesCognito: GeaMesCognitoAuthService) { 
      this.fullSizeMatcher = this.mediaMatcher.matchMedia('(min-width: 1024px)');

      // Check to see if this user is an admin
      this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
        this.adminFlag = out;
        if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
      });   

      this.problemGroup = this.inData.problemGroup;
      this.description = this.inData.description;

    }

  ngOnInit(): void {
    this.getProblems();
  }

  getProblems() {
    console.debug("Retrieve Problems");
    if (this.problemList$) this.problemList$.unsubscribe();

    this.problemList$ = this.svcAdmin.problemListDetails(this.problemGroup).subscribe((
      out => {
        console.debug("ProblemList", out);
        this.saveExpandedNodes();
        this.dataSource.data = out.Body;
        this.treeControl.dataNodes = this.dataSource.data;
        this.restoreExpandedNodes();
        
        //this.treeControl.expandAll();
      }
    ));
  }

  /**
   * Save which nodes are expanded, used when reloading the tree datasource after updates.
   */
  saveExpandedNodes() {
    if (this.treeControl.dataNodes) {
      this.expandedNodes = new Array<ProblemNode>();
      this.treeControl.dataNodes.forEach(node => {
          if (node.children && this.treeControl.isExpanded(node)) {
              this.expandedNodes.push(node);

              node.children.forEach(childNode => {
                if (childNode.children && this.treeControl.isExpanded(childNode)) {
                    this.expandedNodes.push(childNode);
                }
            });
          }
      });

      console.debug("Expanded Nodes", this.expandedNodes);
    }
  }

  /**
   * Restore the nodes that were saved as expanded to expanded state, used when reloading the tree datasource after updates.
   */
  restoreExpandedNodes() {
    if (this.expandedNodes) {
      this.expandedNodes.forEach(node => {
          this.treeControl.expand(this.treeControl.dataNodes.find(n => n.Problem === node.Problem));
      });

      this.treeControl.dataNodes.forEach(node => {
        this.expandedNodes.forEach(childNode => {
          this.treeControl.expand(node.children.find(n => n.Problem === childNode.Problem));
        });          
      });
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  SelectionToggle(node: ProblemNode): void {
    if (!this.checklistSelection.selected.find( ({ Problem }) => Problem === node.Parent)) {
      let uncheck:boolean=this.checklistSelection.selected.find( ({ Problem }) => Problem === node.Problem) ? true : false; // Determine if checking or unchecking
      
      this.checklistSelection.toggle(node);  
      this.checkAllParentsSelection(node,uncheck);
    } else {
      console.debug("Not allowed to unselect child if parent is selected");
      this.refreshTree();
    }
  }  

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ProblemNode, uncheck:boolean): void {
    if (node.children) {
      for (var i in node.children) {
        console.debug(node.children[i]);
        console.debug(this.checklistSelection.selected.find( ({ Problem, Parent }) => Problem === node.children[i].Problem && Parent == node.children[i].Parent));

        if(uncheck || !this.checklistSelection.selected.find( ({ Problem, Parent }) => Problem === node.children[i].Problem && Parent == node.children[i].Parent)) {
          console.debug("recording");
          this.checklistSelection.toggle(node.children[i]);

          if (node.children[i].children) {
            this.checkAllParentsSelection(node.children[i], uncheck);
          }

        } else {
          console.debug("Already selected");
        }
      }
    }
  }

  /**
   * Delete all ProblemNodes that are checked
   */
  deleteSelectedProblems() {
    if (this.checklistSelection.selected.length == 0) {
      alert("Check the problems you would like to delete.");
    } else if (confirm(`Are you sure you want to delete checked problems?`)) {
      this.svcAdmin.problemDelete(this.problemGroup, this.checklistSelection.selected).subscribe(( 
        out => {
          console.debug("ProblemDelete", out);
          this.getProblems();
          this.checklistSelection.clear();
        }
      ))
    }    
  }


  addTopLevelItem() {
    let node:ProblemNode = {
      Parent: "NONE",
      Problem: "",
      Level: 0,
      isChild: false
    }

    this.addNewItem(node);
  }

  /** 
   * Add a new blank ProblemNode so the user can type in a new Problem
   */
  addNewItem(node: ProblemNode) {
    console.debug("AddNewItem",node);
    this.checklistSelection.clear(); // Clear all check boxes when adding a new node
    
    if (this.unSavedProblem) {
      alert("Save Problem before trying to add another.");
    } else {
      this.unSavedProblem = true;

      if (node.Level <= 2) {
        let newNode:ProblemNode = {
          Problem: "",
          Level: node.Level + 1,
          Parent: node.Problem,
          isChild: true
        };

        if (node.Level == 0) {
          this.dataSource.data.push(node);
        } else if (node.Level == 1) {
          this.dataSource.data.find( ({ Problem }) => Problem === node.Problem ).children.push(newNode);
        } else if (node.Level == 2) {
          let parentIdx:number = this.dataSource.data.findIndex( ({ Problem }) => Problem === node.Parent );
          let childIdx:number = this.dataSource.data[parentIdx].children.findIndex( ({ Problem }) => Problem === node.Problem );
          
          this.dataSource.data[parentIdx].children[childIdx].children.push(newNode);
          
        }
      } else {
        console.error("Only allow 3 levels deep");
      }
      
      // Expand the node if it isn't already
      if (node.Level == 1) {
        this.treeControl.expand(this.treeControl.dataNodes.find(n => n.Problem === node.Problem));
      } else if (node.Level == 2) {
        let idx = this.treeControl.dataNodes.findIndex(n => n.Problem === node.Parent);
        this.treeControl.expand(this.treeControl.dataNodes[idx]['children'].find(n => n.Problem === node.Problem));
      }

      this.refreshTree();
    }
  }

  /**
   * Cancel node creation
   */
  cancelNode() {
    this.unSavedProblem = false;
    this.getProblems();
  }

  /**
   * Force the tree to reload data that is currently in the dataSource.
   */
  refreshTree() {
    let _data = this.dataSource.data;

    this.dataSource.data = null;
    this.dataSource.data = _data;
  }

  /**
   * Save ProblemNode to the database
   * @param node The ProblemNode created by addNewItem
   * @param problem The value of the problem
   */
  saveNode(node:ProblemNode, problem:string) {
    console.debug("Save new", node, problem);

    let myNode = node;

    if (problem.trim() != "" && this.isProblemUsed(problem)) {
      alert(`Problem ${problem} already exists in tree, a particular problem must be unique.`);
    }
    else if (problem.trim() != "") {
      myNode.Problem = problem.trim();
      this.svcAdmin.problemAdd(this.problemGroup, myNode).subscribe(( 
        out => {
          console.debug("ProblemAdd", out);
          this.unSavedProblem = false;
          this.getProblems();
        }
      ))    
    } else {
      this.unSavedProblem = false;
      this.getProblems();
    }
  }

  /**
   * Description: Determines if the problem is already in the tree, if so return true
   * 
   * @param problem Problem looking for
   * @param node Starting node in the tree
   * @returns true if Problem is already in the tree
   */
  isProblemUsed(problem:string, node?:ProblemNode):boolean {
    if (node == undefined) {
      for(var i=0; i< this.dataSource.data.length; i++) {      
        if (this.isProblemUsed(problem, this.dataSource.data[i])) {
          return true;
        }
      }
    } else if (node.Problem.toUpperCase() === problem.toUpperCase()) {
        console.debug("Problem Found", node);
        return true;
    } else if ('children' in node) {
      for(var i=0; i< node.children.length; i++) {      
        if (this.isProblemUsed(problem, node.children[i])) {
          return true;
        }
      }
    }
    
    return false;
  }


  uploadComplete(fileName:string) {
    console.debug("Upload Complete", fileName);
    this.excelProcessing = true;
    if (this.problemGroups$) this.problemGroups$.unsubscribe();

    this.problemGroups$ = this.svcAdmin.problemGroup(this.problemGroup).subscribe((
      out => {
        console.debug("problemGroup", out);

        this.excelStatus = out.Body[0].UpdateStatus;

        if (this.excelStatus == "Update Complete") {
          this.getProblems();
          this.excelProcessing = false;
          if (this.problemGroups$) this.problemGroups$.unsubscribe();
        } else if (this.excelStatus.substr(0,10) != "Processing") {
          this.error = true;
        }
      }
    ));
  }

  clearError() {
    if (this.problemGroups$) this.problemGroups$.unsubscribe();

    this.excelProcessing = false;
    this.error = false;

    this.close();
  }

  downloadExcel() {
    let fileName:string = `${this.description}.xlsx`;
    let data:ProblemExcelRow[] = this.getFlattenData();

    console.log("downloadExcel", data);

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, fileName);
  }

  getFlattenData():ProblemExcelRow[] {
    let rows:ProblemExcelRow[] = [];

    // Flatten out the tree, go thru each top node
    this.dataSource.data.forEach(
      (level1) => {
        let level1Value:string = level1.Problem;

        if (level1.children && level1.children.length > 0) {
          // If this node has children loop thru level 2 nodes
          level1.children.forEach(
            (level2) => {
              let level2Value:string = level2.Problem;
              
              if (level2.children && level2.children.length > 0) {
                // If this node has children loop thru level 3 nodes
                level2.children.forEach(
                  (level3) => {
                    let level3Value:string = level3.Problem;
                    rows.push({
                      LEVEL1: level1Value,
                      LEVEL2: level2Value,
                      LEVEL3: level3Value
                    });
                  }
                );
      
              } else {
                // No children, put a record in for this level
                rows.push({
                  LEVEL1: level1Value,
                  LEVEL2: level2Value,
                  LEVEL3: ""
                });
              }
            }
          );

        } else {
          // No children, put a record in for this level
          rows.push({
            LEVEL1: level1Value,
            LEVEL2: "",
            LEVEL3: ""
          });
        }
      }
    );

    return rows;
  }

  close() {
    this.dialogRef.close();
  }  

}

export type ProblemExcelRow = {
  LEVEL1: string,
  LEVEL2: string,
  LEVEL3: string
}
