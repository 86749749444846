import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MetricsService } from 'src/app/services/metrics.service';
import { TooltipValues } from '../metrics/metrics.component';
import { ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-metrics-header-fab',
  templateUrl: './metrics-header-fab.component.html',
  styleUrls: ['./metrics-header-fab.component.scss']
})
export class MetricsHeaderFabComponent implements OnInit, OnDestroy {

  @Input() object:ObjectNode;
  @Input() section:string="";

  tooltipValues:TooltipValues = new TooltipValues();

  metricsHeaderSub$: Subscription;
  metricsHeaderData:any = {};
  tooltipValuePerfect:string = this.tooltipValues.perfect;
  tooltipValueTarget:string = this.tooltipValues.target;

  constructor(
    private svcMetrics:MetricsService
  ) {   }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    let offset:string = (new Date().getTimezoneOffset() / 60).toString().padStart(4, "0");

    this.metricsHeaderSub$ = this.svcMetrics.metricsHeader(this.object).subscribe(
      out => {
        if (out) {
          this.metricsHeaderData = out.Body.PRODUCTION;

          this.metricsHeaderData.EventStart = new Date(Date.parse(this.metricsHeaderData.EventStart + "-" + offset));

          if (this.metricsHeaderData.OAActual == -999) {
            // Value of -999 means Proficy isn't setup correctly for OA
            this.tooltipValues.oa = "Not Configured, reach out to FIS to setup OA in Proficy."
          } else {
            // Reset back to original tooltip if an OAActual starts showing up
            this.tooltipValues.oa = "OA from Proficy";
          }
        }
      }
    );
  }

  navigateToCurrentProblem(e:Event):void { 
    console.log("Navigate to Current Problem");
    e.stopPropagation(); // Prevent this from progating to click event on outter DIV
  }

  floor(value:number):number {
    return Math.floor(value);
  }

  ngOnDestroy(): void{ 
    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    this.svcMetrics.cleanupObservers();
  }
}