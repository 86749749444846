import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action, PaMatrixService, Problem } from '../../services/pa-matrix.service';
import * as dayjs from 'dayjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';

@Component({
  selector: 'app-reopen-problem',
  templateUrl: './reopen-problem.component.html',
  styleUrls: ['./reopen-problem.component.scss']
})
export class ReopenProblemComponent implements OnInit {

  problem:Problem;
  userid:string;

  constructor(
    public dialogRef: MatDialogRef<ReopenProblemComponent>,
    private svcPAMatrix:PaMatrixService,
    private svcAuth: GeaMesCognitoAuthService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.problem = this.inData.problemData;
  }

  ngOnInit(): void {
    this.userid = this.svcAuth.getUserAttribute('sso');
  }

  reopen():void {
    console.log("Reopen");

    let action:Action = {
      ItemNumber: this.problem.ItemNumber,
      ActionDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Action: "Problem was reopened",
      EnteredBy: "",
      LoggedBy: this.userid
    }

    this.problem.ClosedDate = null;
    this.problem.Status = 1;

    this.svcPAMatrix.problemAddEdit(this.problem).subscribe(
      out => {
        console.log(out);
        
        this.svcPAMatrix.actionAdd(action).subscribe(
          out => {
            console.log(out);

            this.dialogRef.close();
          }
        );
      }
    );

  }

  onNoClick():void {
    console.log("CLose");
    this.dialogRef.close();
  }

}
