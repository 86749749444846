import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryService  extends AbstractRestService {

  postInit(): void {
    console.debug("InventoryService Init Complete");
  }

  inventory(site:string, objectid: string):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;

    return this.get({
      ApiResource: "/inventory",
      data: params,
      repeatCall: false
    })
  }

  update(site:string, objectid: string, PartDescription:string, Prior:number, Actual:number, ShiftDate:string, Shift:string, DateTime:string ):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.PartDescription = PartDescription;
    params.Prior = Prior;
    params.Actual = Actual;
    params.ShiftDate = ShiftDate;
    params.Shift = Shift;
    params.DateTime = DateTime;

    return this.post({
      ApiResource: "/inventory",
      data: params,
      repeatCall: false
    })
  }

}
