import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AdminService, TeamGroup } from '../../services/admin.service';
import { TeamsDialogComponent } from '../teams-dialog/teams-dialog.component';

@Component({
  selector: 'app-team-groups',
  templateUrl: './team-groups.component.html',
  styleUrls: ['./team-groups.component.scss']
})
export class TeamGroupsComponent implements OnChanges, OnDestroy {
  @Input() selectedSite:string="";

  adminFlag: boolean;
  adminSub$: Subscription;

  teamGroups$: Subscription;
  mDataSource: TeamGroup[];

  allowDelete: boolean;

  displayedColumns: string[] = ['Description','actions']

  constructor(private svcAdmin: AdminService,
    private geaMesCognito: GeaMesCognitoAuthService,
    public dialog: MatDialog) {

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Changes", changes);
    if (changes.selectedSite.currentValue != "" && changes.selectedSite.currentValue != changes.selectedSite.previousValue) {
      this.getTeamGroups();
    }
  }

  ngOnDestroy() {
    if (this.teamGroups$) this.teamGroups$.unsubscribe();
    if (this.adminSub$) this.adminSub$.unsubscribe();
  }   

  getTeamGroups() {
    if (this.teamGroups$) this.teamGroups$.unsubscribe();

    this.teamGroups$ = this.svcAdmin.teamGroupsGet(this.selectedSite).subscribe((
      out => {
        console.debug("TeamGroups", out);
        // Sort data and store in mDataSource
        this.mDataSource = out.Body.sort((n1,n2) => {
              if (n1.Description > n2.Description) {
                  return 1;
              }
          
              if (n1.Description < n2.Description) {
                  return -1;
              }
          
              return 0;
          });;
          this.allowDelete = true;
      }
    ));
  }

  addNewTeamGroup() {
    console.debug("addNewTeamGroup");
    let newTeamName:string = prompt("Team Description","");
      
    newTeamName = newTeamName.trim();
  
      if (newTeamName != "") {
  
        if (this.mDataSource.findIndex( ({ Description }) => Description.toUpperCase() === newTeamName.toUpperCase() ) >= 0) {
          alert("Team Description already exists!");
        } else {
          this.svcAdmin.teamGroupAdd(newTeamName).subscribe(( 
            out => {
              console.debug("addNewTeamGroup", out);
              this.getTeamGroups();
            }
          ))  
        }
      }
  }  

  editTeamGroup(teamGroup:number):void {
    let idx:number = this.mDataSource.findIndex( ({ TeamGroup }) => TeamGroup === teamGroup );
    let description:string = this.mDataSource[idx]["Description"];

    const dialogRef = this.dialog.open(TeamsDialogComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        type: "EDIT",
        teamData: { 
          "TeamGroup": teamGroup,
          "Description": description
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.allowDelete == false;
      this.getTeamGroups();
    });

  }

  deleteTeamGroup(teamGroup:number):void {
    console.debug("deleteTeamGroup",teamGroup);
    // Determine if any teams exist
    let idx:number = this.mDataSource.findIndex( ({ TeamGroup }) => TeamGroup === teamGroup );
    let count:number = this.mDataSource[idx]["Teams"].length;
    let description:string = this.mDataSource[idx]["Description"];
    
    if (count == 1 && (this.mDataSource[idx]["Teams"][0]["Team"] == "" || this.mDataSource[idx]["Teams"][0]["Team"] == null)) {
      count = 0;
    }

    if (count > 0) {
      alert("Delete all teams before deleting the team group!");
    } else if (confirm(`Are you sure you want to delete ${description}?`)) {
      this.svcAdmin.teamGroupDelete(teamGroup).subscribe(( 
        out => {
          console.debug("deleteTeamGroup", out);
          if (out.Body == "Recorded NOT Deleted") {
            alert(`Team Group ${description} not deleted, configured on one or more objects.`);
          }
          this.getTeamGroups();
        }
      ));
    }
  }
}
