import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MetricsService } from 'src/app/services/metrics.service';
import { ObjectNode } from 'src/app/services/object-list.service';
import { TooltipValues } from '../metrics/metrics.component';

@Component({
  selector: 'app-metrics-detail-assembly',
  templateUrl: './metrics-detail-assembly.component.html',
  styleUrls: ['./metrics-detail-assembly.component.scss']
})
export class MetricsDetailAssemblyComponent implements OnInit {
  @Input() object:ObjectNode;
  @Input() section:string="problems"; // This should be an input
  
  
  alertsEnabled:boolean;
  objectType:string = "ASSEMBLY";

  tooltipValues:TooltipValues = new TooltipValues();

  metricsHourSub$: Subscription;
  metricsHourData:[];

  metricsTopProblemsSub$: Subscription;
  metricsTopProblemsData:any = [];

  problemsExist:number[] = [0];

  currentHour:number = 0;

  partCountSub$: Subscription;
  partCount:any={};

  constructor(
    private router: Router,
    private svcMetrics:MetricsService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (this.object.PerJobObject != '') {
      this.getPartCount();
    }

    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();

    this.metricsHourSub$ = this.svcMetrics.metricsHour(this.object).subscribe(
      out => {
        if (out) {
          this.metricsHourData = out.Body.Hours;
          this.setCurrentHour(); // TODO: Consider having metricsHeader return current Hour to eliminate need for this
        }
      }
    );

    // TODO: Consider merging metrichours and metrictopproblems in future
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();

    this.metricsTopProblemsSub$ = this.svcMetrics.metricsTopProblems(this.object).subscribe(
      out => {
        if (out) {
          this.metricsTopProblemsData = out.Body;

          for (let idx:number = 0; idx < this.metricsTopProblemsData.length; idx++) {
            this.problemsExist[idx] = this.metricsTopProblemsData[idx]['Problems'].length;
          }
        }
      }
    );
  }

  getPartCount() {
    if (this.partCountSub$) this.partCountSub$.unsubscribe();

    this.partCountSub$ = this.svcMetrics.partCount(this.object).subscribe(
      out => {
        if (out) {
          let partCount = out.Body;
          let myPartCount = {};
          partCount.forEach( part => {
            if (part.Hour in myPartCount) {
              myPartCount[part.Hour].push({PartNumber: part.PartNumber, Quantity: part.Quantity, JobID: part.JobID, JobDescription:part.JobDescription});
            } else {
              myPartCount[part.Hour] = [{PartNumber: part.PartNumber, Quantity: part.Quantity, JobID: part.JobID, JobDescription:part.JobDescription}];
            }
          })
          this.partCount = myPartCount;
        }
      }
    )

  }

  setCurrentHour() {
    for (let idx:number = 0; idx < this.metricsHourData.length; idx++) {
      if (this.metricsHourData[idx]['Plan'] == 0) {
        this.currentHour = idx;
        break;
      } else {
        this.currentHour = idx - 1 ;
      }
    }
  }

  navigateToProblems(hour:number):void { 
    console.log("Navigate to Problems", hour);
    let hourParam:string = (hour == -1 ? 'ALL' : hour.toString());
    this.router.navigate(["/problemlist",this.object.Site, this.object.ObjectID, hourParam]);
  }

  floor(value:number):number {
    return Math.floor(value);
  }

  getLostUnits(hour: number, cycleTime: number) {
    var totalDownTime = 0;
    this.metricsTopProblemsData[hour - 1]['Problems'].forEach((x) => {
      var a = x['Duration'].split(':');
      totalDownTime += (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
    })

    return (Math.round(totalDownTime/cycleTime))
  }

  ngOnDestroy(): void{ 
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();
    if (this.partCountSub$) this.partCountSub$.unsubscribe();

    this.svcMetrics.cleanupObservers();
  }

}
