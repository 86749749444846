import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  objectID: string;
  site: string;
  problemIDs: number[];
  changeType:string='delete';

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public deleteData: any,
    private svcMetrics:MetricsService,
  ) { 
    console.debug("deleteData", deleteData);
    this.objectID = deleteData.objectid;
    this.site = deleteData.site;
    this.problemIDs = deleteData.problemids;
    this.changeType = deleteData.changeType;
  }

  deleteProblem(){
    console.debug("deleteProblems", this.site, this.objectID, this.problemIDs);
    this.svcMetrics.deleteProblem(this.site, this.objectID, this.problemIDs).subscribe(
      out => {
        console.log(out)
        this.dialogRef.close({
          status: 'deleted'
        })
      } 
    )
  }

  clearProblem(){
    console.debug("clearProblem", this.site, this.objectID, this.problemIDs);
    this.svcMetrics.clearProblem(this.site, this.objectID, this.problemIDs).subscribe(
      out => {
        console.log(out)
        this.dialogRef.close({
          status: 'cleared'
        })
      } 
    )
  }  

  onNoClick(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }
}
