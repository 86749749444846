import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { InventoryService } from '../../services/inventory.service';

@Component({
  selector: 'app-part-dialog',
  templateUrl: './part-dialog.component.html',
  styleUrls: ['./part-dialog.component.scss']
})
export class PartDialogComponent implements OnInit {
  
  type:string;

  objectid:string;
  site:string;
  shiftDate:string;
  shift:string;

  partFormGroup:UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<PartDialogComponent>,
    private fb: UntypedFormBuilder,
    private svcInventory: InventoryService,
    @Inject(MAT_DIALOG_DATA) public inData: any

  ) {

    this.type = inData.type;
    this.site = inData.site;
    this.objectid = inData.object;
    this.shiftDate = inData.shiftDate;
    this.shift = inData.shift;

    let PartDescription:string = '';
    let Prior:number = 0;
    let Actual:number = 0;

    if (inData.problemData) {
      PartDescription = inData.problemData.PartDescription;
      Prior = inData.problemData.Prior;
      Actual = inData.problemData.Actual;
    }

    this.partFormGroup = this.fb.group({
      PartDescription: new UntypedFormControl({value: PartDescription, disabled: false}, Validators.required),
      Prior: new UntypedFormControl({value: Prior, disabled: false}, Validators.required),
      Actual: new UntypedFormControl({value: Actual, disabled: false})
    });

   }

  ngOnInit(): void {
  }

  save() {
    let PartDescription:string = this.partFormGroup.get('PartDescription').value;
    let Prior:number = this.partFormGroup.get('Prior').value;
    let Actual:number = this.partFormGroup.get('Actual').value;
    let datetime:string = dayjs().format('YYYY-MM-DD HH:mm:ss')
    
    this.svcInventory.update(this.site, this.objectid, PartDescription, Prior, Actual, this.shiftDate, this.shift, datetime).subscribe(
      out => {
        console.log(out);
        this.dialogRef.close();
      }
    );



  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}
