import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

// Services
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { AdminService } from '../../services/admin.service'
import { ProblemsComponent } from '../problems/problems.component';

@Component({
  selector: 'app-admin-problem-groups',
  templateUrl: './problem-groups.component.html',
  styleUrls: ['./problem-groups.component.scss']
})
export class ProblemGroupsComponent implements OnChanges {

  @Input() selectedSite:string="";

  fullSizeMatcher: MediaQueryList;
  
  problemGroups$: Subscription;
  adminFlag: boolean;
  adminSub$: Subscription;

  mDataSource: any;  

  displayedColumns: string[] = ['Description','actions']

  constructor(    
    private svcAdmin: AdminService,
    private mediaMatcher: MediaMatcher,
    private geaMesCognito: GeaMesCognitoAuthService,
    public dialog: MatDialog) {
    
    this.fullSizeMatcher = this.mediaMatcher.matchMedia('(min-width: 1024px)');

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Changes", changes);
    if (changes.selectedSite.currentValue != "" && changes.selectedSite.currentValue != changes.selectedSite.previousValue) {
      this.getProblemGroups();
    }
  }

  getProblemGroups() {
    if (this.problemGroups$) this.problemGroups$.unsubscribe();

    this.problemGroups$ = this.svcAdmin.problemGroups(this.selectedSite).subscribe((
      out => {
        console.debug("ProblemGroups", out);
        this.mDataSource = out.Body;
      }
    ));
  }

  editProblems(problemGroup:number, description:string): void {
    const dialogRef = this.dialog.open(ProblemsComponent, {
      width: '750px',
      maxHeight: '850px',
      data: {
        problemGroup: problemGroup,
        description: description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("close dialog", result);
      this.getProblemGroups();
    });
  }

  deleteProblemGroup(problemGroup:number, description:string):void {
    console.log("Delete problem group");

    // Determine if any problems exist
    let idx:number = this.mDataSource.findIndex( ({ ProblemGroup }) => ProblemGroup === problemGroup );
    let count:number = this.mDataSource[idx]["Problems"].length;
    
    if (count == 1 && this.mDataSource[idx]["Problems"][0]["Problem"] == "") {
      count = 0;
    }

    if (count > 0) {
      alert("Delete all problems before deleting the problem group!");
    } else if (confirm(`Are you sure you want to delete ${description}?`)) {
      this.svcAdmin.problemGroupDelete(problemGroup).subscribe(( 
        out => {
          console.debug("ProblemDelete", out);
          this.getProblemGroups();
        }
      ));
    }
  }

  addNewProblemGroup() {
    let newGroupName:string = prompt("Problem Group Description","");
    
    newGroupName = newGroupName.trim();

    if (newGroupName != "") {

      if (this.mDataSource.findIndex( ({ Description }) => Description === newGroupName ) >= 0) {
        alert("Problem Group Description already exists!");
      } else {
        this.svcAdmin.problemGroupAdd(newGroupName).subscribe(( 
          out => {
            console.debug("ProblemGroupAdd", out);
            this.getProblemGroups();
          }
        ))  
      }
    }

    console.log(newGroupName);
  }  

}
