<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>
<div style="padding: 15px">
    <!-- Metric Header-->
    <app-metrics-header [object]="object" section=""></app-metrics-header>
    <!-- Top Rejects -->
    <mat-card  class="metrics-card mat-elevation-z1">
        <mat-card-title class="metric-title">
                Top 5 Rejects
        </mat-card-title>
        <mat-card-content>
            <mat-table [dataSource]="rejectsData.Rejects">
                <ng-container matColumnDef="Description">
                    <mat-cell *matCellDef="let d">{{d.RejectDescription}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Count">
                    <mat-cell *matCellDef="let d">{{d.Count}}</mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>

    <!-- Top Repairs -->
    <mat-card  class="metrics-card mat-elevation-z1">
        <mat-card-title class="metric-title">
                Top 5 Repairs
        </mat-card-title>
        <mat-card-content>
            <mat-table [dataSource]="repairData.Repairs">
                <ng-container matColumnDef="Description">
                    <mat-cell *matCellDef="let d">{{d.RepairDefect}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Count">
                    <mat-cell *matCellDef="let d">{{d.Count}}</mat-cell>
                </ng-container>

                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</div>