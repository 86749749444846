<div><button [disabled]="!adminFlag" mat-icon-button (click)="addNewTeamGroup()"><mat-icon>add</mat-icon>Create Team Group</button></div>

<mat-table [dataSource]="mDataSource">
    <ng-container matColumnDef="Description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let d">{{d.Description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let d; let i = index">
          <button mat-button color="link" (click)="editTeamGroup(d.TeamGroup)"><mat-icon>assignment</mat-icon>
              <span *ngIf="adminFlag">Edit</span><span *ngIf="!adminFlag">View</span> Teams</button>          
          <button [disabled]="!adminFlag || !allowDelete" mat-button color="warn" (click)="deleteTeamGroup(d.TeamGroup)"><mat-icon>delete_forever</mat-icon>Delete</button>
        </mat-cell>
      </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>