
<div class="fx-row-start-center">
    <gea-mes-datepicker
    class="dateRangeBut"
    matTooltip="Select Date" 
    (onDateSelect)="onDateSelection($event)" 
    (onDateInit)="onDateSelection($event)"
    singleDate="false" 
    showLabel="true"
    showCurrentShift="false"
    defaultRange="Today"
    showRangeLabel="true"
    autoApply="true"
    [index]=DatePickerIndex>
    </gea-mes-datepicker>
    <div style="min-width: 144px">
      <button mat-button [disabled]="disableRawgDataView" color="primary" (click)="openTableView()">Raw Data</button>
    </div>
</div>
<div>
    <fusioncharts
    height="80%"
    width="100%"
    type="msstackedcolumn2d"
    dataFormat="JSON"
    [dataSource]="dataSource"
  ></fusioncharts>
</div>