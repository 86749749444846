import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MetricsService, shiftData } from 'src/app/services/metrics.service';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { CustomerDemandLine, OAService } from '../oa.service';
import { MatDialog } from '@angular/material/dialog';
import { DemandDefaultsDialog } from './demand-defaults-dialog/demand-defaults-dialog.component';

@Component({
  selector: 'app-customer-demand',
  templateUrl: './customer-demand.component.html',
  styleUrls: ['./customer-demand.component.scss']
})
export class CustomerDemandComponent implements OnInit, OnDestroy {
  objectid:string;
  site:string;
  environment:string;

  object:ObjectNode;

  proficyOASetup:boolean = true;

  cycleTime:number = 1;
  objectType:string = "ASSEMBLY";
  shiftDate:string;
  shift:string;
  currentShift:boolean;

  customerDemand$:Subscription;
  customerDemandSelected:CustomerDemandLine[];
  customerDemandFuture:CustomerDemandLine[];

  jobSub$:Subscription;
  jobConfig:any[];

  unsavedChanges:{};
  triggerSave:number = 0;

  availShifts:string[];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private svcMetrics:MetricsService,
    private svcObjectList:ObjectListService,
    private svcOA: OAService
  ) { 
    this.unsavedChanges = {
      "CURRENT": false,
      "FUTURE": false
    }

    this.customerDemandSelected = [];
    this.customerDemandFuture = [];

    this.availShifts = ["1ST SHIFT", "2ND SHIFT", "3RD SHIFT"];
  }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
    
    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;

        this.objectType = objectNode.ObjectType;
        this.environment = objectNode.Environment;

        this.getHeaderData();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.jobSub$) this.jobSub$.unsubscribe();
    if (this.customerDemand$) this.customerDemand$.unsubscribe();
  }

  getHeaderData() {
    this.svcMetrics.metricsHeader(this.object).pipe(filter(out => out != null && out['Body'].PRODUCTION.Plant == this.site && out['Body'].PRODUCTION.Name == this.object.ObjectID),take(1)).subscribe(
      out => {
        if (out) {
          let metricsHeaderData = out.Body.PRODUCTION;

          this.cycleTime = out.Body.PRODUCTION.cycleTime;
          
          if (metricsHeaderData["OAActual"] != -999) {
            this.proficyOASetup = true;

            // Load anything we need for Customer Demand at this point
            this.getShiftData();

          } else {
            this.proficyOASetup = false;
          }
        }
      }
    );    
  }

  getShiftData() {
    this.svcMetrics.getShiftData().pipe(filter(out => out != null)).subscribe(
      (shift:shiftData) => {
        console.log("SHIFT DATA!!!", shift);

        this.currentShift = shift.currentShift;
        this.shift = shift.current.shift;
        this.shiftDate = shift.current.shiftDate;

        this.getJobConfig();
      }
    )
  }

  getJobConfig() {
    if (this.jobSub$) this.jobSub$.unsubscribe();

    this.jobSub$ = this.svcObjectList.getJobConfig(this.environment, this.object.ObjectID).subscribe(
      (out) => {
        if (out) {
          this.jobConfig = out.Body;
          console.log("JobConfig", this.jobConfig);
          this.getCustomerDemand();
        }
      }
    )
  }

  getCustomerDemand() {
    if (this.customerDemand$) this.customerDemand$.unsubscribe();

    console.log("Call customer demand", this.shiftDate, this.shift, this.currentShift);
    this.customerDemand$ = this.svcOA.getCustomerDemand(this.object.Environment, this.object.ObjectID, this.shiftDate, this.shift, this.currentShift).subscribe(
      (out) => {
        if (out) {
          this.customerDemandSelected = out.Body.SELECTED;
          this.customerDemandFuture = out.Body.FUTURE;

          this.unsavedChanges = {
            "CURRENT": false,
            "FUTURE": false
          }
        }
      }
    );
  }

  setUpdateStatus(type:string, e:boolean) {
    console.log("setupdatestatus", type, e);
    this.unsavedChanges[type] = e;

    if (this.unsavedChanges['CURRENT'] || this.unsavedChanges['FUTURE']) {
      if (this.customerDemand$) this.customerDemand$.unsubscribe(); // Disable the subscription to customer demand as soon as edits start so we don't overwrite those values
    }
  }

  saveComplete(type:string, e:boolean) {
    this.unsavedChanges[type] = false; // Set that we no longer need changes saved

    if (!this.unsavedChanges['CURRENT'] && !this.unsavedChanges['FUTURE']) {
      this.getCustomerDemand();
    }
  }

  saveChanges() {
    this.triggerSave += 1;
  }

  undoChanges() {
    this.getCustomerDemand();
  }

  openDefaults() {
    const dialogRef = this.dialog.open(DemandDefaultsDialog, {
      width: '900px',
      maxHeight: '800px',
      data: {
        objectid: this.object.ObjectID,
        site: this.object.Site,
        environment: this.object.Environment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug("Dialog Closed", result);
    });
  }

}
