import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss']
})
export class ObjectListComponent implements OnInit, OnDestroy {
  
  
  treeControl = new NestedTreeControl<ObjectNode>(node => node.children);
  teamSource = new MatTreeNestedDataSource<ObjectNode>();
  
  ObjectListSubscription$:Subscription;
  
  hasChild = (_: number, node: ObjectNode) => !!node.children && node.children.length > 0; 
  expandedNodes: ObjectNode[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcObjectList:ObjectListService
  ) { 

  }

  ngOnInit(): void {
    if (this.ObjectListSubscription$) this.ObjectListSubscription$.unsubscribe();    
    this.ObjectListSubscription$ = this.svcObjectList.objectList().subscribe(
      out => {
        this.teamSource.data = out.Body;
        if (this.route.snapshot.paramMap.get("plant_name") !== undefined && this.route.snapshot.paramMap.get("plant_name") !== null){
          this.expandTree("plant_name", out.Body);
        }
      }
    );
    
  }

  ngOnDestroy():void{
    if (this.ObjectListSubscription$) this.ObjectListSubscription$.unsubscribe(); 
  }

  navigate(site: string, objectid: string) {
    console.log("site", site, objectid);
    this.router.navigate(["/metrics", site, objectid]);

  }
    
  expandTree(param: string, nodes: ObjectNode[]){
    if (this.route.snapshot.paramMap.get(param) !== undefined){
      var valid = false;
      var expandedNode: ObjectNode;  
      nodes.forEach(node => {
        var routeParam: string = this.route.snapshot.paramMap.get(param);
        console.log(routeParam)
        if(((node.search).toString()).toLowerCase() == routeParam.toLowerCase()){
          expandedNode = node;
          valid = true;
        }
      });

      if(valid){
        console.debug("Valid " + param + " parameter supplied, expanding " + this.route.snapshot.paramMap.get(param));
        this.treeControl.toggle(expandedNode);
        this.expandedNodes.push(expandedNode);
      }else{
        console.warn("Invalid " + param + " parameter provided");
      }
    }
    }


}
