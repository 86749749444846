import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ObjectNode } from 'src/app/services/object-list.service';
import { TooltipValues } from '../metrics/metrics.component';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-metrics-header-assembly',
  templateUrl: './metrics-header-assembly.component.html',
  styleUrls: ['./metrics-header-assembly.component.scss']
})
export class MetricsHeaderAssemblyComponent implements OnInit, OnDestroy {

  @Input() object:ObjectNode;
  @Input() section:string="problems";
  
  tooltipValues:TooltipValues = new TooltipValues();

  metricsHeaderSub$: Subscription;
  metricsHeaderData:any = {};
  tooltipValuePerfect:string = this.tooltipValues.perfect;
  tooltipValueTarget:string = this.tooltipValues.target;

  constructor(
    private svcMetrics:MetricsService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    let offset:string = (new Date().getTimezoneOffset() / 60).toString().padStart(4, "0");

    this.metricsHeaderSub$ = this.svcMetrics.metricsHeader(this.object).subscribe(
      out => {
        if (out) {
          this.metricsHeaderData = out.Body.PRODUCTION;

          this.metricsHeaderData.EventStart = new Date(Date.parse(this.metricsHeaderData.EventStart + "-" + offset));

          if (this.metricsHeaderData.idealCycleTime !=0) {
            this.tooltipValues.perfect = this.tooltipValuePerfect + '\n' + "(Ideal Cycle Time = " + this.metricsHeaderData.idealCycleTime.toFixed(2) + ")"
          }

          if (this.metricsHeaderData.totalTargetDemand !='') {
          this.tooltipValues.target  = this.tooltipValueTarget +  '\n' + "(Total Shift Target = " + this.metricsHeaderData.totalTargetDemand + ")"
          }

          if (this.metricsHeaderData.OAActual == -999) {
            // Value of -999 means Proficy isn't setup correctly for OA
            this.tooltipValues.oa = "Not Configured, reach out to FIS to setup OA in Proficy."
          } else {
            // Reset back to original tooltip if an OAActual starts showing up
            this.tooltipValues.oa = "OA from Proficy";
          }
        }
      }
    );
  }

  navigateToCurrentProblem(e:Event):void { 
    console.log("Navigate to Current Problem");
    e.stopPropagation(); // Prevent this from progating to click event on outter DIV
  }

  floor(value:number):number {
    return Math.floor(value);
  }

  ngOnDestroy(): void{ 
    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    this.svcMetrics.cleanupObservers();
  }
}
