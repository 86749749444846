
<app-button-bar [site]="site" [objectid]="objectid" shiftSelect="false"></app-button-bar>

<mat-tab-group dynamicHeight>
      <mat-tab label="Pareto">
        <ng-template matTabContent>
          <div style="height: 90vh;">
              <app-pareto DatePickerIndex=1></app-pareto>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Pareto - Comparison">
        <ng-template matTabContent>
          <div style="height: 90vh;">
            <div class="fx-row-start-start">
              <div style="width:49vw;">
                <app-pareto DatePickerIndex=1></app-pareto>
              </div>
              <div style="width:49vw;">
                <app-pareto DatePickerIndex=2></app-pareto>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Planned Downtime" *ngIf="objectType == 'FAB'">
        <ng-template matTabContent>
          <div style="height: 90vh;">
              <app-planned-downtime-chart DatePickerIndex=1></app-planned-downtime-chart>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Data">
        <ng-template matTabContent>
          <div style="height: 90vh;">
              <app-rawdata DatePickerIndex=1></app-rawdata>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Top Root Cause">
        <ng-template matTabContent>
          <div style="height: 90vh;">
              <app-toprootcause DatePickerIndex=1></app-toprootcause>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Top Root Cause - Comparison">
        <ng-template matTabContent>
          <div style="height: 90vh;">
            <div class="fx-row-start-start">
              <div style="width:49vw;">
                <app-toprootcause DatePickerIndex=1></app-toprootcause>
              </div>
              <div style="width:49vw;">
                <app-toprootcause DatePickerIndex=2></app-toprootcause>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>



</mat-tab-group>
