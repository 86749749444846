import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetricsService } from 'src/app/services/metrics.service';
import { Subscription } from 'rxjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-shift-note-dialog',
  templateUrl: './shift-note-dialog.component.html',
  styleUrls: ['./shift-note-dialog.component.scss']
})
export class ShiftNoteDialogComponent implements OnInit, OnDestroy {
  userid:string;
  objectid:string;
  site:string;
  environment:string;

  object:ObjectNode;

  shiftDate:string;
  shift:string;
  selectedID:string ='';
  selectedZone:string = '';

  shiftDataSub$: Subscription;
  shiftNotesSub$: Subscription;
  noteFormGroup:UntypedFormGroup;

  zoneData:any;
  shiftNotes:any;
  filteredShiftNotes:any;

  loadingData:boolean=true;

  displayedColumns: string[] = ['Note', 'Action']

  constructor(
    public dialogRef: MatDialogRef<ShiftNoteDialogComponent>,
    private fb: UntypedFormBuilder,
    private svcMetrics:MetricsService,
    private svcAuth: GeaMesCognitoAuthService,
    private svcObjectList:ObjectListService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;

    this.noteFormGroup = this.fb.group({
      Note: new UntypedFormControl({value: "", disabled: false}),
      Zone: new UntypedFormControl({value: this.zoneData, disabled: false}, Validators.required)
    });
  }

  ngOnInit(): void {
    this.userid = this.svcAuth.getUserAttribute('sso');

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;
        this.environment = objectNode.Environment;

        this.getZones();
      }
    );


  }

  getZones() {
    this.svcMetrics.zones(this.site, this.objectid).subscribe(
      out => {
        this.zoneData = out.Body;

        if (this.zoneData.length > 0) {
          this.selectedZone = this.zoneData[0].zone_id;
        } else {
          this.selectedZone = '';
        }
        
        this.noteFormGroup.get('Zone').setValue(this.selectedZone);
        this.getShiftNotes();
      }
    );
  }

  getShiftNotes() {
    console.log("getShiftNotes()")
    if (this.shiftDataSub$) this.shiftDataSub$.unsubscribe();
    if (this.shiftNotesSub$) this.shiftNotesSub$.unsubscribe();

    // Get the shift data being dispalyed
    
    this.shiftDataSub$ = this.svcMetrics.getShiftData().pipe(filter(out => out != null), take(1)).subscribe(
      out => {
        console.log("SHIFT DATE LOAD!!!", out.current);
        this.shiftDate = out.current.shiftDate;
        this.shift = out.current.shift;

        if (this.shiftNotesSub$) this.shiftNotesSub$.unsubscribe();

        this.shiftNotesSub$ = this.svcMetrics.shiftNoteList(this.site, this.environment, this.objectid, this.shiftDate, this.shift).pipe(filter(out => out != null)).subscribe(
          out => {
            if (out) {
              console.log("LOADING DATA!!!");
              this.loadingData = false;
              this.shiftNotes = out.Body;

              this.updateFilterShiftNotes();
            }
          }
        );
      }
    );

  }

  updateFilterShiftNotes() {
    let zone:string = this.noteFormGroup.get('Zone').value;
    this.selectedZone = zone;

    this.filteredShiftNotes = this.shiftNotes.filter(note => note.Zone == zone);
  }

  saveNote():void {
    console.log("Save Note");

    let note:string = this.noteFormGroup.get('Note').value;
    let zone:string = this.noteFormGroup.get('Zone').value;

    if (note != "") {
      this.svcMetrics.shiftNoteUpdate(this.site, this.objectid, note, this.shiftDate, this.shift, zone, this.selectedID).subscribe(
        out => {
          console.log("Note Saved");
          this.noteFormGroup.patchValue({ Note: ''}); // Reset the form
          this.selectedID = "";

          this.getShiftNotes(); // Reload shift notes

        }
      );
    }
  }

  editNote(shiftnote:any) {
    this.selectedID = shiftnote.id;
    this.noteFormGroup.patchValue({ Note: shiftnote.Note}); 
  }



  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

  ngOnDestroy(): void{ 
    if (this.shiftDataSub$) this.shiftDataSub$.unsubscribe();
    if (this.shiftNotesSub$) this.shiftNotesSub$.unsubscribe();
  }
}
