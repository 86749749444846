import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-metrics-header',
  templateUrl: './metrics-header.component.html',
  styleUrls: ['./metrics-header.component.scss']
})
export class MetricsHeaderComponent implements OnInit, OnDestroy {

  @Input() object:ObjectNode;
  @Input() site:string;
  @Input() environment:string;
  @Input() objectid:string;
  @Input() cycleTime:number = 0; // TODO: REMOVE THIS, IT IS NO LONGER NEEDED
  @Input() section:string="problems";
  @Input() objectType:string;


  constructor(
  ) { }

  ngOnInit(): void {
  }

  navigateToCurrentProblem(e:Event):void { 
    console.log("Navigate to Current Problem");
    e.stopPropagation(); // Prevent this from progating to click event on outter DIV
  }

  floor(value:number):number {
    return Math.floor(value);
  }

  ngOnDestroy(): void{ 
  }

}
