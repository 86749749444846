import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { filter, take } from 'rxjs/operators';
import { OAService, DefaultPlanned, ConfigItem } from '../../oa.service';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-planned-defaults-dialog',
  templateUrl: './planned-defaults-dialog.component.html',
  styleUrls: ['./planned-defaults-dialog.component.scss']
})
export class PlannedDefaultsDialog implements OnInit {
  objectid:string;
  site:string;
  environment:string;

  defaultData:DefaultPlanned[];
  configData:ConfigItem[];
  availShifts:string[];
  disableList:{};

  helpEnabled:boolean=false;

  dataSource = new MatTableDataSource<any>();
  fgDefaults: FormGroup;

  displayedColumns: string[] = ['Description','Duration','Shift','Qty','DaysOfWeek','Actions']

  dayList = ['Mo','Tu','We','Th','Fr','Sa','Su'];

  constructor(
    public dialogRef: MatDialogRef<PlannedDefaultsDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private timeFormat: TimeFormatPipe,
    private svcOA: OAService
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.environment = this.inData.environment;
    
    this.configData = [];
    this.availShifts = ["1ST SHIFT", "2ND SHIFT", "3RD SHIFT"];

    this.fgDefaults = this.fb.group({
      rows: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig() {
    this.svcOA.getPlannedDowntimeConfig(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        if (out) {
          this.configData = out["Body"];
          this.getDefaults();
        }
      }
    );
  }

  getDefaults() {
    this.svcOA.getPlannedDowntimeDefaults(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        if (out) {
          this.defaultData = out["Body"];

          this.disableList = {};

          // Load the disableList marking every day avialable for each ConfigID and Shift
          this.configData.forEach (
            (config:ConfigItem) => {
                this.disableList[config.ConfigID.toString()] = {};

                this.availShifts.forEach(
                  (shift) => {
                    this.disableList[config.ConfigID.toString()][shift] = {
                      'Mo': false,
                      'Tu': false,
                      'We': false,
                      'Th': false,
                      'Fr': false,
                      'Sa': false,
                      'Su': false
                    };
                  }
                )
            });

          // For the defaults passed, mark any used days for each configid/shift as true
          this.defaultData.forEach(
            (val:DefaultPlanned) => {
              // For each day, if used in this set the value for the ConfigID/Shift/Day to true so we know its used
              val.DaysOfWeek.includes('Mo') ? this.disableList[val.ConfigID][val.Shift]['Mo'] = true : null;
              val.DaysOfWeek.includes('Tu') ? this.disableList[val.ConfigID][val.Shift]['Tu'] = true : null;
              val.DaysOfWeek.includes('We') ? this.disableList[val.ConfigID][val.Shift]['We'] = true : null;
              val.DaysOfWeek.includes('Th') ? this.disableList[val.ConfigID][val.Shift]['Th'] = true : null;
              val.DaysOfWeek.includes('Fr') ? this.disableList[val.ConfigID][val.Shift]['Fr'] = true : null;
              val.DaysOfWeek.includes('Sa') ? this.disableList[val.ConfigID][val.Shift]['Sa'] = true : null;
              val.DaysOfWeek.includes('Su') ? this.disableList[val.ConfigID][val.Shift]['Su'] = true : null;
            });


          // Load the Form Group with the defaultData
          this.fgDefaults = this.fb.group({
            rows:this.fb.array(this.defaultData.map( 
              (val:DefaultPlanned) => this.getFormGroup(val.Shift,
                                                    val.ConfigID, 
                                                    val.Description, 
                                                    this.timeFormat.convertToString(val.Duration), 
                                                    val.Qty,
                                                    val.DaysOfWeek
                                                    )))
          });

          this.dataSource = new MatTableDataSource((this.fgDefaults.get('rows') as FormArray).controls);
        }
      }
    );
  }

  getFormGroup(Shift:string, ConfigID:number, Description:string, Duration:string, Qty:number, DaysOfWeek:string, isEditing:boolean=false, isNew:boolean=false):FormGroup {
    return this.fb.group({
            Shift: new UntypedFormControl({value: Shift, disabled: false}, Validators.required), 
            ConfigIdx: new UntypedFormControl(isNew ? 0 : -1),     
            ConfigID: new UntypedFormControl(ConfigID),
            Description: new UntypedFormControl({value: Description, disabled: false}, Validators.required),
            Duration: new UntypedFormControl({value: Duration, disabled: false},Validators.required),
            Qty: new UntypedFormControl({value: Qty, disabled: false},[Validators.required]),
            DaysOfWeek: new UntypedFormControl({value: DaysOfWeek, disabled: false}),
            Mo: new UntypedFormControl(DaysOfWeek.includes('Mo')),
            Tu: new UntypedFormControl(DaysOfWeek.includes('Tu')),
            We: new UntypedFormControl(DaysOfWeek.includes('We')),
            Th: new UntypedFormControl(DaysOfWeek.includes('Th')),
            Fr: new UntypedFormControl(DaysOfWeek.includes('Fr')),
            Sa: new UntypedFormControl(DaysOfWeek.includes('Sa')),
            Su: new UntypedFormControl(DaysOfWeek.includes('Su')),
            isNew: new FormControl(isNew),
            isEditing: new FormControl(isEditing),
            isDeleted: new FormControl(false)
          });
  } 
 
  add(inForm) {
    if (this.configData.length > 0) {
      let defaultItem = this.configData[0];
  
      // There are configurations, allow to add
      let newRow:FormGroup = this.getFormGroup('1ST SHIFT',defaultItem.ConfigID,defaultItem.Description,this.timeFormat.convertToString(defaultItem.Duration),1,"",true,true);

      newRow.markAllAsTouched();
      newRow.markAsDirty();
          
      inForm.get('rows').push(newRow);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }
  }

  selectConfigIdx(inForm, idx:number,e) {
    let ConfigIdx:number = e.value;

    let ConfigID:string = this.configData[ConfigIdx].ConfigID.toString();
    let Description:string = this.configData[ConfigIdx].Description;
    let Duraton:string = this.timeFormat.convertToString(this.configData[ConfigIdx].Duration);

    inForm.get('rows').at(idx).get('ConfigID').patchValue(ConfigID);
    inForm.get('rows').at(idx).get('Description').patchValue(Description);
    inForm.get('rows').at(idx).get('Duration').patchValue(Duraton);
  }

  editLine(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(true);
  }

  changeDay(inForm, idx:number,e) {
    let configID:string = inForm.get('rows').at(idx).get('ConfigID').value
    let shift:string = inForm.get('rows').at(idx).get('Shift').value
    let modifiedDay:string = e.value;
    let newStatus:boolean = !inForm.get('rows').at(idx).get(modifiedDay).value;

    if (newStatus) {
      // Mark this day as used so other entries can't use if for the same shift and ConfigID
      this.disableList[configID][shift][modifiedDay] = true; //idx;
    } else if (!newStatus && this.disableList[configID][shift][modifiedDay]) {
      // Mark this day as unused
      this.disableList[configID][shift][modifiedDay] = false;
    }

    inForm.get('rows').at(idx).get(modifiedDay).patchValue(newStatus);
    inForm.get('rows').at(idx).get(modifiedDay).markAsDirty();
  }

  delete(inForm, idx:number):void {
    if (!inForm.get('rows').at(idx).get('isNew').value) {
      inForm.get('rows').at(idx).get('isDeleted').patchValue(true);
      this.fgDefaults.markAsDirty();
    } else {
      inForm.get('rows').removeAt(idx);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }

  }

  undo(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(false);
    inForm.get('rows').at(idx).get('isDeleted').patchValue(false);

    inForm.get('rows').at(idx).get('Qty').patchValue(this.defaultData[idx]["Qty"]);
    inForm.get('rows').at(idx).get('Mo').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Mo'));
    inForm.get('rows').at(idx).get('Tu').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Tu'));
    inForm.get('rows').at(idx).get('We').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('We'));
    inForm.get('rows').at(idx).get('Th').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Th'));
    inForm.get('rows').at(idx).get('Fr').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Fr'));
    inForm.get('rows').at(idx).get('Sa').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Sa'));
    inForm.get('rows').at(idx).get('Su').patchValue(this.defaultData[idx]["DaysOfWeek"].includes('Su'));

    inForm.get('rows').at(idx).markAsPristine();
  }

  save() {
    // Return an array with all the times converted from strings to number of seconds
    let adjustedItems:DefaultPlanned[] = this.fgDefaults.get('rows').value.map( (item, index) => {
      let daysOfWeek:string = "";

      if (item.isDeleted) {
        daysOfWeek = ""; // Clear days of the week
      } else {

        // Create comma delimited list
        item.Mo ? daysOfWeek += ",Mo" : null;
        item.Tu ? daysOfWeek += ",Tu" : null;
        item.We ? daysOfWeek += ",We" : null;
        item.Th ? daysOfWeek += ",Th" : null;
        item.Fr ? daysOfWeek += ",Fr" : null;
        item.Sa ? daysOfWeek += ",Sa" : null;
        item.Su ? daysOfWeek += ",Su" : null;

        if (daysOfWeek.length > 0) {
          daysOfWeek = daysOfWeek.substring(1);
        } 
      }

      let configItemUpdate:DefaultPlanned = {
        ConfigID: item.ConfigID,
        Shift: item.Shift,
        Description: item.Description,
        Duration: this.timeFormat.convertToSeconds(item.Duration),
        Qty: item.Qty,
        DaysOfWeek: daysOfWeek,
        OldDaysOfWeek: item.isNew ? 'NEWRECORD' : this.defaultData[index].DaysOfWeek
      }
  
      return configItemUpdate;

    });

    // Get filtered array of items that have changed but aren't being deleted
    let changedItems:DefaultPlanned[] = adjustedItems.filter( 
      (item, index) => {
        // Check if record has been marked for delete or a change has been made
        if (  item.OldDaysOfWeek == 'NEWRECORD' ||
              this.defaultData[index].Qty != item.Qty || 
              this.defaultData[index].DaysOfWeek != item.DaysOfWeek
            ) {      
            return true; // Record is add, update, or delete
        } else {
          return false; // Record hasn't changed don't include
        }
      });

    let obsUpdates:Observable<any>[] = []; // Area of observables for all updates

    // Load array with all add/updates
    changedItems.forEach(
      (changed) => {
        obsUpdates.push(this.svcOA.savePlannedDowntimeDefault(this.environment, this.objectid, changed.Shift, changed.ConfigID, changed.Qty, changed.DaysOfWeek, changed.OldDaysOfWeek));
      }
    );
   
    // If any add/updates/deletes then execute and wait, otherwise just close
    if (obsUpdates.length > 0) {
      forkJoin(obsUpdates).subscribe(
        result => {
          this.close(true);
        }
      );
    } else {
      this.close(false);
    }
  }

  close(updates:boolean = false) {
    this.dialogRef.close({
      updates: updates
    });
  }

}
