import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MetricsService } from 'src/app/services/metrics.service';
import { TooltipValues } from '../metrics/metrics.component';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';
import { ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-metrics-detail-fab',
  templateUrl: './metrics-detail-fab.component.html',
  styleUrls: ['./metrics-detail-fab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MetricsDetailFabComponent implements OnInit, OnDestroy {
  @Input() object:ObjectNode;
  @Input() section:string="problems"; // This should be an input
  
  currentHour:number = 3; // Should this be in input or should it come from the MetricsFab call???? I think MetricsFAB call

  tooltipValues:TooltipValues = new TooltipValues();

  metricsHourSub$: Subscription;
  metricsHourData:[];

  partCountSub$: Subscription;
  partCount:any={};

  metricsTopProblemsSub$: Subscription;
  metricsTopProblemsData:any = [];

  problemsExist:number[] = [0];

  public ffUnplannedRow:boolean = false;
  
  constructor(
    private router: Router,
    private svcMetrics:MetricsService,
    private timeFormat: TimeFormatPipe,
  ) { }

  ngOnInit(): void {
    // We don't need to look up the part count all the time, think we should just look it up when they switch to part count.
    // Ultimately though should part count just be a sub component to avoid duplication? Probably yes.
    if (this.object.PerJobObject != '') {
      this.getPartCount(); 
    }

    this.getTopProblems();
    this.getHourData();
  }

  genToolTip(type:string, record:any):string {
    let output:string = '';

    if (type == 'AvailableTime') {
      output = `Hour ${record.Hour}

                Schedule Time - Planned Downtime = Available Time

                ${this.timeFormat.convertToString(record.ProductionTime, true)} - ${this.timeFormat.convertToString(record.PlannedDowntime, true)} = ${this.timeFormat.convertToString(record.AvailableTime, true)}
                
                `;
    } else if (type == 'OA') {
      output = `Hour ${record.Hour}
      
                (Available Time - Unplanned DownTime) / Available Time = OA %

                (${this.timeFormat.convertToString(record.AvailableTime, true)} - ${this.timeFormat.convertToString(record.UnplannedDownTime, true)}) / ${this.timeFormat.convertToString(record.AvailableTime, true)} = ${(record.HourOA * 100).toFixed(1)}%

                `;
    }
    
    return output;
  }

  calcOAHour(record:any):number {
    let output:number = 0;

    output = (record.AvailableTime - record.UnplannedDownTime) / record.AvailableTime;
    if (output < 0) {
      output = 0;
    }

    return output;
  }

  getHourData() {
    this.metricsHourSub$ = this.svcMetrics.metricsHour(this.object).subscribe(
      out => {
        if (out) {
          this.metricsHourData = out.Body.Hours;
        }
      }
    );
  }

  getTopProblems() {
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();

    this.metricsTopProblemsSub$ = this.svcMetrics.metricsTopProblems(this.object).subscribe(
      out => {
        if (out) {
          this.metricsTopProblemsData = out.Body;

          for (let idx:number = 0; idx < this.metricsTopProblemsData.length; idx++) {
            this.problemsExist[idx] = this.metricsTopProblemsData[idx]['Problems'].length;
          }
        }
      }
    );
  }

  getPartCount() {
    if (this.partCountSub$) this.partCountSub$.unsubscribe();

    this.partCountSub$ = this.svcMetrics.partCount(this.object).subscribe(
      out => {
        if (out) {
          let partCount = out.Body;
          let myPartCount = {};
          partCount.forEach( part => {
            if (part.Hour in myPartCount) {
              myPartCount[part.Hour].push({PartNumber: part.PartNumber, Quantity: part.Quantity, JobID: part.JobID, JobDescription:part.JobDescription});
            } else {
              myPartCount[part.Hour] = [{PartNumber: part.PartNumber, Quantity: part.Quantity, JobID: part.JobID, JobDescription:part.JobDescription}];
            }
          })
          this.partCount = myPartCount;
        }
      }
    )

  }

  navigateToProblems(hour:number):void { 
    console.log("Navigate to Problems", hour);
    let hourParam:string = (hour == -1 ? 'ALL' : hour.toString());
    this.router.navigate(["/problemlist",this.object.Site, this.object.ObjectID, hourParam]);
  }

  ngOnDestroy(): void{ 
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();
    if (this.partCountSub$) this.partCountSub$.unsubscribe();

    this.svcMetrics.cleanupObservers();
  }

}
