import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InventoryService } from '../../services/inventory.service';
import { PartDialogComponent } from '../part-dialog/part-dialog.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, OnDestroy {
  objectid:string;
  site:string;
  shiftDate:string;
  shift:string;

  inventorySub$:Subscription;
  inventoryData:any;

  selectedRow:any;

  displayedColumns: string[] = ['PartDescription','Prior','Actual','LastUpdate','Modified']

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private svcInventory: InventoryService    
  ) { }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
    this.shiftDate = '2022-06-24';
    this.shift = '2ND SHIFT';

    this.getInventory();
  }

  getInventory():void {
    if (this.inventorySub$) this.inventorySub$.unsubscribe();

    this.inventorySub$ = this.svcInventory.inventory(this.site, this.objectid).subscribe(
      out => {
        this.inventoryData = out.Body;
        console.log("inventoryData", this.inventoryData);
      }
    );
  }

  addPart():void {
    this.openPartDialog('Add');
  }

  editCount():void {
    this.openPartDialog('Edit');
  }

  submitCount():void {
    this.openPartDialog('Submit');
  }

  openPartDialog(type:string) {
    const dialogRef = this.dialog.open(PartDialogComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        type: type,
        site: this.site,
        object: this.objectid,
        shiftDate: this.shiftDate,
        shift: this.shift,
        problemData: this.selectedRow
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getInventory();
    });

  }

  toggleRowSelect(element:any) {
    console.log("Select Row!!!",element);
    if (this.selectedRow == element) {
      this.selectedRow = null;
    } else {
      this.selectedRow = element;
    }
  }

  ngOnDestroy(): void{ 
    if (this.inventorySub$) this.inventorySub$.unsubscribe();
  }

}
