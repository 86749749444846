<form [formGroup]="filterFormGroup">
    <h3 mat-dialog-title>Filter Duration</h3>
    <div mat-dialog-content>
        <mat-label>Enter Duration in Seconds</mat-label>
        <mat-form-field [style.width.px]="100">
            
            <input matInput cdkTextareaAutosize type="number" size="1" min="0" formControlName="MinDuration">
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="fx-row-end-center">
        <button mat-raised-button color="primary" (click)="filter()" [disabled]="filterFormGroup.invalid">Save</button>
        <button mat-raised-button (click)="onNoClick()">No Filter</button>
    </div>
</form>