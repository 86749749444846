<div style="display: flex">
    <h3 mat-dialog-title>Schedule Planned Downtime</h3>
    <span class="spacer"></span>
    <div style="min-width: 225px">
        <button mat-icon-button color="primary" (click)="openDefaults()"><mat-icon>preview</mat-icon> Manage Defaults</button>
    </div>
    <button mat-icon-button color="primary" (click)="helpEnabled = !helpEnabled"><mat-icon>help</mat-icon></button>
</div>
<div mat-dialog-content>
<div *ngIf="this.configData.length > 0">
<form [formGroup]="fgConfig" autocomplete="off">
<ng-container formArrayName="rows">
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ShiftDate">
            <mat-header-cell *matHeaderCellDef>Shift Date</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isNew? 'none' : 'legacy'" [style.width.px]="90">
                    <input matInput type="text" 
                    formControlName="ShiftDate"
                    maxlength="10"
                    [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"   
                    [readonly]="!fgConfig.get('rows').value[i].isNew"/>
                    <mat-error *ngIf="schedRows.controls[i].get('ShiftDate').errors?.validateDate">Must be valid date YYYY-MM-DD or MM/DD/YYYY</mat-error>
                    <mat-error *ngIf="schedRows.controls[i].get('ShiftDate').errors?.required">Shift Date required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Shift">
            <mat-header-cell *matHeaderCellDef>Shift</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="'none'" [style.width.px]="95">
                    <span *ngIf="fgConfig.get('rows').value[i].isNew">
                        <mat-select formControlName="Shift">
                            <mat-option *ngFor="let shift of availShifts" [value]="shift">{{shift}}</mat-option>
                        </mat-select>
                    </span>
                    <span *ngIf="!fgConfig.get('rows').value[i].isNew">
                        <input matInput type="text"
                        formControlName="Shift"  
                        maxlength="9"     
                        [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"  
                        readonly="true"/>
                    </span>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="'none'" [style.width.px]="195">
                    <input matInput hidden="true" formControlName="ConfigIdx"/>
                    <div *ngIf="!fgConfig.get('rows').value[i].isNew && fgConfig.get('rows').value[i].ConfigIdx != -1">
                        {{configData[fgConfig.get('rows').value[i].ConfigIdx].Description}}
                    </div>
                    <span *ngIf="fgConfig.get('rows').value[i].isNew">
                        <mat-select formControlName="ConfigIdx">
                            <mat-option *ngFor="let config of configData; let i = index" [value]="i">{{config.Description}}</mat-option>
                        </mat-select>
                    </span>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Duration">
            <mat-header-cell *matHeaderCellDef>Duration (MM:SS)</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="'none'" [style.width.px]="120">
                    <input matInput hidden="true" formControlName="ConfigIdx"/>
                    <span *ngIf="fgConfig.get('rows').value[i].ConfigIdx != -1">
                        {{configData[fgConfig.get('rows').value[i].ConfigIdx].Duration | TimeFormat}}
                    </span>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index">

                <button *ngIf="!fgConfig.get('rows').value[i].isDeleted"
                         mat-button color="warn" 
                         (click)="delete(fgConfig,i)">
                    <mat-icon>delete_forever</mat-icon>Delete
                </button>

                <button *ngIf="fgConfig.get('rows').value[i].isDeleted" 
                        mat-button 
                        color="primary" 
                        (click)="undo(fgConfig, i)">
                    <mat-icon>undo</mat-icon>
                    <span *ngIf="fgConfig.get('rows').value[i].isDeleted">Undo - Delete</span>
                </button>
            </mat-cell>
        </ng-container> 

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>      
    </mat-table>
    <div>
        <button mat-button color="primary" (click)="add(fgConfig)"><mat-icon>add_circle</mat-icon>Add New</button>
    </div>
</ng-container>
</form>
</div>
<div *ngIf="this.configData.length == 0">
No Planned Downtime Events Configured
</div>
</div>   
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!fgConfig.dirty || !fgConfig.valid" color="primary" (click)="save()">Save and Close</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="close()">Cancel</button>
</div>

<!-- Help Panel-->
<div *ngIf="helpEnabled" class="help" (click)="helpEnabled = !helpEnabled">
    <div class="help-content" (click)="$event.stopPropagation()">
        <h1>Help - Schedule Planned Downtime</h1>
        <div>
            <p>
                For calculating Operational Availablity during a shift accurately you can estime the Planned Downtime events that will be completed during
                that shift. These schedule planned downtime events are only for estimating Available Time during the shift. As real planend downtime events 
                occur the scheduled events will be removed. At the end of the shift only actual planend downtime events will be used.
            </p>

            <h2>Configuration</h2>
            <p>
                Add an entry for each planned downtime event expected during the shift
            </p>
            <h2>Manage Defaults</h2>
            <p>
                If you have a consistent estimate of planned downtime events that will apply to shifts you can click on Manage Defaults and set 
                defaults by day. Those defaults will be loaded every day so you do not have to manually create them.
            </p>
        </div>
        <button mat-raised-button color="primary" (click)="helpEnabled = !helpEnabled">Close</button>
    </div>
</div>