import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'TimeFormat'})
export class TimeFormatPipe implements PipeTransform {
  transform(value:number, showAsMinutes:boolean=false): string {
    return this.convertToString(value, showAsMinutes);
  }

  convertToString(value:number,showAsMinutes:boolean=false): string {
    let remainingSeconds = value;

    let hours = Math.floor(remainingSeconds / 3600);
    remainingSeconds = remainingSeconds - (hours * 3600); // Remove any seconds used by hours
    
    let minutes = Math.floor((remainingSeconds ) / 60);
    remainingSeconds = remainingSeconds - (minutes * 60); // Remove any seconds used by minutes

    let seconds = remainingSeconds;

    if (showAsMinutes) {
      minutes += hours * 60; // Show hours/minutes combined
    }

    let output:string;
    if (hours > 0 && !showAsMinutes) {
        output = `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
    } else {
        output = `${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
    }

    return output;
  }

  convertToSeconds(value:string): number {
    let seconds:number = 0;
    
    if (typeof value == 'number') {
      // Already passed a number, assume that is seconds
      seconds = value;
    } else {
      let timePieces:string[] = value.split(":");
      let sections:number = timePieces.length;

      for (let i = 1; i <= sections; i++) {
        if (i == 1) {
          seconds += +timePieces.pop(); // Get seconds off end of array
        } else if (i == 2) {
          seconds += +timePieces.pop() * 60; // Get minutes off end of array and convert to seconds
        } else if (i == 3) {
          seconds += +timePieces.pop() * 3600; // Get hours off end of array and convert to seconds
        }
      }
    }

    return seconds;
  }
}