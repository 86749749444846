import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { filter, take } from 'rxjs/operators';
import { PlannedConfigDialog } from 'src/app/components/oa/planned-downtime/planned-config-dialog/planned-config-dialog.component';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';
import { AdminService, ConfigItem } from '../../services/admin.service';
import { OAService } from 'src/app/components/oa/oa.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-oa-challenge-dialog',
  templateUrl: './oa-challenge-dialog.component.html',
  styleUrls: ['./oa-challenge-dialog.component.scss']
})
export class OaChallengeDialogComponent {
  objectid:string;
  site:string;
  environment:string;

  configData:ConfigItem[];

  helpEnabled:boolean=false;

  dataSource = new MatTableDataSource<any>();

  fgConfig: FormGroup;
   
  displayedColumns: string[] = ['StartDate','EndDate','Challenge', 'Actions']

  constructor(
    public dialogRef: MatDialogRef<PlannedConfigDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private timeFormat: TimeFormatPipe,
    private svcOAChallenge: AdminService
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.environment = this.inData.environment;

    this.fgConfig = this.fb.group({
      rows: this.fb.array([])
    });

  }

  ngOnInit(): void {
    this.getConfig();    
  }

  getConfig() {
    this.svcOAChallenge.getOAChallengeConfig(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        if (out) {
          this.configData = out["Body"];
          console.log("LOADED CONFIG DATA!", this.configData);

          this.fgConfig = this.fb.group({
            rows: this.fb.array(this.configData.map( 
                (val:ConfigItem) => this.getFormGroup(val.id,                                            
                                                      dayjs(val.StartDate).format('MM/DD/YYYY'),
                                                      dayjs(val.EndDate).format('MM/DD/YYYY'),
                                                      val.Challenge.toString()
                                                      )))
          });

          this.dataSource = new MatTableDataSource((this.fgConfig.get('rows') as FormArray).controls);
        }
      }
    );
  }

  getFormGroup(id:number, StartDate:string, EndDate:string, Challenge:string, isEditing:boolean=false):FormGroup {
    return this.fb.group({
            id: new UntypedFormControl(id),            
            StartDate: new UntypedFormControl({value: StartDate, disabled: false},[this.validatorDate(), Validators.required]),
            EndDate: new UntypedFormControl({value: EndDate, disabled: false},[this.validatorDate(), Validators.required]),
            Challenge: new UntypedFormControl({value: Challenge, disabled: false}, Validators.required),
            isEditing: new FormControl(isEditing),
            isDeleted: new FormControl(false)
          });
  }

  validatorDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let inDate: string = control.value;
      if (!dayjs(inDate).isValid()) { 
        return { notValidDate: true };
      }
  
      return null;

    } 
  }

  get configRows(): FormArray {
    return this.fgConfig.get('rows') as FormArray;
  }

  formatDate(control:AbstractControl) {
    if (!control.invalid) {
      let dateValue:string = control.value;
      let dateFormatted:string = "";

      if (dateValue.trim() != "") {    
        dateFormatted = dayjs(dateValue).format('MM/DD/YYYY').toString();
      }
      control.patchValue(dateFormatted);
    }
  }

  add(inForm) {
    let newRow:FormGroup = this.getFormGroup(-1,"","","", true);

    newRow.markAllAsTouched();

    inForm.get('rows').push(newRow);

    this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
  }

  editLine(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(true);
  }

  delete(inForm, idx:number):void {
    if (inForm.get('rows').at(idx).get('id').value != -1) {
      inForm.get('rows').at(idx).get('isDeleted').patchValue(true);
      this.fgConfig.markAsDirty();
    } else {
      inForm.get('rows').removeAt(idx);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }

  }

  undo(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(false);
    inForm.get('rows').at(idx).get('isDeleted').patchValue(false);
    inForm.get('rows').at(idx).get('StartDate').patchValue(dayjs(this.configData[idx]["StartDate"]).format('MM/DD/YYYY'));
    inForm.get('rows').at(idx).get('EndDate').patchValue(dayjs(this.configData[idx]["EndDate"]).format('MM/DD/YYYY'));
    inForm.get('rows').at(idx).get('Challenge').patchValue(this.configData[idx]["Challenge"]);
    inForm.get('rows').at(idx).markAsPristine();
  }

  save() {
    // Return an array with all the times converted from strings to number of seconds
    let adjustedItems:ConfigItem[] = this.fgConfig.get('rows').value.map( (item) => {
      let configItemUpdate:ConfigItem = {
        id: item.id,
        StartDate: dayjs(item.StartDate).format('YYYY-MM-DD').toString(),        
        EndDate: dayjs(item.EndDate).format('YYYY-MM-DD').toString(),
        Challenge: item.Challenge
        
      }
      console.log("configItemUpdate", configItemUpdate);
  
      return configItemUpdate;

    });
    
    // Get filtered array of items that have changed, this includes deletes
    let changedItems:ConfigItem[] = adjustedItems.filter( 
      (item, index) => {
        // Check if record has been marked for delete or a change has been made
        if (
            ( item.id == -1 ||               
              this.configData[index].StartDate != item.StartDate ||
              this.configData[index].EndDate != item.EndDate ||
              this.configData[index].Challenge != item.Challenge
            )) {      
            return true; // Record is add, update, or delete
        } else {
          return false; // Record hasn't changed don't include
        }
      });

    if (changedItems.length > 0) {
      this.svcOAChallenge.saveOAChallengeConfig(this.site, this.environment, this.objectid, changedItems).subscribe(
        (out) => {
          this.close(true);
        }
      );
    } else {
      this.close(false);
    }
  }

  close(updates:boolean = false) {
    this.dialogRef.close({
      updates: updates
    });
  }
}
