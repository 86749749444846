<h3 mat-dialog-title>Problem Group: {{description}}</h3>
<div mat-dialog-content>
  <div *ngIf="excelProcessing">
      <div class="fx-column-space-between-center">
        <div *ngIf="!error">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="error">
            <mat-icon color="warn" style="font-size: 64px;">error</mat-icon>
        </div>
        <div>{{excelStatus}}</div>
        <div><button *ngIf="error" mat-raised-button color="primary" (click)="clearError()">Close</button></div>
  </div>
  </div>
  <div *ngIf="!excelProcessing">
    <div class="fx-column-start-start">
      <div class="fx-row">
        <div>
          <app-file-upload problemgroup={{problemGroup}} text="Upload Problems from Excel" admin={{adminFlag}} (onUploadComplete)="uploadComplete($event)"></app-file-upload>
        </div>
        <div>
          <button mat-icon-button [disabled]="!adminFlag"  color="primary" (click)="downloadExcel()" ><mat-icon>file_download</mat-icon>Download Problems to Excel</button>
        </div>
      </div>
      <div><button [disabled]="!adminFlag" mat-icon-button (click)="addTopLevelItem()"><mat-icon>add</mat-icon>Add Top Level Problem</button></div>    
    </div>
    
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree mat-tree-positio">
      <!-- This is a tree node with no children -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
          <button mat-icon-button disabled><mat-icon class="mat-icon-rtl-mirror"></mat-icon></button>
          <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="SelectionToggle(node)">{{node.Problem}}</mat-checkbox>
          <button [disabled]="!adminFlag" *ngIf="node.Level != 3" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
        </li>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-form-field>
          <mat-label>New Problem...</mat-label>
          <input matInput #itemValue placeholder="Problem">
        </mat-form-field>
        <button [disabled]="!adminFlag" mat-button (click)="saveNode(node, itemValue.value)">Save</button>
        <button [disabled]="!adminFlag" mat-button (click)="cancelNode()">Cancel</button>
      </mat-tree-node>

      <!-- There are the tree nodes with children -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild;">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.Problem">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="SelectionToggle(node)">{{node.Problem}}</mat-checkbox>
            <button [disabled]="!adminFlag" *ngIf="node.Level != 3" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="!excelProcessing" class="fx-column-space-evenly-start">
    <div><button [disabled]="!adminFlag" mat-icon-button color="warn" (click)="deleteSelectedProblems()"><mat-icon>delete_outline</mat-icon>Delete Selected</button></div>
    <div><button mat-icon-button color="primary" (click)="getProblems()"><mat-icon>refresh</mat-icon>Refresh</button></div>
  </div> 
</div>
<div mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">Close</button>
</div>