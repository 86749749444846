<mat-card class="metrics-card mat-elevation-z1">
    <mat-card-title>
        <div class="grid-container-header"> 
            <div class="sched_time" matTooltipClass = "mat-multiline-tooltip" matTooltipClass="custom-tooltip" matTooltip="Amount of time area scheduled to run in Proficy Workcalendar." matTooltipPosition="above" style="text-align: center;">Scheduled<br>Time</div>
            <div class="minus_sign header-sign" style="text-align: center;">-</div>
            <div class="planned_dt" matTooltipClass = "mat-multiline-tooltip" matTooltipClass="custom-tooltip" matTooltip="Amount of time in a planned downtime event such as a change over." matTooltipPosition="above" style="text-align: center;">Planned<br>Downtime</div>
            <div class="equal_sign header-sign" style="text-align: center;">=</div>
            <div class="available_time" matTooltipClass = "mat-multiline-tooltip" matTooltipClass="custom-tooltip" matTooltip="Amount of time the equipment is available to run calculated as Scheduled Time - Planned Downtime" matTooltipPosition="above" style="text-align: center;">Available<br>Time</div>
            <div class="unplanned_dt" matTooltipClass="custom-tooltip" matTooltip="Amount of time in a fault state not during a planned downtime event." matTooltipPosition="above" style=" text-align: center;">Unplanned<br>Downtime</div>           
            <div class="efficiency" matTooltipClass="custom-tooltip oa-tooltip" matTooltip="(Available Time - Unplanned DownTime) / Available Time = OA %" matTooltipPosition="above" style="text-align: center;">OA</div>
            <div class="perfect" matTooltipClass="custom-tooltip" [matTooltip]="'Ideal (Available Time / Cycle Time) Cycle Time = ' + metricsHeaderData.idealCycleTime" matTooltipPosition="above" style="text-align: center;">Perfect</div>
            <div class="actual" matTooltipClass="custom-tooltip" matTooltip="Parts Produced" matTooltipPosition="above" style="text-align: center;">Actual</div>

            <div class="variableContent">
                <div class="grid-container-content" *ngIf="section=='problems'">
                    <div class="item1">Problem</div>
                    <div class="item2">Duration</div>
                </div>
                <div class="grid-container-content" *ngIf="section=='hourNotes'">
                    <div class="item1">Note</div>
                    <div class="item2">Counter Measure</div>
                </div>
                <div class="grid-container-partcontent" *ngIf="section=='partCount'">
                    <div class="part-item1">Job ID</div>
                    <div class="part-item2">Job Description</div>
                    <div class="part-item3">Part Number</div>
                    <div class="part-item4">Quantity</div>
                </div>
            </div>
            <div class="equipstatus"></div>
        </div>        
    </mat-card-title>
    <mat-card-content>
        <div class="grid-container-header"> 
            <div class="sched_time" style="text-align: center;"><strong>{{metricsHeaderData.ProductionTime | TimeFormat}}</strong></div>
            <div class="minus_sign" style="text-align: center;">-</div>
            <div class="planned_dt" style="text-align: center;"><strong>{{metricsHeaderData.PlannedDowntime | TimeFormat}}</strong></div>
            <div class="equal_sign" style="text-align: center;">=</div>
            <div class="available_time" style="color: green; text-align: center;"><strong>{{metricsHeaderData.AvailableTime | TimeFormat}}</strong></div>
            <div class="unplanned_dt" style="color: rgb(187, 84, 84); text-align: center;"><strong>{{metricsHeaderData.UnplannedDownTime | TimeFormat}}</strong></div>
            <div class="efficiency" style="text-align: center;"><strong>
                <span *ngIf="metricsHeaderData.OAActual != -999" matTooltip="OA Target: {{metricsHeaderData.OATarget| percent:'.1'}}" [ngClass]="metricsHeaderData.OAActual < metricsHeaderData.OATarget ? 'below-target-oa' : null">{{metricsHeaderData.OAActual | percent:'.1'}}</span>
            </strong></div>
            <div class="perfect" style="text-align: center;"><strong>{{floor(metricsHeaderData.AvailableTime / metricsHeaderData.idealCycleTime)}}</strong></div>
            <div class="actual" style="text-align: center;"><strong>{{metricsHeaderData.Actual}}</strong></div>
            <div class="variableContent">
                <div class="grid-container-content">
                    <div class="item1" [matTooltip]="tooltipValues.problem" matTooltipPosition="above">
                        <span *ngIf="section =='problems_hide' && metricsHeaderData.Status != '0'">
                            <strong>{{metricsHeaderData.EventStart | date:'HH:mm:ss'}}</strong>
                        </span>
                    </div>
                    <div class="item2" [matTooltip]="tooltipValues.duration" matTooltipPosition="above">
                        <span *ngIf="section =='problems_hide' && metricsHeaderData.Status != '0'">
                            <strong>{{metricsHeaderData.EventDuration}}</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div class="equipstatus"><img  (click)="navigateToCurrentProblem($event)" style="height: 25px; float: right !important;" [src]="'assets/' + metricsHeaderData.Status + '.png'"/></div>
        </div>
    </mat-card-content>
</mat-card>