import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ObjectNode, ObjectListService } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-analysis-home',
  templateUrl: './analysis-home.component.html',
  styleUrls: ['./analysis-home.component.scss']
})
export class AnalysisHomeComponent implements OnInit {
  objectid:string;
  site:string;
  startDate:string;
  endDate:string;
  currentTab:string="pareto";

  objectType:string;
  objecListSub$: Subscription;
  objectListData:any = {};
  currentObject:ObjectNode;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcObjectList: ObjectListService,
  ) { 
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
  }

  ngOnInit(): void {
    this.getObjectList();
  }

  getObjectList() {
    if (this.objecListSub$) this.objecListSub$.unsubscribe();

    this.objecListSub$ = this.svcObjectList.objectList().subscribe(
      out => {
        this.objectListData = out.Body;
        let siteObjects:ObjectNode[] =  this.objectListData.find( x => x.search == this.site);
        for (let child of siteObjects['children']) {
          let searchObj = child.children.find(x => x.ObjectID == this.objectid);

          if (searchObj) {
            this.currentObject = searchObj;

            this.objectType = this.currentObject['ObjectType'];

            break;
          }
        }
      }
    );
  }

  onDateSelection(e) {
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
  }

  changeTab(tabName:string) {
    this.currentTab = tabName;
  }
}
