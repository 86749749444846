import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Modules
import { MatButtonModule } from '@angular/material/button'; 
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card'; 
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// App Components
import { HomeComponent } from './home/home.component';
import { ObjectsComponent } from './admin/components/objects/objects.component';
import { ObjectDialogComponent } from './admin/components/object-dialog/object-dialog.component';
import { AdminComponent } from './admin/components/admin/admin.component';
import { ProblemsComponent } from './admin/components/problems/problems.component';
import { ProblemGroupsComponent } from './admin/components/problem-groups/problem-groups.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { AndonGroupsDialogComponent } from './admin/components/andon-groups-dialog/andon-groups-dialog.component';
import { FileUploadComponent} from './admin/components/file-upload/file-upload.component';
import { TeamGroupsComponent } from './admin/components/team-groups/team-groups.component';
import { TeamsDialogComponent } from './admin/components/teams-dialog/teams-dialog.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { ObjectListComponent } from './components/object-list/object-list.component';
import { DeleteDialogComponent } from './components/problem-list/delete-dialog/delete-dialog.component';
import { ProblemListComponent } from './components/problem-list/problem-list.component';
import { MetricsHeaderComponent } from './components/metrics-header/metrics-header.component';
import { PaMatrixComponent } from './pa-matrix/components/pa-matrix/pa-matrix.component';
import { AddEditProblemComponent } from './pa-matrix/components/add-edit-problem/add-edit-problem.component';
import { CloseProblemComponent } from './pa-matrix/components/close-problem/close-problem.component';
import { ReopenProblemComponent } from './pa-matrix/components/reopen-problem/reopen-problem.component';
import { AddActionComponent } from './pa-matrix/components/add-action/add-action.component';
import { TestRepairComponent } from './components/test-repair/test-repair.component';
import { ShiftNoteDialogComponent } from './components/shift-note-dialog/shift-note-dialog.component';
import { HourNoteDialogComponent } from './components/hour-note-dialog/hour-note-dialog.component';
import { ButtonBarComponent } from './components/button-bar/button-bar.component';
import { InventoryComponent } from './inventory/components/inventory/inventory.component';
import { PartDialogComponent } from './inventory/components/part-dialog/part-dialog.component';
import { FilterComponent } from './components/problem-list/filter/filter.component';
import { ShiftSelectDialogComponent } from './components/shift-select-dialog/shift-select-dialog.component';
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaultPreviewDialogComponent } from './components/hour-note-dialog/fault-preview-dialog/fault-preview-dialog.component';
import { ProblemEntryComponent } from './problem-entry/problem-entry.component';
import { ProblemDialogComponent } from './components/problem-list/problem-dialog/problem-dialog.component';
import { GeaMesNavbarModule }from '@gea-mes/navbar';
import { ParetoComponent } from './analysis/components/pareto/pareto.component';
import { TimeFormatPipe } from './pipes/time-format.pipe';

/** 
 * Fusion Charts
 * 
 * Note 01/03/2023
 * 
 * For Angular 14, angular-fusioncharts module does not work. See issue:
 * 
 * https://github.com/fusioncharts/angular-fusioncharts/issues/153
 * 
 * Used the module copy from https://stackblitz.com/edit/angular-ivy-32bzyf
 * 
 * Had to have a copy of the module in the app itself which is why FusionChartsModule is local to the app.
 * 
 **/ 

import { FusionChartsModule } from './angular-fusioncharts/fusioncharts.module';
import * as FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import { AnalysisHomeComponent } from './analysis/components/analysis-home/analysis-home.component';
import { RawdataComponent } from './analysis/components/rawdata/rawdata.component';
import { ToprootcauseComponent } from './analysis/components/toprootcause/toprootcause.component';
import { UngroupConfirmComponent } from './problem-entry/ungroup-confirm/ungroup-confirm.component';
import { PlannedDowntimeComponent } from './components/oa/planned-downtime/planned-downtime.component';
import { PlannedConfigDialog } from './components/oa/planned-downtime/planned-config-dialog/planned-config-dialog.component';
import { PlannedDefaultsDialog } from './components/oa/planned-downtime/planned-defaults-dialog/planned-defaults-dialog.component';
import { PlannedScheduledDialog } from './components/oa/planned-downtime/planned-scheduled-dialog/planned-scheduled-dialog.component';
import { CustomerDemandComponent } from './components/oa/customer-demand/customer-demand.component';
import { DemandFormComponent } from './components/oa/customer-demand/demand-form/demand-form.component';
import { DemandDefaultsDialog } from './components/oa/customer-demand/demand-defaults-dialog/demand-defaults-dialog.component';
import { PlannedDowntimeChartComponent } from './analysis/components/planned-downtime-chart/planned-downtime-chart.component';
import { MetricsHeaderFabComponent } from './components/metrics-header-fab/metrics-header-fab.component';
import { MetricsDetailFabComponent } from './components/metrics-detail-fab/metrics-detail-fab.component';
import { MetricsDetailAssemblyComponent } from './components/metrics-detail-assembly/metrics-detail-assembly.component';
import { MetricsHeaderAssemblyComponent } from './components/metrics-header-assembly/metrics-header-assembly.component';
import { PlannedDowntimeTableDialog } from './analysis/components/planned-downtime-chart/planned-downtime-table-dialog/planned-downtime-table-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ObjectsComponent,
    ObjectDialogComponent,
    AdminComponent,
    ProblemsComponent,
    ProblemGroupsComponent,
    RedirectComponent,
    AndonGroupsDialogComponent,
    FileUploadComponent,
    TeamGroupsComponent,
    TeamsDialogComponent,
    MetricsComponent,
    ObjectListComponent,
    DeleteDialogComponent,
    ProblemListComponent,
    MetricsHeaderComponent,
    PaMatrixComponent,
    AddEditProblemComponent,
    CloseProblemComponent,
    ReopenProblemComponent,
    AddActionComponent,
    TestRepairComponent,
    ShiftNoteDialogComponent,
    HourNoteDialogComponent,
    FaultPreviewDialogComponent,
    ButtonBarComponent,
    InventoryComponent,
    PartDialogComponent,
    FilterComponent,
    ShiftSelectDialogComponent,
    ProblemEntryComponent,
    ProblemDialogComponent,
    ParetoComponent,
    AnalysisHomeComponent,
    RawdataComponent,
    ToprootcauseComponent,
    UngroupConfirmComponent,
    PlannedDowntimeComponent,
    TimeFormatPipe,
    PlannedConfigDialog,
    PlannedDefaultsDialog,
    PlannedScheduledDialog,
    CustomerDemandComponent,
    DemandFormComponent,
    DemandDefaultsDialog,
    PlannedDowntimeChartComponent,
    MetricsHeaderFabComponent,
    MetricsDetailFabComponent,
    MetricsDetailAssemblyComponent,
    MetricsHeaderAssemblyComponent,
    PlannedDowntimeTableDialog
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FusionChartsModule.forRoot(FusionCharts, Charts, FusionTheme, CandyTheme),
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDividerModule,
    MatExpansionModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatRadioModule,
    MatSlideToggleModule,
    GeaMesDatepickerModule,
    MatTooltipModule,
    GeaMesNavbarModule
  ],
  providers: [TimeFormatPipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }
