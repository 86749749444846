<div style="display: flex">
    <h3 mat-dialog-title>Planned Downtime Configuration</h3>
    <span class="spacer"></span>
    <button mat-icon-button color="primary" (click)="helpEnabled = !helpEnabled"><mat-icon>help</mat-icon></button>
</div>
<div mat-dialog-content>
    
<form [formGroup]="fgConfig" autocomplete="off">
<ng-container formArrayName="rows">
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing? 'none' : 'legacy'" [style.width.px]="250">
                    <input matInput type="text"
                           formControlName="Description"  
                           maxlength="80"     
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"  
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"/>
                    <mat-error *ngIf="configRows.controls[i].get('Description').errors?.required">Description required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Duration">
            <mat-header-cell *matHeaderCellDef>Duration (MM:SS)</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing ? 'none' : 'legacy'" [style.width.px]="75">
                    <input matInput type="text" 
                           formControlName="Duration"
                           maxlength="8"
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"   
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"
                           (blur)="formatTime(configRows.controls[i].get('Duration'))"/>
                    <mat-error *ngIf="configRows.controls[i].get('Duration').errors?.validateTime">Format must be MM:SS</mat-error>
                    <mat-error *ngIf="configRows.controls[i].get('Duration').errors?.required">Duration required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GracePeriod">
            <mat-header-cell *matHeaderCellDef>Grace Period (MM:SS)</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing? 'none' : 'legacy'" [style.width.px]="75">
                    <input matInput type="text" 
                           formControlName="GracePeriod"
                           maxlength="8"
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"  
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"
                           (blur)="formatTime(configRows.controls[i].get('GracePeriod'))" />
                    <mat-error *ngIf="configRows.controls[i].get('GracePeriod').errors?.validateTime">Format must be MM:SS</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index">
                <button *ngIf="!fgConfig.get('rows').value[i].isDeleted && !fgConfig.get('rows').value[i].isEditing" 
                         mat-button 
                         color="primary" 
                         (click)="editLine(fgConfig,i)">
                    <mat-icon>create</mat-icon>Edit
                </button>
                
                <span *ngIf="fgConfig.get('rows').value[i].ConfigID == -1" style="min-width: 75.05px; max-width: 75.05px;"></span> <!-- Space element for when adding new to line up Delete button with other delete buttons -->

                <button *ngIf="!fgConfig.get('rows').value[i].isDeleted && !fgConfig.get('rows').value[i].isEditing || fgConfig.get('rows').value[i].ConfigID == -1" 
                         mat-button color="warn" 
                         (click)="delete(fgConfig,i)">
                    <mat-icon>delete_forever</mat-icon>Delete
                </button>

                <button *ngIf="(fgConfig.get('rows').value[i].isEditing || fgConfig.get('rows').value[i].isDeleted) && fgConfig.get('rows').value[i].ConfigID != -1" 
                        mat-button 
                        color="primary" 
                        (click)="undo(fgConfig, i)">
                    <mat-icon>undo</mat-icon>
                    <span *ngIf="!fgConfig.get('rows').value[i].isDeleted">Undo - Edit</span>
                    <span *ngIf="fgConfig.get('rows').value[i].isDeleted">Undo - Delete</span>
                </button>
            </mat-cell>
        </ng-container> 

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>      
    </mat-table>
    <div>
        <button mat-button color="primary" (click)="add(fgConfig)"><mat-icon>add_circle</mat-icon>Add New</button>
    </div>
</ng-container>
</form>
</div>   
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!fgConfig.dirty || !fgConfig.valid" color="primary" (click)="save()">Save and Close</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="close()">Cancel</button>
</div>

<!-- Help Panel-->
<div *ngIf="helpEnabled" class="help" (click)="helpEnabled = !helpEnabled">
    <div class="help-content" (click)="$event.stopPropagation()">
        <h1>Help - Planned Downtime Configuration</h1>
        <div>
            <p>
                Planned Downtime is Scheduled/Required downtime (such as Planned Maintenance or Planned Changeovers).
            </p>

            <h2>Configuration</h2>
            
            <h3>Description</h3> 
            <p>
                Short Description of the Planned Downtime activity.
            </p>

            <h3>Duration</h3>
            <p>
                The amount of time expected to complete the Planned Downtime activity. Can be entered as number of seconds or as minutes and seconds (i.e. 20:30 would be 20 minutes 
                and 30 seconds).
            </p>
            <p>
                When an operator starts a Planned Downtime event, faults that occur on that equipment after that time will not count as downtime or show up in Hour by Hour. 
                If Planned Downtime takes longer than Duration, there will be a single entry added to Hour by Hour to record a reason for the amount of time over.
            </p>

            <h3>Grace Period</h3>
            <p>
                If a fault on the equipment is expected to signal that a Planned Downtime activyt should start, you can put in a Grace Period. This will remove unplanned downtime 
                before the planned event. Can be entered as number of seconds or as minutes and seconds (i.e. 20:30 would be 20 minutes and 30 seconds).
            </p>
            <p>
                For example:
            </p>
            <ul>
                <li>Equipment Fault Starts at 10:00:30.</li>
                <li>Operator starts Planned Downtime at 10:01:00 and Grace Period is 1 minute.</li>
                <li>Hour by Hour will not show the fault that started at 10:00:30 beause it started within the 1 minute before the Planned Downtime Event was started.</li>
            </ul>
    
        </div>
        <button mat-raised-button color="primary" (click)="helpEnabled = !helpEnabled">Close</button>
    </div>
</div>