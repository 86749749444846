<form [formGroup]="partFormGroup">
    <h3 mat-dialog-title *ngIf="type=='Add'">Add Part</h3>
    <h3 mat-dialog-title *ngIf="type=='Edit'">Edit Count</h3>
    <h3 mat-dialog-title *ngIf="type=='Submit'">Parts For Shift</h3>
    <div mat-dialog-content>
        <div *ngIf="type=='Add'" >
            <mat-form-field [style.width.px]="350">
                <input matInput cdkTextareaAutosize placeholder="Part Description" formControlName="PartDescription">
            </mat-form-field>

            <mat-divider></mat-divider>
        </div>

        <div *ngIf="type=='Add' || type == 'Edit'" >
            <mat-form-field [style.width.px]="200">
                <input matInput cdkTextareaAutosize type="number" size="1" min="0" placeholder="Prior" formControlName="Prior">
            </mat-form-field>

            <mat-divider></mat-divider>
        </div>

        <mat-form-field [style.width.px]="200">
            <input matInput cdkTextareaAutosize type="number" size="1" min="0" placeholder="Actual" formControlName="Actual">
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="fx-row-end-center">
        <button mat-raised-button (click)="save()" [disabled]="partFormGroup.invalid">Save</button>
        <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
    </div>
</form>