import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigItem, OAService } from '../../oa.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { filter, take } from 'rxjs/operators';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';

@Component({
  selector: 'app-planned-config-dialog',
  templateUrl: './planned-config-dialog.component.html',
  styleUrls: ['./planned-config-dialog.component.scss']
})
export class PlannedConfigDialog implements OnInit {
  objectid:string;
  site:string;
  environment:string;

  configData:ConfigItem[];

  helpEnabled:boolean=false;

  dataSource = new MatTableDataSource<any>();

  fgConfig: FormGroup;
   
  displayedColumns: string[] = ['Description','Duration','GracePeriod', 'Actions']

  constructor(
    public dialogRef: MatDialogRef<PlannedConfigDialog>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private timeFormat: TimeFormatPipe,
    private svcOA: OAService
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.environment = this.inData.environment;

    this.fgConfig = this.fb.group({
      rows: this.fb.array([])
    });

  }

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig() {
    this.svcOA.getPlannedDowntimeConfig(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        if (out) {
          this.configData = out["Body"];
          console.log("LOADED CONFIG DATA!", this.configData);

          this.fgConfig = this.fb.group({
            rows: this.fb.array(this.configData.map( 
                (val:ConfigItem) => this.getFormGroup(val.ConfigID, 
                                                      val.Description, 
                                                      this.timeFormat.convertToString(val.Duration), 
                                                      this.timeFormat.convertToString(val.GracePeriod)
                                                      )))
          });

          this.dataSource = new MatTableDataSource((this.fgConfig.get('rows') as FormArray).controls);
        }
      }
    );
  }

  getFormGroup(ConfigID:number, Description:string, Duration:string, GracePeriod:string, isEditing:boolean=false):FormGroup {
    return this.fb.group({
            ConfigID: new UntypedFormControl(ConfigID),
            Description: new UntypedFormControl({value: Description, disabled: false}, Validators.required),
            Duration: new UntypedFormControl({value: Duration, disabled: false},[this.validateTime(), Validators.required]),
            GracePeriod: new UntypedFormControl({value: GracePeriod, disabled: false},this.validateTime()),
            isEditing: new FormControl(isEditing),
            isDeleted: new FormControl(false)
          });
  }

  validateTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let timeString:string = control.value;

      let valid:boolean = true;

      // Allow blank value, the form control will have Validators.required if a value is required this just checks for valid time
      if (timeString.trim() != "") {
        valid = /^[0-9:]+$/.test(timeString);
      }

      return valid ? null : { validateTime: { value: control.value } };
    };
  }

  get configRows(): FormArray {
    return this.fgConfig.get('rows') as FormArray;
  }

  formatTime(control:AbstractControl) {
    if (!control.invalid) {
      let timeValue:string = control.value;
      let timeSeconds:number = 0;
      let timeFormatted:string = "";

      if (timeValue.trim() != "") {
        timeSeconds = this.timeFormat.convertToSeconds(timeValue);
        timeFormatted = this.timeFormat.convertToString(timeSeconds);
      }

      control.patchValue(timeFormatted);
    }
  }

  add(inForm) {
    let newRow:FormGroup = this.getFormGroup(-1,"","","", true);

    newRow.markAllAsTouched();

    inForm.get('rows').push(newRow);

    this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
  }

  editLine(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(true);
  }

  delete(inForm, idx:number):void {
    if (inForm.get('rows').at(idx).get('ConfigID').value != -1) {
      inForm.get('rows').at(idx).get('isDeleted').patchValue(true);
      this.fgConfig.markAsDirty();
    } else {
      inForm.get('rows').removeAt(idx);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }

  }

  undo(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(false);
    inForm.get('rows').at(idx).get('isDeleted').patchValue(false);

    inForm.get('rows').at(idx).get('Description').patchValue(this.configData[idx]["Description"]);
    inForm.get('rows').at(idx).get('Duration').patchValue(this.timeFormat.convertToString(this.configData[idx]["Duration"]));
    inForm.get('rows').at(idx).get('GracePeriod').patchValue(this.timeFormat.convertToString(this.configData[idx]["GracePeriod"]));
    inForm.get('rows').at(idx).markAsPristine();
  }

  save() {
    // Return an array with all the times converted from strings to number of seconds
    let adjustedItems:ConfigItem[] = this.fgConfig.get('rows').value.map( (item) => {
      let configItemUpdate:ConfigItem = {
        ConfigID: item.ConfigID,
        Description: item.Description,
        Duration: this.timeFormat.convertToSeconds(item.Duration),
        GracePeriod: this.timeFormat.convertToSeconds(item.GracePeriod),
        Active: item.isDeleted ? 0 : 1
      }
      console.log("configItemUpdate", configItemUpdate);
  
      return configItemUpdate;

    });
    
    // Get filtered array of items that have changed, this includes deletes
    let changedItems:ConfigItem[] = adjustedItems.filter( 
      (item, index) => {
        // Check if record has been marked for delete or a change has been made
        if (item.Active == 0 ||
            ( item.ConfigID == -1 ||
              this.configData[index].Description != item.Description || 
              this.configData[index].Duration != item.Duration ||
              this.configData[index].GracePeriod != item.GracePeriod
            )) {      
            return true; // Record is add, update, or delete
        } else {
          return false; // Record hasn't changed don't include
        }
      });

    if (changedItems.length > 0) {
      this.svcOA.savePlannedDowntimConfig(this.environment, this.objectid, changedItems).subscribe(
        (out) => {
          this.close(true);
        }
      );
    } else {
      this.close(false);
    }
  }

  close(updates:boolean = false) {
    this.dialogRef.close({
      updates: updates
    });
  }

}