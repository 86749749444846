import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private Activatedroute:ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    console.log("In HOME Component!");
    console.log(this.Activatedroute.queryParamMap);
    this.Activatedroute.queryParamMap.forEach(value => {
      console.log("VALUE:", value);
    })
  }

}
