import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum ChangeStatus {
  UNKNOWN,
  NO_CHANGES,
  CHANGES_VALID,
  CHANGES_INVALID
}

@Injectable({
  providedIn: 'root'
})
export class ProblemService extends AbstractRestService {
  private objectid:string;
  private site:string;

  private saveTrigger:Subject<boolean> = new Subject<boolean>(); // Use Subject because we don't want a current value for new subscribers.
  private reloadTrigger:Subject<boolean> = new Subject<boolean>();

  private clipboard:BehaviorSubject<any> = new BehaviorSubject<any>(null); // Use BehaviorSubject because we want to have a current value for new subscribers.
  private changeStatus:BehaviorSubject<ChangeStatus> = new BehaviorSubject<ChangeStatus>(ChangeStatus.UNKNOWN);
  private changes:ChangeStatus[] = []; 

  postInit(): void {}

  setClipboard(value:any):void {
    this.clipboard.next(value);
  }

  getClipboard(site?:string, objectid?: string):Observable<any> {
    console.debug("get clipboard site, objectid", site,objectid);
    if (site && objectid) {
      if (this.site != site || this.objectid != objectid) {
        this.site = site;
        this.objectid = objectid;

        this.clipboard.next(null);
      }
    }

    return this.clipboard.asObservable();
  }

  getSaveTrigger():Observable<boolean> {
    return this.saveTrigger.asObservable();
  }

  setSavetrigger(status:boolean):void {
    this.saveTrigger.next(status);
  }

  getReloadTrigger():Observable<boolean> {
    return this.reloadTrigger.asObservable();
  }

  setReloadTrigger(status:boolean):void {
    this.reloadTrigger.next(status);
  }

  setChangeStatus(index:number, status:ChangeStatus):void {
    this.changes[index] = status;

    let maxValue:ChangeStatus = ChangeStatus.NO_CHANGES;

    this.changes.forEach( element => { maxValue = element > maxValue ? element : maxValue; });
    this.changeStatus.next(maxValue);
  }

  getChangeStatus():Observable<ChangeStatus> {
    return this.changeStatus.asObservable();
  }

  ungroupFault(objectid:string, site:string, shiftdate:string, shift:string, hour:number, faultvalue:number, faultmessage:string, problem:string, problemL2:string, problemL3:string, notes:string,):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.objectid = objectid;
    params.site = site;
    params.shiftdate = shiftdate;
    params.shift = shift;
    params.hour = hour.toString();
    params.faultvalue = faultvalue;
    params.faultMessage = faultmessage;
    params.problem = problem;
    params.probleml2 = problemL2;
    params.probleml3 = problemL3;
    params.notes = notes;

    return this.post({
      ApiResource: "/faultungroup",
      data: params,
      repeatCall: false
    })
  }
}
