import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  
  filterFormGroup:UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<FilterComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.filterFormGroup = this.fb.group({
      MinDuration: new UntypedFormControl({value: inData.minDuration, disabled: false}, Validators.required)
    });    
  }

  ngOnInit(): void {
  }

  filter() {

    this.dialogRef.close({minDuration: this.filterFormGroup.get('MinDuration').value});
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close({minDuration: 0});
  }

}
