import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MetricsService } from 'src/app/services/metrics.service';
import * as dayjs from 'dayjs';
import FusionChartsEvent from 'src/app/angular-fusioncharts/interfaces/FusionChartsEvent';
import { FormControl } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-pareto',
  templateUrl: './pareto.component.html',
  styleUrls: ['./pareto.component.scss']
})
export class ParetoComponent implements OnInit, OnDestroy {

  @Input() DatePickerIndex:number = 0;

  objectid:string;
  site:string;

  object:ObjectNode;

  cycleTime:number;
  cycleTimeRetrieved$: Subject<boolean> = new Subject<boolean>(); // Used in takeUntil to stop subscription when cycle time is retrieved
  
  dataSource: any;
  formattedData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.
  filteredData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.
  filterData:any[] = [];

  shifts:any[] = ['1ST SHIFT','2ND SHIFT','3RD SHIFT']

  filter = new FormControl('');
  shiftSelect = new FormControl();

  paertoSub$: Subscription;
  paretoData:any = {};

  // Parameters for pulling Pareto data
  level:number = 1;
  startDate:string;
  endDate:string;
  filterLevel1:string = '';
  filterLevel2:string = '';

  labelSeperator:string = '->';
  showDetail:boolean = false;
  showBy:string = "duration";



  constructor(
    private route: ActivatedRoute,
    private svcMetrics:MetricsService,
    private svcObjectList:ObjectListService,
    private zone: NgZone
  ) { 
    this.setChartData(); // Need to set the default chart config even though we don't have data yet
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
    this.shiftSelect.setValue(this.shifts);
  }
  
  ngOnInit(): void {
    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;
        
        this.getData();
      }
    );
    
  }
 
  applyFilter() {
    this.filteredData = this.formattedData.filter(rec => !this.filter.value.includes(rec.label));
    this.setChartData();
  }


  getData() {
    // First get the Ideal Cycle Time from header and then start pulling Pareto data
    this.svcMetrics.metricsHeader(this.object).pipe(takeUntil(this.cycleTimeRetrieved$)).subscribe(
      out => {
        // Check if we got a return, possible to get a null due to caching in the service
        if (out) {
          this.cycleTime = out.Body.PRODUCTION.idealCycleTime;
          this.getParetoData();
          
          this.cycleTimeRetrieved$.next(true); // Once we have the cycle time, set Subject to true to force this to end this subscription.
          this.svcMetrics.cleanupObservers();
        }
      }
    );
  }

  clearFilter(level:number = 1) {
    this.level = level;
    if (level == 1) {
      this.filterLevel1 ='';
      this.filterLevel2 ='';
    } else {
      this.filterLevel2 = '';
    }
    this.getParetoData();
  }

  onDateSelection(e) {
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
    this.getParetoData();
  }

  getParetoData() {
    if (this.paertoSub$) this.paertoSub$.unsubscribe();

    let myLevel:number = (this.showDetail) ? 3 : this.level;

    this.paertoSub$ = this.svcMetrics.pareto(this.site, this.objectid, this.startDate, this.endDate, myLevel, this.filterLevel1, this.filterLevel2, this.shiftSelect.value.toString()).subscribe(
      (data) => {
        this.paretoData = data.Body;

        let myFormattedData:any = [];
        this.formattedData = [];

        this.paretoData.forEach(element => {
          let myLabel:string = element.Level1;


          if ((this.showDetail || this.level > 1) && element.Level2 != '') {
            myLabel = myLabel.concat(this.labelSeperator + element.Level2);
          }

          if ((this.showDetail || this.level > 2) && element.Level3 != '') {
            myLabel = myLabel.concat(this.labelSeperator + element.Level3);
          }

          let myValue:number;

          if (this.showBy == 'duration') {
            myValue = element.Downtime;
          } else if (this.showBy == 'occurences') {
            myValue = element.Occurences;
          } else {
            myValue = Math.floor(element.Downtime / this.cycleTime); // Calculate Lost Units
          }

          myFormattedData.push({
            label:myLabel,
            value: myValue 
          });

          this.formattedData = myFormattedData; //.filter(rec => rec.label != 'Not Entered' );
        });

        console.debug("this.formattedData", this.formattedData);

        this.applyFilter();
      }
    )
  }

  setGrouping(grouping:string) {
    this.showBy = grouping;

    this.getParetoData();
  }
  
  setChartData() {
    this.dataSource = null;
    let pYAxisName:string;

    if (this.showBy == 'duration') {
      pYAxisName = 'Duration (seconds)';
    } else if (this.showBy == 'occurences') {
      pYAxisName = 'Occurences';
    } else {
      pYAxisName = 'Lost Units';
    }

    this.dataSource = {
      "chart": {
          "caption": "",
          "subCaption": "",
          "xAxisName": "Root Cause",
          "pYAxisName": pYAxisName,
          "sYAxisname": "Cumulative Percentage",
          "showHoverEffect": "1",
          "theme": "fusion",
          "plotToolText": "Root Cause: $label <br> Lost Units: $value"
      },
      "data": this.filteredData
    };
  }

  drilldown(e:FusionChartsEvent) {
    console.debug("Drilldown!!!",e);
    let clickedItem:string = e.eventObj['data']['index'];
    let levelSelected:string = this.filteredData[clickedItem].label;
    
    if (this.filterLevel1 == '') {
      let arrLabel = levelSelected.split(this.labelSeperator);
      this.level = 2;
      this.filterLevel1 = arrLabel[0];
    } else {
      let arrLabel = levelSelected.split(this.labelSeperator);
      this.level = 3;
      this.filterLevel2 = arrLabel[1];
    }

    // FusionChart events are note run in the same zone as Angular, need to trigger the refresh in the Angular zone
    this.zone.run(() => {
      this.getParetoData();
    });
  }

  /**
   * Toggles between showing just top level and showing all levels
   */
  toggleDetail() {
    this.showDetail = this.showDetail ? false : true;
    this.getParetoData();
  }
  
  ngOnDestroy(): void { 
    if (this.paertoSub$) this.paertoSub$.unsubscribe();
    this.cycleTimeRetrieved$.next(true); // Force cycle time sub to end
  }

}
