import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {MatAccordion} from '@angular/material/expansion';

// Services
import { AdminService } from '../../services/admin.service'
import { ObjectListService } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-object-dialog',
  templateUrl: './object-dialog.component.html',
  styleUrls: ['./object-dialog.component.scss']
})
export class ObjectDialogComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;  
  title:string;
  objFormGroup: UntypedFormGroup;
  disable: boolean;
  selectedSite: string;

  plantList: any;
  problemGroups: any;
  teamGroups: any;
  trSchemaList: any;
  data: ObjectConfigType;

  constructor(
    public dialogRef: MatDialogRef<ObjectDialogComponent>,
    private adminService: AdminService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: ObjectConfigType,
    private svcObjectList: ObjectListService,
  ) {
    console.log("Open Dialog", inData);

    this.data = inData['objData'];
    console.log("this.data.​ProbNoteReq", this.data.​ProbNoteReq);

    if (this.data.isEdit) {
      this.title = "Edit Object"; 
    } else {
      this.title = "Create Object";
    }

    this.adminService.plantList().subscribe((
      out => {
        this.plantList = out.Body;
        if(this.data.isEdit) {
          this.selectPlant();
        }             
      }
    ));

    this.adminService.problemGroups('ALL').subscribe((
      out => {
        this.problemGroups = out.Body;
      }
    ));

    this.adminService.teamGroupsGet('ALL').subscribe((
      out => {
        this.teamGroups = out.Body.sort((n1,n2) => {
              if (n1.Description > n2.Description) {
                  return 1;
              }
          
              if (n1.Description < n2.Description) {
                  return -1;
              }
          
              return 0;
          });
        
        this.teamGroups.unshift({"Team": ""});
      }
    ));    

    this.adminService.trSchemaList().subscribe((
      out => {
        this.trSchemaList = out.Body;
      }
    ));    

    this.disable = this.data.isEdit;

    if (this.data.PlantName == "MISSING PLANT") {
      this.disable = false;
    }

    console.log("data", this.data);
    console.log("this.data.​ProbNoteReq", this.data.​ProbNoteReq);
    this.objFormGroup = this.fb.group({
      PlantName: new UntypedFormControl({value: this.data.PlantName, disabled: this.disable}, Validators.required),
      ObjectID: new UntypedFormControl({value: this.data.ObjectID, disabled: this.disable}, Validators.required),
      Description: new UntypedFormControl({value: this.data.Description, disabled: false}),
      Section: new UntypedFormControl({value: this.data.​Section, disabled: false}),
      ObjectType: new UntypedFormControl({value: this.data.ObjectType, disabled: false}, Validators.required),
      ManCount: new UntypedFormControl({value: this.data.ManCount, disabled: false}, Validators.required),
      ManProb: new UntypedFormControl({value: this.data.ManProb, disabled: false}, Validators.required),
      ProbLevelReq: new UntypedFormControl({value: this.data.ProbLevelReq, disabled: false}, Validators.required),
      ProbNoteReq: new UntypedFormControl({value: this.data.​ProbNoteReq, disabled: false}, Validators.required),
      ProblemGroup: new UntypedFormControl({value: this.data.​ProblemGroup, disabled: false}, Validators.required),
      TeamGroup: new UntypedFormControl({value: this.data.TeamGroup, disabled: false}),
      RepairLine: new UntypedFormControl({value: this.data.​RepairLine, disabled: false}),
      RepairLocation: new UntypedFormControl({value: this.data.​RepairLocation, disabled: false}),
      SchemaName: new UntypedFormControl({value: this.data.​SchemaName, disabled: false}),
      TestArea: new UntypedFormControl({value: this.data.​TestArea, disabled: false}),
      TestLine: new UntypedFormControl({value: this.data.TestLine, disabled: false}),
      TestStation: new UntypedFormControl({value: this.data.​TestStation, disabled: false}),
      TestType: new UntypedFormControl({value: this.data.​TestType, disabled: false})
    });

   }

  ngOnInit(): void {
  }

  selectPlant() {
    const d = this.objFormGroup.getRawValue().PlantName
    console.log("SELECT() BEFORE", d);
    console.log("SELECT()", this.plantList);
    for (let plant in this.plantList) {
      if (this.plantList[plant].display_name == d) {
        //this.selectedSite = this.plantList[plant].display_name.substring(0,3);
        this.selectedSite = this.plantList[plant].display_name;
      }
    }
  }  

  saveData(): void {
    console.log("Save Data", this.objFormGroup)
    let bClose:boolean = true;
    let objectlistreload:boolean = true;
    
    let rawFormData = this.objFormGroup.getRawValue();
    console.log("rawFormData.Description", rawFormData.Description);

    let Description:string = "";
    let Section:string = "";
    let TeamGroup:number;
    let ObjectType:string = "";

    Description = rawFormData.Description == undefined ? "" : rawFormData.Description ;
    Section = rawFormData.Section == undefined ? "" : rawFormData.Section ;
    TeamGroup = rawFormData.TeamGroup == undefined ? "" : rawFormData.TeamGroup;
    ObjectType = rawFormData.ObjectType == undefined ? "" : rawFormData.ObjectType ;
    if (bClose) {
      let data:ObjectConfigType = {
        "ObjectID": rawFormData.ObjectID,
        "ObjectType": rawFormData.ObjectType,
        "Description": Description,
        "PlantName": this.selectedSite,
        "ManCount": rawFormData.ManCount,
        "ManProb": rawFormData.ManProb,
        "ProbNoteReq": rawFormData.ProbNoteReq,
        "ProbLevelReq": rawFormData.ProbLevelReq,
        "ProblemGroup": rawFormData.ProblemGroup,
        "TeamGroup": TeamGroup,
        "RepairLine": rawFormData.​RepairLine,
        "RepairLocation": rawFormData.​RepairLocation,
        "SchemaName": rawFormData.​SchemaName,
        "Section": Section,
        "TestArea": rawFormData.​TestArea,
        "TestLine": rawFormData.​TestLine,
        "TestStation": rawFormData.​TestStation,
        "TestType": rawFormData.​TestType        
      }

      this.adminService.editObject(data).subscribe(
        (out) => {
          this.svcObjectList.objectList(objectlistreload);
          console.log("Success", out);
          this.dialogRef.close(this.selectedSite);
        },
        (err) => {
          console.log("Something failed", err);
        }
      );
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }  

}

export interface ObjectConfigType {
  isEdit?: boolean;
  PlantName?: string;
  ObjectID?: string;
  Description?: string;
  ManCount?: string;
  ManProb?: number;
  ProbLevelReq?: number;
  ProbNoteReq?: number;
  ProblemGroup?: number;
  TeamGroup?: number;
  ObjectType?: string; 
  RepairLine?: string;
  RepairLocation?: string;
  SchemaName?: string;
  Section?: string;
  TestArea?: string;
  TestLine?: string;
  TestStation?: number;
  TestType?: string;
}