import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfigItem, OAService, PlannedSched } from '../../oa.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { filter, take } from 'rxjs/operators';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';
import { PlannedDefaultsDialog } from '../planned-defaults-dialog/planned-defaults-dialog.component';
import { Observable, forkJoin } from 'rxjs';

// Dayjs imports, localize the namespace with const because this is a library
import * as dayjs_ from 'dayjs';
const dayjs = dayjs_;

@Component({
  selector: 'app-planned-scheduled-dialog',
  templateUrl: './planned-scheduled-dialog.component.html',
  styleUrls: ['./planned-scheduled-dialog.component.scss']
})
export class PlannedScheduledDialog implements OnInit {
  objectid:string;
  site:string;
  environment:string;

  configData:ConfigItem[];
  schedData:PlannedSched[];
  availShifts:string[];

  helpEnabled:boolean=false;

  dataSource = new MatTableDataSource<any>();

  fgConfig: FormGroup;
   
  displayedColumns: string[] = ['ShiftDate','Shift','Description','Duration','Actions']

  constructor(
    public dialogRef: MatDialogRef<PlannedScheduledDialog>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inData: any,
    private timeFormat: TimeFormatPipe,
    private svcOA: OAService
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.environment = this.inData.environment;

    this.configData = [];
    this.availShifts = ["1ST SHIFT", "2ND SHIFT", "3RD SHIFT"];

    this.fgConfig = this.fb.group({
      rows: this.fb.array([])
    });

  }

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig() {
    this.svcOA.getPlannedDowntimeConfig(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        if (out) {
          this.configData = out["Body"];

          this.getPlannedSchedule();
        }
      }
    );
  }

  getPlannedSchedule() {
    this.svcOA.getPlannedDowntimeSched(this.site, this.environment, this.objectid).pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
        this.schedData = out["Body"];

        this.fgConfig = this.fb.group({
          rows: this.fb.array(this.schedData.map( 
              (val:PlannedSched) => this.getFormGroup(val.pKey,
                                                    this.configData.findIndex( item => item.ConfigID == val.ConfigID), 
                                                    val.ShiftDate,
                                                    val.Shift
                                                    )))
        });

        this.dataSource = new MatTableDataSource((this.fgConfig.get('rows') as FormArray).controls);

      });
  }

  getFormGroup(pKey:number, ConfigIdx:number, ShiftDate:string, Shift:string, isNew:boolean=false):FormGroup {
    return this.fb.group({
            pKey: new UntypedFormControl(pKey),
            ShiftDate: new UntypedFormControl({value: ShiftDate, disabled: false}, [this.validateDate(),Validators.required]),
            Shift: new UntypedFormControl(Shift),
            ConfigIdx: new UntypedFormControl(ConfigIdx),
            isNew: new FormControl(isNew),
            isDeleted: new FormControl(false)
          });
  }

  // This is needed to get the errors in mat-error
  get schedRows(): FormArray {
    return this.fgConfig.get('rows') as FormArray;
  }

  validateDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let dateString:string = control.value;

      let valid:boolean = true;

      if (dateString.trim() != "") {
        valid = dayjs(dateString).isValid();
      }

      return valid ? null : { validateDate: { value: control.value } };
    };
  }

  formatTime(control:AbstractControl) {
    if (!control.invalid) {
      let timeValue:string = control.value;
      let timeSeconds:number = 0;
      let timeFormatted:string = "";

      if (timeValue.trim() != "") {
        timeSeconds = this.timeFormat.convertToSeconds(timeValue);
        timeFormatted = this.timeFormat.convertToString(timeSeconds);
      }

      control.patchValue(timeFormatted);
    }
  }

  add(inForm) {
    let newRow:FormGroup = this.getFormGroup(-1, 1,dayjs().format('YYYY-MM-DD'),'1ST SHIFT',true);

    newRow.markAllAsTouched();
    newRow.markAsDirty();
    inForm.get('rows').push(newRow);

    this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
  }

  editLine(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(true);
  }

  delete(inForm, idx:number):void {
    if (!inForm.get('rows').at(idx).get('isNew').value) {
      inForm.get('rows').at(idx).get('isDeleted').patchValue(true);
      this.fgConfig.markAsDirty();
    } else {
      inForm.get('rows').removeAt(idx);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }

  }

  undo(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(false);
    inForm.get('rows').at(idx).get('isDeleted').patchValue(false);

    inForm.get('rows').at(idx).get('Description').patchValue(this.configData[idx]["Description"]);
    inForm.get('rows').at(idx).get('Duration').patchValue(this.timeFormat.convertToString(this.configData[idx]["Duration"]));
    inForm.get('rows').at(idx).get('GracePeriod').patchValue(this.timeFormat.convertToString(this.configData[idx]["GracePeriod"]));
    inForm.get('rows').at(idx).markAsPristine();
  }

  /**
   * Opens the Planned Donwtime defaults dialog, no reload happens on close
   */
  openDefaults() {
    const dialogRef = this.dialog.open(PlannedDefaultsDialog, {
      width: '1000px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        environment: this.environment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          console.debug("Closed defaults, no refresh actions taken");
      }
    });
  }

  save() {
    // Return an array with all the times converted from strings to number of seconds
    let adjustedItems:PlannedSched[] = this.fgConfig.get('rows').value.map( (item) => {
      let schedUpdate:PlannedSched = {
        pKey: item.pKey,
        ConfigID: this.configData[item.ConfigIdx].ConfigID,
        ShiftDate: item.ShiftDate,
        Shift: item.Shift,
        Description: this.configData[item.ConfigIdx].Description,
        Duration: this.configData[item.ConfigIdx].Duration,
        GracePeriod: this.configData[item.ConfigIdx].GracePeriod,
        isDeleted: item.isDeleted,
        isNew: item.isNew
      }
  
      return schedUpdate;

    });
    
    let newItems:PlannedSched[] = adjustedItems.filter(item => item.isNew);
    let deletedItems:PlannedSched[] = adjustedItems.filter(item => item.isDeleted);

    console.debug("newItems", newItems);
    console.debug("deletedItems", deletedItems);

    let obsUpdates:Observable<any>[] = []; // Area of observables for all updates

    if (newItems.length > 0) {
      obsUpdates.push(this.svcOA.addPlannedDowntimeSched(this.environment, this.objectid, newItems));
    }

    if (deletedItems.length > 0) {
      let pKeys:number[] = deletedItems.map(item => item['pKey']);
      obsUpdates.push(this.svcOA.deletePlannedDowntimeSched(this.environment, this.objectid, pKeys))
    }

    // If any add/updates/deletes then execute and wait, otherwise just close
    if (obsUpdates.length > 0) {
      forkJoin(obsUpdates).subscribe(
        result => {
          console.debug("Save Done", result);
          this.close(true);
        }
      );
    } else {
      this.close(false);
    }   
  }

  close(updates:boolean = false) {
    this.dialogRef.close({
      updates: updates
    });
  }

}