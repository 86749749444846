<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
<form [formGroup]="addFormGroup">
    <mat-table [dataSource]="teams">
        <ng-container matColumnDef="Team">
            <mat-header-cell *matHeaderCellDef>Team</mat-header-cell>
            <mat-cell *matCellDef="let d">
                <div *ngIf="(d.Team != '')">{{d.Team}}</div>

                <mat-form-field  *ngIf="(d.Team == '') && adminFlag" floatLabel="never">
                    <input  matInput cdkTextareaAutosize placeholder="New Team" formControlName="NewTeam">
                </mat-form-field>
            </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index">
            <button [disabled]="!adminFlag"  *ngIf="(d.Team != '')" mat-button color="warn" (click)="delete(d.Team)"><mat-icon>delete_forever</mat-icon>Delete Team</button>
            <button [disabled]="!adminFlag"  *ngIf="(d.Team == '') && adminFlag" mat-button color="primary" (click)="add()"><mat-icon>add_circle</mat-icon>Add Team</button>
            </mat-cell>
        </ng-container> 
        
        

        <mat-header-row *matHeaderRowDef="displayedObjectColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedObjectColumns;"></mat-row>      
    </mat-table>
</form>
</div>   
<div mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</div>