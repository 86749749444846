    <h3 mat-dialog-title>Reopen Problem</h3>
    <div mat-dialog-content>
        
        <div> Are you sure you want to reopen Problem:</div>
        <div>{{problem.Description}}</div>

    </div>
    <div mat-dialog-actions>
        <button class="mat-raised-button" (click)="reopen()">Reopen</button>
        <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
    </div>
