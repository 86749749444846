import { Component, OnInit,  OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MetricsService, team } from 'src/app/services/metrics.service';
import * as dayjs from 'dayjs';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { take } from 'rxjs/operators';
import { PaMatrixService, Problem } from 'src/app/pa-matrix/services/pa-matrix.service';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';

@Component({
  selector: 'app-problem-dialog',
  templateUrl: './problem-dialog.component.html',
  styleUrls: ['./problem-dialog.component.scss']
})
export class ProblemDialogComponent implements OnInit, OnDestroy {

  problemFormGroup: UntypedFormGroup;
  objectID: string;
  problemIDs:number[] =[];
  selectedHour: number;
  start: string;
  startDate: string = '';
  end: string;
  manual: boolean;
  site:string;
  minutes: number;
  seconds: number;

  duration:number;
  hours: any;
  problemHour: number;
  shiftStart: string;
  shiftEnd: string;
  currentValidationTime:string;

  problemSelectSub$: Subscription;
  problemSelectData:[];

  category:string = '';
  categoryL2:string = '';
  categoryL3:string = '';

  problemLevelOne:    string[] = []
  problemLevelTwo:    string[] = []
  problemLevelThree:  string[] = []
  teamList: team[] = []
  showTeam: boolean = false;
  addToPAMatrix: boolean = false;

  selectedLevelOne:   string = ""
  selectedLevelTwo:   string = ""
  selectedLevelThree: string = ""

  constructor(
    public dialogRef: MatDialogRef<ProblemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public problemData: ProblemDialogData,
    private problemFormBuilder: UntypedFormBuilder,
    private svcMetrics:MetricsService,
    private svcPAMatrix:PaMatrixService,
    private geaMesCognito:GeaMesCognitoAuthService,
    private dialog: MatDialog
  ) { 
    console.log("ProblemDialog problemData", this.problemData);
    this.objectID = this.problemData.objectid;
    this.problemData.selectedhour? this.selectedHour = this.problemData.selectedhour : this.selectedHour = 0
    this.manual = this.problemData.manual;
    this.problemHour = this.selectedHour
    this.hours = this.problemData.hours
    this.showTeam = this.problemData.config.showTeam;
    
    if (this.hours.length > 0) {
      this.shiftStart = this.hours[0].Start
      this.shiftEnd = this.hours[this.hours.length-1].End
    }

    let notes:string = '';
    this.start = this.hours[this.problemHour - 1].Start;
    this.end = this.hours[this.problemHour - 1].End;

    // Define all field validation
    let optionsStartTime:any[] = this.manual ? [Validators.required, this.validatorStartTime()] : [];
    let optionsMinutes:any[] = []; // No field validation at minute or seconds, the validateDuration() formgroup validator checks total duration
    let optionsSeconds:any[] = [];
    let team:any[] = this.showTeam ? [Validators.required] : [];
    let optionsCategoryL1:any[] = [Validators.required]; // Always required level 1
    let optionsCategoryL2:any[] = (this.problemData.config.problemLevelRequired > 1) ? [Validators.required] : [];
    let optionsCategoryL3:any[] = (this.problemData.config.problemLevelRequired > 2) ? [Validators.required] : [];
    let optionsNotes:any[] = this.problemData.config.problemNoteRequired ?  [Validators.required] : [];

    this.problemFormGroup = this.problemFormBuilder.group({
      form_startTime:[(this.start == null)? '': this.start, optionsStartTime],
      form_minutes:[(this.minutes == null)? '': this.minutes, optionsMinutes],
      form_seconds:[(this.seconds == null)? '': this.seconds, optionsSeconds],
      form_team: [{value: '', disabled: false}, team],
      form_category: [this.category, optionsCategoryL1],
      form_categoryl2: [this.categoryL2, optionsCategoryL2],
      form_categoryl3: [this.categoryL3, optionsCategoryL3],
      form_notes: [notes, optionsNotes]
    })
  }

  onSelectLevelOne(problem: string){
    this.selectedLevelOne = problem

    if (this.selectedLevelOne in this.problemSelectData) {
      this.problemLevelTwo = Object.keys(this.problemSelectData[problem])
    } else {
      this.problemLevelTwo = ['']
    }
  }

  onSelectLevelTwo(problem: string){
    this.selectedLevelTwo = problem

    if (this.selectedLevelOne in this.problemSelectData && this.selectedLevelTwo in this.problemSelectData[this.selectedLevelOne]) {
      this.problemLevelThree = this.problemSelectData[this.selectedLevelOne][this.selectedLevelTwo]
    } else {
      this.problemLevelThree = ['']
    }
  }

  ngOnInit(): void {
    this.site = this.problemData.site;
    this.objectID = this.problemData.objectid;

    console.log("This is the log from ngOnInit:")
    console.log(this.problemData)

    this.getData();
  }

  getData() {
    if (this.problemSelectSub$) this.problemSelectSub$.unsubscribe();

    this.problemSelectSub$ = this.svcMetrics.problemList(this.site, this.objectID).subscribe(
      out => {
        this.problemSelectData = out.Body;
        this.problemLevelOne = Object.keys(this.problemSelectData)
        
        this.onSelectLevelOne(this.category)
        this.onSelectLevelTwo(this.categoryL2)
      });

      this.svcMetrics.teamList(this.site, this.objectID).pipe(take(1)).subscribe(
        out => {
          console.log("out.Body", out.Body);
          this.teamList = out.Body.Teams;
        }
      );
  }

  checkAddToPAMatrix(event) {
    console.log("Click add to add pa matrix", event);
  }

  saveManualProblem(): void {
    let problemIDs:string[] = [];
   
    let minutes:number = (this.problemFormGroup.get("form_minutes").value == '') ? 0 : this.problemFormGroup.get("form_minutes").value
    let seconds:number = (this.problemFormGroup.get("form_seconds").value == '') ? 0 : this.problemFormGroup.get("form_seconds").value
    let duration:number = minutes * 60 + seconds
    let startTime:string = this.problemFormGroup.get("form_startTime").value

    if (startTime.split(":").length == 2){
      startTime = startTime + ":00"
    }

    if (this.startDate != ""){
      startTime = this.startDate + " " + startTime 
    }
    else {
      startTime = " " + startTime
    }


    this.svcMetrics.saveManualProblem(
      this.site,
      this.objectID,
      this.problemHour,
      this.problemFormGroup.get("form_category").value, this.problemFormGroup.get("form_categoryl2").value,
      this.problemFormGroup.get("form_categoryl3").value, this.problemFormGroup.get("form_notes").value,
      startTime,
      duration,
      problemIDs,
      this.manual,
      this.problemFormGroup.get("form_team").value
    ).subscribe(
      out => {
        console.log(out)
        this.dialogRef.close()
      }
    );

    // Create PA Matrix record if requested
    if (this.addToPAMatrix) {
      let problem:Problem = {
        Site: this.site,
        ObjectID: this.objectID,
        ItemNumber: null,
        Description: this.problemFormGroup.get("form_category").value + ', ' + this.problemFormGroup.get("form_categoryl2").value + ', ' + this.problemFormGroup.get("form_categoryl3").value + ', ' + this.problemFormGroup.get("form_notes").value,
        CreatedDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        DueDate: '',
        ClosedDate: '',
        CreatedBy: '',
        AssignedTo: '',
        LoggedBy: this.geaMesCognito.getUserAttribute("sso"),
        Status: 1
      }

      this.svcPAMatrix.problemAddEdit(problem).subscribe( 
        out => {
          console.log("pa matrix record added", out);
        }
      );
    }
  }

  removeProblem(changeType:string='delete'){
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: 
      {
        site: this.site,
        objectid: this.objectID,
        problemids: this.problemIDs,
        changeType: changeType
      }
    
    });
    dialogRef.afterClosed().subscribe(
      result => {
        console.debug(result)
        if (result && (result.status == 'deleted' || result.status== 'cleared')){
          this.dialogRef.close()
        }
      });
  }  

  validatorStartTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let inTime: string = control.value;
      let validTime: boolean = false;
      
      this.currentValidationTime = dayjs().format('HH:mm:ss');
      this.problemHour = this.selectedHour

      if (this.currentValidationTime < inTime){
        return { futureTime: true };
      }
      else {
        validTime = this.validateHour(inTime);
        return !validTime ? { outsideShift: true } : null ;
      }
    } 
  }

 validateHour(startTime:string): boolean {
  let validHour: boolean = false;
 
  this.hours.forEach(
    (hour, index) => {
      console.debug(hour.Hour, hour.Start, hour.End)
      if (hour.Start > hour.End) {
        if (hour.Start <= startTime){
          this.problemHour = hour.Hour
          validHour = true;
          console.debug("This is in hrxhr: ", hour.Hour)
        }
        else if (hour.End >= startTime){
          this.problemHour = hour.Hour
          validHour = true;
          console.debug("This is in hrxhr: ", hour.Hour)
        }
      }
      else if (hour.Start <= startTime && hour.End > startTime){
        this.problemHour = hour.Hour
        validHour = true;
        console.debug("This is in hrxhr: ", hour.Hour)
      }
      else if (index == (this.hours.length -1) && hour.End == startTime){
        this.problemHour = hour.Hour
        validHour = true;
        console.debug("This is in hrxhr: ", hour.Hour)
      }
    })
    return validHour;
  }

  validateDuration(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let duration:number = control.get('form_minutes').value * 60 + control.get('form_seconds').value;

      console.log("duration", duration);

      if (duration > 0) {
        return null;
      } else {
        return { durationError: true };
      }
    } 
  }

  ngOnDestroy() {
    if (this.problemSelectSub$) this.problemSelectSub$.unsubscribe();
  }

  onNoClick() {
    this.dialogRef.close();
  }
}


export interface ProblemDialogData {
  objectid: string;
  selectedhour: number;
  start?: string;
  end?: string;
  manual: boolean;
  site: string;
  hours: any;
  shift: string;
  shiftDate: string;
  problem?: ProblemData;
  faults?: FaultData[];
  config: ProblemConfig;
}
export interface ProblemData {
  Problem: string;
  Problem_Level2: string;
  Problem_Level3: string;
  Duration: string;
  Notes: string;
  Start: Date;
  End: Date;
  ID: number;
}

export interface FaultData {
  FaultMessage: string;
  FaultValue: number;
  Hour: string;
  Problem: string;
  Problem_Level2: string;
  Problem_Level3: string;
  Problem_time_amt: number;
  Notes: string;
  ID: number;
}

export interface ProblemConfig {
  problemLevelRequired:number;
  problemNoteRequired:boolean;
  showTeam:boolean;
}