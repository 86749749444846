import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appinfo } from './appinfo';

declare var gtag_setValue; // Declare function defined in index.html

const APP_TITLE:string = 'HRXHR';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title:string = APP_TITLE;
  subtitle:string = APP_TITLE;
  version: any;
  trackingid:string;
  logon$: Subscription;

  constructor (
    private geaMesCognito: GeaMesCognitoAuthService
  )
  {
    this.version = appinfo.version;
    console.log("Version: ", this.version)

  }

  ngOnInit(): void {
    gtag_setValue({"appversion": appinfo.version});

    this.logon$ = this.geaMesCognito.sessionEstablished$.subscribe( out => {
      this.trackingid = this.geaMesCognito.getUserAttribute("trackingid");
      if (this.trackingid != undefined) {
        gtag_setValue({"sub": this.trackingid});
        if (this.logon$) this.logon$.unsubscribe(); // We've done the work we need to, can unsubscribe
      }       
    });
  }  
}
