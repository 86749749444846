import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetricsService } from 'src/app/services/metrics.service';
import { FaultPreviewDialogComponent } from './fault-preview-dialog/fault-preview-dialog.component';
import { Subscription } from 'rxjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-hour-note-dialog',
  templateUrl: './hour-note-dialog.component.html',
  styleUrls: ['./hour-note-dialog.component.scss']
})
export class HourNoteDialogComponent implements OnInit, OnDestroy {
  userid:string;
  objectid:string;
  site:string;

  object:ObjectNode;

  shiftDate:string;
  shift:string;
  selectedID:string ='';
  hour:string;
  noteFormGroup:UntypedFormGroup;
  hourNotes:any;
  dataLoaded:boolean=false;
  metricsHourSub$: Subscription;
  
  displayedColumns: string[] = ['Note', 'Action', 'Edit']

  constructor(
    public dialogRef: MatDialogRef<HourNoteDialogComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private svcMetrics:MetricsService,
    private svcObjectList:ObjectListService,
    private svcAuth: GeaMesCognitoAuthService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.hour = this.inData.hour;

    this.noteFormGroup = this.fb.group({
      Note: new UntypedFormControl({value: "", disabled: false}),
      Action: new UntypedFormControl({value: "", disabled: false})
    });
  }

  ngOnInit(): void {
    this.userid = this.svcAuth.getUserAttribute('sso');

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;

        this.getHourNotes();
      }
    );

    
  }

  getHourNotes() {
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();

    this.metricsHourSub$ =this.svcMetrics.metricsHour(this.object, false).subscribe(
      out => {
        console.log("getHourNOtes Loaded", out)
        if (out) {
          this.hourNotes = out.Body.Hours.filter(h => h.Hour === this.hour)[0].Notes;
          this.shiftDate = out.Body.ShiftDate;
          this.shift = out.Body.Shift;
          this.dataLoaded = true;
          this.metricsHourSub$.unsubscribe();
        }
      }
    );
  }

  saveNote():void {
    let note:string = this.noteFormGroup.get('Note').value;
    let action:string = this.noteFormGroup.get('Action').value;

    if (note != "" || action != "") {
      this.svcMetrics.hourNoteUpdate(this.site, this.objectid, note, action, this.shiftDate, this.shift, this.hour, this.selectedID).subscribe(
        out => {
          console.log("Note Saved");
          this.noteFormGroup.patchValue({ Note: ''});
          this.noteFormGroup.patchValue({ Action: ''});
          this.selectedID = "";
          this.getHourNotes();
        }
      );
    }
  }

  editNote(note:any) {
    this.selectedID = note.id;
    this.noteFormGroup.patchValue({ Note: note.Note });
    this.noteFormGroup.patchValue({ Action: note.CorrectiveAction })
  }

  openFaultPreview() {
    console.log("Open Fault Preview");

    const dialogRef = this.dialog.open(FaultPreviewDialogComponent, {
      width: '700px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        object: this.object,
        hour: this.hour
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close Fault Preview");
    });
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

  ngOnDestroy(): void{ 
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
    this.svcMetrics.cleanupObservers();
  }
}
