<div class="rawDataComp">
      <div class="row2">
            <div class="fx-row-start-center">
                  <gea-mes-datepicker
                        class="dateRangeBut"
                        matTooltip="Select Date" 
                        (onDateSelect)="onDateSelection($event)" 
                        (onDateInit)="onDateSelection($event)"
                        singleDate="false" 
                        showLabel="true"
                        showCurrentShift="false"
                        defaultRange="Today"
                        showRangeLabel="true"
                        autoApply="true"
                        [index]=DatePickerIndex>
                  </gea-mes-datepicker>

                  <mat-form-field>
                        <mat-label>Select Shift</mat-label>
                        <mat-select [(value)]="shifts.values" [formControl]="shiftSelect" multiple (selectionChange)="getRawData()">
                          <mat-option *ngFor="let shift of shifts" [value]="shift">{{shift}}</mat-option>
                        </mat-select>
                  </mat-form-field>

                  <button mat-button color="primary" [disabled]="loadingData"  (click)="downloadAsCSV()"><mat-icon>download</mat-icon> Download</button>

            </div>
      </div>
      <div class="row3" style="height: 600px; overflow: auto;"> <!-- style="overflow: auto;" -->
            <div *ngIf="!loadingData">
                  <mat-table [dataSource]="rawData">

                        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
                              <mat-header-cell *matHeaderCellDef>{{column}}</mat-header-cell>
                              <mat-cell *matCellDef="let d">{{d[column]}}</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>          
                  </mat-table>
            </div>
            <div *ngIf="loadingData">
                  <mat-spinner>Loading...</mat-spinner>
            </div>
      </div>
</div>