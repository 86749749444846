import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerDemandLine, OAService } from '../../oa.service';
import { FormGroup, FormBuilder, AbstractControl, FormArray, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.scss']
})
export class DemandFormComponent implements OnInit {

  @Input() allowEdits:boolean = false;
  @Input() currentShift:boolean = false;
  @Input() site:string;
  @Input() environment:string
  @Input() objectid:string;
  @Input() title:string;
  @Input() jobConfig:any[];
  @Input() shift:string;
  @Input() shiftDate:string;

  @Input() set customerDemand(customerDemand: CustomerDemandLine[]) {
    console.log("Customerdemand", customerDemand);
    if (customerDemand) {
      this.customerDemandData = customerDemand;

      // Load the Form Group for selected shift
      this.fgDemand = this.fb.group({
        rows:this.fb.array(customerDemand.map( 
          (val:CustomerDemandLine) => this.getFormGroup(val)
          )
        )
      });

      this.dataSource = new MatTableDataSource((this.fgDemand.get('rows') as FormArray).controls);

      // Watch for changes
      if (this.fgDemandSub$) this.fgDemandSub$.unsubscribe();
    
      this.fgDemandSub$ = this.fgDemand.statusChanges.subscribe(
        (status) => {
          console.log("STATUS CHANGE", this.fgDemand.dirty, this.fgDemand.valid);
          if (this.fgDemand.dirty && this.fgDemand.valid) {
            this.needSave.next(true);
          } else {
            this.needSave.next(false);
          }
        }
      )
    }
  }
  
  @Input() set triggerSave(triggerSave: number) {
    console.log("trigger Save", triggerSave);

    if (triggerSave > 0) {
      this.save();
    }
  };

  @Output() needSave = new EventEmitter<boolean>();
  @Output() saveComplete = new EventEmitter<boolean>();
  
  availShifts:string[];
  fgDemand: FormGroup;

  fgDemandSub$: Subscription;

  customerDemandData:CustomerDemandLine[];

  dataSource = new MatTableDataSource<any>();

  displayedColumns: string[] = ['ShiftDate','Shift','JobID','JobDescription','Qty','Actions']

  constructor(
    private fb: FormBuilder,
    private svcOA: OAService
  ) { 

    this.availShifts = ["1ST SHIFT", "2ND SHIFT", "3RD SHIFT"];

    this.fgDemand = this.fb.group({
      rows: this.fb.array([])
    });
  }

  ngOnInit(): void {
  }

  test() {
  }

  getFormGroup(demand:CustomerDemandLine, isEditing:boolean=false, isNew:boolean=false):FormGroup {
    return this.fb.group({
            ShiftDate: new UntypedFormControl({value: demand.ShiftDate, disabled: false}, [this.validateDate(),Validators.required]),
            Shift: new UntypedFormControl({value: demand.Shift, disabled: false}, Validators.required), 
            JobID: new UntypedFormControl(demand.JobID),
            JobDescription: new UntypedFormControl(demand.JobDescription),
            Qty: new UntypedFormControl({value: demand.Qty > 0 ? demand.Qty : '', disabled: false}, [this.validateQty(),Validators.required]),
            JobIdx: new UntypedFormControl(isNew ? 0 : -1),     
            isNew: new FormControl(isNew),
            isEditing: new FormControl(isEditing),
            isDeleted: new FormControl(false)
          });
  }

  // This is needed to get the errors in mat-error
  get demandRows(): FormArray {
    return this.fgDemand.get('rows') as FormArray;
  }


  validateDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let dateString:string = control.value;

      let valid:boolean = true;

      if (dateString.trim() != "") {
        valid = dayjs(dateString).isValid();
      }

      return valid ? null : { validateDate: { value: control.value } };
    };
  }

  validateQty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let qty:string = control.value;

      let valid:boolean = true;
      
      if (Number(qty)) {
        if (Number(qty) < 0) {
          valid = false;
        }
      } else {
        valid = false;
      }

      return valid ? null : { validateQty: { value: control.value } };
    };
  }

  selectJobIdx(inForm, idx:number,e) {
    let ConfigIdx:number = e.value;

    let JobID:string = this.jobConfig[ConfigIdx].JobID;
    let JobDescription:string = this.jobConfig[ConfigIdx].JobDescription;

    inForm.get('rows').at(idx).get('JobID').patchValue(JobID);
    inForm.get('rows').at(idx).get('JobDescription').patchValue(JobDescription);

  }

  add(inForm) {
    let shiftDate:string = this.shiftDate;
    let shift:string = this.shift;

    let rowCount:number = inForm.get('rows').length;

    // If there are entries in the form for future shifts, use the last shiftdate/shift selected
    if (!this.currentShift && rowCount > 1) {
      shiftDate = inForm.get('rows').at(rowCount - 1).get('ShiftDate').value;
      shift = inForm.get('rows').at(rowCount - 1).get('Shift').value;
    }

    let jobDescription:string = "";
    let jobID:string = "";

    if (this.jobConfig.length > 0) {
      jobDescription = this.jobConfig[0].JobDescription;
      jobID = this.jobConfig[0].JobID;
    }

    let newDemand:CustomerDemandLine = {
      "ShiftDate": shiftDate,
      "Shift": shift,
      "JobDescription": jobDescription,
      "JobID": jobID,
      "Qty": 0
    }
    let newRow:FormGroup = this.getFormGroup(newDemand,true,true);

    newRow.markAllAsTouched();
    newRow.markAsDirty();

    inForm.get('rows').push(newRow);

    this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
  }

  editLine(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(true);
  }

  delete(inForm, idx:number):void {
    if (!inForm.get('rows').at(idx).get('isNew').value) {
      inForm.get('rows').at(idx).get('isDeleted').patchValue(true);
      this.fgDemand.markAsDirty();
      this.fgDemand.markAsTouched();
    } else {
      inForm.get('rows').removeAt(idx);

      this.dataSource = new MatTableDataSource((inForm.get('rows') as FormArray).controls);
    }
  }

  undo(inForm, idx:number) {
    inForm.get('rows').at(idx).get('isEditing').patchValue(false);
    inForm.get('rows').at(idx).get('isDeleted').patchValue(false);

    inForm.get('rows').at(idx).get('Qty').patchValue(this.customerDemandData[idx]["Qty"]);

    inForm.get('rows').at(idx).markAsPristine();
  }

  save() {
    // Return an array with all the times converted from strings to number of seconds
    let adjustedItems:CustomerDemandLine[] = this.fgDemand.get('rows').value.map( (item, index) => {
      let demand:CustomerDemandLine = {
        ShiftDate: item.ShiftDate,
        Shift: item.Shift,
        Qty: item.Qty,
        OldQty: !item.isNew ? this.customerDemandData[index].Qty : item.Qty,
        JobDescription: item.JobDescription,
        JobID: item.JobID,
        isDeleted: item.isDeleted,
        isNew: item.isNew
      }
  
      return demand;
    });


    let updateItems:CustomerDemandLine[] = adjustedItems.filter( (item,index) => (!item.isNew && item.Qty != this.customerDemandData[index].Qty));
    let newItems:CustomerDemandLine[] = adjustedItems.filter( item => item.isNew);
    let deletedItems:CustomerDemandLine[] = adjustedItems.filter( item => item.isDeleted);

    console.log("updateItems", updateItems);
    console.log("newItems", newItems);
    console.log("deletedItems", deletedItems);

    let obsUpdates:Observable<any>[] = []; // Area of observables for all updates

    // Load array with all adds
    newItems.forEach(
      (newItem) => {
        obsUpdates.push(this.svcOA.addCustomerDemand(this.site, this.environment, this.objectid, newItem));
      }
    );

    // Load array with all deletes
    deletedItems.forEach(
      (deletedItem) => {
        obsUpdates.push(this.svcOA.deleteCustomerDemand(this.site, this.environment, this.objectid, deletedItem));
      }
    );

    // Load array with all updates
    updateItems.forEach(
      (updateItem) => {
        obsUpdates.push(this.svcOA.updateCustomerDemand(this.site, this.environment, this.objectid, updateItem));
      }
    );
    
    // If any add/updates/deletes then execute and wait, otherwise just close
    if (obsUpdates.length > 0) {
      forkJoin(obsUpdates).subscribe(
        result => {
          console.log("CHANGES COMPLETE", result);
          this.saveComplete.next(true); // Signal parent that save is complete
        }
      );
    } else {
      console.log("NO CHANGES, SIGNAL WE'RE DONE");
      this.saveComplete.next(true); // Signal parent that save is complete
    }
  }

}
