<div style="display: flex">
    <h3 mat-dialog-title>Customer Demand Defaults</h3>
    <span class="spacer"></span>
    <button mat-icon-button color="primary" (click)="helpEnabled = !helpEnabled"><mat-icon>help</mat-icon></button>
</div>
<div mat-dialog-content>
<form [formGroup]="fgDefaults" autocomplete="off">
<ng-container formArrayName="rows">
    <ng-container>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="Shift">
                <mat-header-cell *matHeaderCellDef>Shift</mat-header-cell>
                <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                    <mat-form-field appearance="none" [style.width.px]="90">
                        <span *ngIf="d.value['isNew']">
                            <mat-select formControlName="Shift">
                                <mat-option *ngFor="let shift of availShifts" [value]="shift">{{shift}}</mat-option>
                            </mat-select>
                        </span>
                        <span *ngIf="!d.value['isNew']">
                            <input matInput type="text"
                                formControlName="Shift"  
                                maxlength="80"     
                                [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                [readonly]="true"/>
                        </span>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                    <mat-form-field appearance="none" [style.width.px]="300">
                        <span *ngIf="d.value['isNew']">
                            <mat-select formControlName="ConfigIdx" (selectionChange)="selectConfigIdx(fgDefaults, i, $event)">
                                <mat-option *ngFor="let config of jobConfig; let i = index" [value]="i">{{config.JobDescription}}</mat-option>
                            </mat-select>
                        </span>
                        <span *ngIf="!d.value['isNew']">
                            <input matInput type="text"
                                formControlName="Description"  
                                maxlength="80"     
                                [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                [readonly]="true"/>
                        </span>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Qty">
                <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                    <mat-form-field [appearance]="!d.value['isEditing'] ? 'none' : 'legacy'" [style.width.px]="60">
                        <input matInput type="text"
                               formControlName="Qty"  
                               maxlength="10"     
                               [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                               [readonly]="!d.value['isEditing']"/>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DaysOfWeek">
                <mat-header-cell *matHeaderCellDef>Day(s)</mat-header-cell>
                <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                    <mat-button-toggle-group multiple>
                        <ng-container *ngFor="let day of dayList; let idxDay = index">
                        <mat-button-toggle 
                            *ngIf="d.value['isEditing'] || d.value[day]"
                            (change)="changeDay(fgDefaults,i,$event)"
                            [checked]="!d.value['isDeleted'] && d.value[day] ? true : false"
                            [value]="day"
                            [disabled]="!d.value['isEditing']
                                        || d.value['isDeleted']
                                        || (!d.value[day] && disableList[d.value['JobID']][d.value['Shift']][day])
                            "
                        >
                            <span [class]="d.value['isDeleted'] ? 'deleted' : (!d.value[day] && disableList[d.value['JobID']][d.value['Shift']][day]) ? 'unavailable' : 'none'">
                                {{day}}
                            </span>
                        </mat-button-toggle>
                        </ng-container>
                        </mat-button-toggle-group>
                </mat-cell>

            </ng-container>

            <ng-container matColumnDef="Actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let d; let i = index">
                    <button *ngIf="!d.value['isDeleted'] && !d.value['isEditing']" 
                            mat-button 
                            color="primary" 
                            (click)="editLine(fgDefaults,i)">
                            <mat-icon>create</mat-icon>Edit
                    </button>

                    <span *ngIf="d.value['JobID'] == -1" style="min-width: 64px; max-width: 64px;"></span> 

                    <button *ngIf="(!d.value['isDeleted'] && !d.value['isEditing']) || d.value['isNew']" 
                             mat-button color="warn" 
                             (click)="delete(fgDefaults,i)">
                        <mat-icon>delete_forever</mat-icon>Delete
                    </button>
    
                    <button *ngIf="(d.value['isEditing'] || d.value['isDeleted']) && !d.value['isNew']" 
                            mat-button 
                            color="primary" 
                            (click)="undo(fgDefaults, i)">
                        <mat-icon>undo</mat-icon>
                        <span *ngIf="!d.value['isDeleted']">Undo - Edit</span>
                        <span *ngIf="d.value['isDeleted']">Undo - Delete</span>
                    </button>
                </mat-cell>
            </ng-container>
    
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>      
        </mat-table>
        <div>
            <button mat-button color="primary" (click)="add(fgDefaults)"><mat-icon>add_circle</mat-icon>Add New</button>
        </div>
    </ng-container>
</ng-container>
</form>
</div>   
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!fgDefaults.dirty || !fgDefaults.valid" color="primary" (click)="save()">Save and Close</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="close()">Cancel</button>
</div>

<!-- Help Panel-->
<div *ngIf="helpEnabled" class="help" (click)="helpEnabled = !helpEnabled">
    <div class="help-content" (click)="$event.stopPropagation()">
        <h1>Help -Customer Demand Defaults</h1>
        <div>
            <p>
                If you have a consistent estimate of customer demand that will apply to shifts you can click on Manage Defaults and set 
                defaults by day. Those defaults will be loaded every day so you do not have to manually create them.
            </p>
        </div>
        <button mat-raised-button color="primary" (click)="helpEnabled = !helpEnabled">Close</button>
    </div>
</div>