<div>
    <h3 mat-dialog-title><span class="fx-row-center-center">Top Faults by Duration</span></h3>
    <mat-card  class="metrics-card mat-elevation-z1">
        <mat-card-content>
            <mat-table [dataSource]="metricsTopProblemsData[hour - 1]['Faults']">
                <ng-container matColumnDef="Fault">
                    <mat-header-cell *matHeaderCellDef>Fault</mat-header-cell>
                    <mat-cell *matCellDef="let d"><div class="hour-note">{{d.Fault}}</div></mat-cell>
                </ng-container>

                <ng-container matColumnDef="Duration">
                    <mat-header-cell *matHeaderCellDef>Duration</mat-header-cell>
                    <mat-cell *matCellDef="let d"><div class="hour-note">{{d.Duration}}</div></mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>

    <div mat-dialog-actions class="fx-row-end-center">
        <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>
</div>