import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs';
import { AnalysisService } from '../../analysis-service.service';
import { Subscription } from 'rxjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { TimeFormatPipe } from 'src/app/pipes/time-format.pipe';
import { MatDialog } from '@angular/material/dialog';
import { PlannedDowntimeTableDialog } from './planned-downtime-table-dialog/planned-downtime-table-dialog.component';

@Component({
  selector: 'app-planned-downtime-chart',
  templateUrl: './planned-downtime-chart.component.html',
  styleUrls: ['./planned-downtime-chart.component.scss']
})
export class PlannedDowntimeChartComponent implements OnInit, OnDestroy {

  @Input() DatePickerIndex:number = 0;

  objectid:string;
  site:string;
  environment:string;

  dataSource: any;

  formattedData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.
  filteredData:any[] = [{ label: '', value: 0 }]; // To avoid console erorr, need to default the data to something.

  startDate:string;
  endDate:string;

  category:any[]; // Stores the categories for the chart
  dataset:any[]; // Stores the datasets for the chart
  downtimedata:any[] = [];

  plannedDTSub$:Subscription;
  plannedDTData:any = [];
  disableRawgDataView:boolean = true;

  constructor(
    private route: ActivatedRoute,
    private svcObjectList:ObjectListService,
    private svcAnalysis:AnalysisService,
    private timeFormat: TimeFormatPipe,
    private dialog: MatDialog
  ) {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
   }

  ngOnInit(): void {
    this.getObjectInfo();
  }

  ngOnDestroy(): void {
    if (this.plannedDTSub$) this.plannedDTSub$.unsubscribe(); 
  }

  getObjectInfo() {
    this.svcObjectList.objectList().subscribe(
      (out) => {
        let plantNode = out.Body.find( plant => plant.search == this.site); // Get PlantNode
        let objectNode:ObjectNode = this.findObjectID(plantNode); // Get ObjectID node

        if (objectNode) {
          this.environment = objectNode.Environment;
        }

        this.getChartData();
      }
    );
  }

  findObjectID(node:ObjectNode):ObjectNode {
    if (node.ObjectID && node.ObjectID == this.objectid) {
        return node;
    } else if (node.children) {
      let result:ObjectNode;

      for(let i = 0; result == null && i < node.children.length; i++) {
        result = this.findObjectID(node.children[i]);
      }
      return result;
    } 
    return null;
  }

  getChartData() {
    
    this.disableRawgDataView=true;

    if (this.plannedDTSub$) this.plannedDTSub$.unsubscribe();

    this.plannedDTSub$ = this.svcAnalysis.plannedDowntime(this.site, this.environment, this.objectid, this.startDate, this.endDate).subscribe(
      (out) => {

        this.category = [];
        this.dataset = [];

        let lastShiftNumber:number = -1;
        let lastSeriesName:string = "";

        let colorIdx:number = 0;
        let colorArray:string[] = ['#5d62b5','#29c3be','#f2726f'];
        let colorAlpha:string = "90";

        this.downtimedata = out.Body;
        this.disableRawgDataView = false;

        out.Body.forEach(element => {
          if (lastShiftNumber != element.ShiftNumber) {
            this.category.push({ "label": element.ShiftDate + " " + element.Shift })
            lastShiftNumber = element.ShiftNumber;
            lastSeriesName = "";
            colorAlpha = "90";
          }

          if (this.dataset.length < element.DataSetGroup) {
            this.dataset.push(
              {
                "dataset": []
              }
            )
          }

          let grpIdx:number = element.DataSetGroup - 1;
          
          if (this.dataset[grpIdx].dataset.length < element.DataSetID) {
            
            let seriesName:string;

            if (element.Activity != lastSeriesName) {
              seriesName = element.Activity;
              lastSeriesName = seriesName;
              colorIdx = colorIdx -1 < colorArray.length ? colorIdx + 1 : 0;
            } else {
              seriesName = ""; // Only want the planned downtime to show up in the legend once, so make it blank for all others
            }

            this.dataset[grpIdx].dataset.push(
              {
                "seriesname": seriesName,
                "color": colorArray[colorIdx],
                "data": []
              }
            );
           
          }

          let dsIdx:number = element.DataSetID - 1;

          let type:string = element.ActivityType == "PLANNED_DT" ? "Planned Downtime" : "Over Planned Time";
          
          if (colorAlpha == "90" && element.ActivityType == "PLANNED_DT")
            colorAlpha = "75";
          else {
            colorAlpha = "90";
          }

          let timeFormatted:string = this.timeFormat.convertToString(element.Duration);
          let displayValue:string = `${type}: ${element.Activity} <br> Duration: ${timeFormatted}`;

          this.dataset[grpIdx].dataset[dsIdx].data.push({ "value": element.Duration,  "displayValue": displayValue, "alpha": colorAlpha }
        );

          if (element.ActivityType == "PLANNED_OT") {
            // Change the color for overtime to red
            this.dataset[grpIdx].dataset[dsIdx].data.at(-1)['color'] = '#a93226';
          }
        });

        // This will add a dataset that isn't used but need this to add an entry to the legend
        if (this.dataset.length > 0) {
          // Add "hidden" dataset for planned_ot
          this.dataset.push(
            {
              "dataset": []
            }
          )
          this.dataset[this.dataset.length-1].dataset.push(
            {
              "seriesname": "Over Planned Time",
              "color": "#922b21",
              "data": []
            });
        }

        this.setChartData();
      }
    )
  }


  setChartData() {
    this.dataSource = null;

    this.dataSource ={
      "chart": {
          "caption": "Planned Downtime",
          "subCaption": "",
          "xAxisname": "Shift Date",
          "yAxisName": "Duration (seconds)",
          "showValues": "0",
          "theme": "fusion",
          "showPlotBorder": "1",
          "plotBorderThickness": "1",
          "plotBorderColor": "#ffffff",
          "plotToolText": "$displayValue" // Overrode the display value to communicate if this is planned or overtime
          
      },
      "categories": [
          {
              "category": this.category
          }
      ],
      "dataset": this.dataset
    }
  }

  onDateSelection(e) {    
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
    this.getChartData();
  }

  /**
 * Opens the Planned Donwtime dialog, no reload happens on close
 */
  openTableView() {
    const dialogRef = this.dialog.open(PlannedDowntimeTableDialog, {
      width: '800px',
      maxHeight: '900px',
      data: {
        objectid: this.objectid,
        site: this.site,
        environment: this.environment,
        data:this.downtimedata
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug("openTableView dialog closed", result);
    });
  }

}
