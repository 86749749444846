<div *ngIf="changeType == 'delete'">
    <div mat-dialog-content>
        <h3>This will delete the selected problem</h3>
        <mat-divider></mat-divider>
        <div>
            <p>Are you sure you want to delete the problem?</p>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div mat-dialog-actions >
        <button mat-raised-button color="warn" (click)="deleteProblem()">Delete Problem</button>
        <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
    </div>
</div>

<div *ngIf="changeType == 'clear'">
    <div mat-dialog-content>
        <h3>This will clear the selected problem</h3>
        <mat-divider></mat-divider>
        <div>
            <p>Are you sure you want to clear the problem? This will reset the Problem to SP and the Note to AUTO</p>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div mat-dialog-actions >
        <button mat-raised-button color="warn" (click)="clearProblem()">Clear Problem</button>
        <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
    </div>
</div>