<form [formGroup]="problemFormGroup">
    <div class="entry-form" >
        <div class="root-cause">
            <div *ngIf="showTeam == true" class="fx-row">
                <mat-form-field>
                    <mat-label> Team </mat-label>
                    <input type="text"
                        placeholder="Team"
                        matInput
                        formControlName="form_team"
                        [matAutocomplete]="team"
                        (focusin)="focus('team')">
                    <mat-autocomplete autoActiveFirstOption #team="matAutocomplete">
                        <mat-option *ngFor="let team of obsTeamList | async" [value]="team.Team" >
                            {{team.Team}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="problemFormGroup.get('form_category').invalid && problemFormGroup.get('form_category').errors && 
                            (problemFormGroup.get('form_category').dirty || problemFormGroup.get('form_category').touched)">
                </div>
            </div>
            <div class="fx-row">
                <mat-form-field>
                    <mat-label> Level 1 </mat-label>
                    <input type="text"
                        placeholder="Level 1"
                        matInput
                        formControlName="form_category"
                        [matAutocomplete]="autoL1"
                        (focusin)="focus('level1')">
                    <mat-autocomplete autoActiveFirstOption #autoL1="matAutocomplete">
                        <mat-option *ngFor="let problem of obsFilteredproblemL1 | async" [value]="problem" (click)="onSelectLevelOne(problem)">
                            {{problem}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="problemFormGroup.get('form_category').invalid && problemFormGroup.get('form_category').errors && 
                            (problemFormGroup.get('form_category').dirty || problemFormGroup.get('form_category').touched)">
                </div>
            </div>
            <div class="fx-row" *ngIf="problemData.Source != 'PLANNED_DT'">
                <mat-form-field>
                    <mat-label> Level 2 </mat-label>
                    <input type="text"
                        placeholder="Level 2"
                        matInput
                        formControlName="form_categoryl2"
                        [matAutocomplete]="autoL2"
                        (focusin)="focus('level2')">
                    <mat-autocomplete autoActiveFirstOption #autoL2="matAutocomplete">
                        <mat-option *ngFor="let problem of obsFilteredproblemL2 | async" [value]="problem" (click)="onSelectLevelTwo(problem)">
                            {{problem}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="problemFormGroup.get('form_categoryl2').invalid && 
                        problemFormGroup.get('form_categoryl2').errors && 
                            (problemFormGroup.get('form_categoryl2').dirty || problemFormGroup.get('form_categoryl2').touched)">
                </div>
            </div>
            <div class="fx-row" *ngIf="problemData.Source != 'PLANNED_DT'">
                <mat-form-field>
                    <mat-label> Level 3 </mat-label>
                    <input type="text"
                        placeholder="Level 3"
                        matInput
                        formControlName="form_categoryl3"
                        [matAutocomplete]="autoL3"
                        (focusin)="focus('level3')">
                    <mat-autocomplete autoActiveFirstOption #autoL3="matAutocomplete">
                        <mat-option *ngFor="let problem of obsFilteredproblemL3 | async" [value]="problem" (click)="onSelectLevelThree()">
                            {{problem}}
                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>
                <div *ngIf="problemFormGroup.get('form_categoryl3').invalid && 
                        problemFormGroup.get('form_categoryl3').errors && 
                            (problemFormGroup.get('form_categoryl3').dirty || problemFormGroup.get('form_categoryl3').touched)">
                </div>
            </div>
        </div>
        <div class="note-and-buttons">
            <mat-form-field>
                <textarea matInput [placeholder]="problemData.Source == 'PLANNED_DT' ? 'Notes' : 'Corrective Action'" formControlName="form_notes"></textarea>
            </mat-form-field>
            <div *ngIf="problemFormGroup.get('form_categoryl3').invalid && 
                    problemFormGroup.get('form_categoryl3').errors && 
                        (problemFormGroup.get('form_categoryl3').dirty || problemFormGroup.get('form_categoryl3').touched)">
            </div>
            <div>
                <button mat-button color="primary" (click)="setClipboard()" [disabled]="problemFormGroup.invalid">
                    <mat-icon>content_copy</mat-icon>Copy
                </button>
                <button mat-button color="primary" (click)="pasteClipboard()" [disabled]="!clipboardSet">
                    <mat-icon>content_paste</mat-icon>Paste
                </button>
                <button mat-button color="primary" (click)="undoChanges()" [disabled]="!problemFormGroup.dirty">
                    <mat-icon>undo</mat-icon>Undo
                </button>
                <button mat-button color="primary" (click)="saveFaultEntry()" [disabled]="!problemFormGroup.dirty || problemFormGroup.invalid">
                    <mat-icon>save</mat-icon>Save
                </button>
                <button mat-button color="primary" (click)="ungroupFaults()" *ngIf="occurrences>1">
                    <mat-icon>expand</mat-icon>Ungroup
                </button>
            </div>
        </div>
    </div>
</form>