import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Action, PaMatrixService, Problem } from '../../services/pa-matrix.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-add-edit-problem',
  templateUrl: './add-edit-problem.component.html',
  styleUrls: ['./add-edit-problem.component.scss']
})
export class AddEditProblemComponent implements OnInit {

  problem:Problem;
  problemFormGroup:UntypedFormGroup;
  type:string;
  userid:string;

  constructor(
    public dialogRef: MatDialogRef<AddEditProblemComponent>,
    private svcPAMatrix:PaMatrixService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.problem = this.inData.problemData;
    this.type = this.inData.type

    if (this.problem.DueDate == "") {
      this.problem.DueDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
    }

    console.log(this.problem)

    this.problemFormGroup = this.fb.group({
      Description: new UntypedFormControl({value: this.problem.Description, disabled: false}, Validators.required),
      AssignedTo: new UntypedFormControl({value: this.problem.AssignedTo, disabled: false}),
      CreatedBy: new UntypedFormControl({value: this.problem.CreatedBy, disabled: false}),
      DueDate: new UntypedFormControl({value: this.problem.DueDate, disabled: false}, Validators.required)
    });


  }

  ngOnInit(): void {
    console.log("problem", this.problem.Description);
  }

  saveData():void {
    console.log("Add or Update Data!");

    this.problem.Description = this.problemFormGroup.get('Description').value;
    this.problem.AssignedTo = this.problemFormGroup.get('AssignedTo').value;
    this.problem.CreatedBy = this.problemFormGroup.get('CreatedBy').value;
    this.problem.DueDate = this.problemFormGroup.get('DueDate').value;
    this.problem.CreatedDate = this.problem.CreatedDate;

    if (!this.problem.CreatedDate || this.problem.CreatedDate == "") {
      this.problem.CreatedDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    }

    this.svcPAMatrix.problemAddEdit(this.problem).subscribe(
      out => {
        console.log(out);

        if (this.problem.ItemNumber) {
          let action:Action = {
            ItemNumber: this.problem.ItemNumber,
            ActionDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            Action: "Problem was edited",
            EnteredBy: "",
            LoggedBy: this.userid
          }

          this.svcPAMatrix.actionAdd(action).subscribe(
            out => {
              console.log(out);
  
              this.dialogRef.close();
            }
          );
        } else {
          this.dialogRef.close();
        }

        
      }
    );
  }

  onNoClick():void {
    console.log("CLose");
    this.dialogRef.close();
  }

}
