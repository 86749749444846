import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PaMatrixService, Problem } from '../../services/pa-matrix.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditProblemComponent } from '../add-edit-problem/add-edit-problem.component';
import { CloseProblemComponent } from '../close-problem/close-problem.component';
import { ReopenProblemComponent } from '../reopen-problem/reopen-problem.component';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { AddActionComponent } from '../add-action/add-action.component';
import {MatSort, Sort} from '@angular/material/sort';

import * as dayjs from 'dayjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pa-matrix',
  templateUrl: './pa-matrix.component.html',
  styleUrls: ['./pa-matrix.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PaMatrixComponent implements OnInit {

  objectid:string;
  site:string;
  userid:string;

  days:number=0;

  paMatrixSub$: Subscription;
  //paMatrixData:any = [];
  //paMatrixData:MatTableDataSource; 
  paMatrixData:any;
  
  expandedActions:{} = {}; // Store list of problems that are expanded to show actions
  selectedRow:Problem;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Action','Description','AssignedTo','DueDate','Status','CreatedBy','CreatedDate','LoggedBy','ClosedDate']
  displayedDetailActionColumns: string[] = ['DetailAction','DetailDate','DetailEnteredBy','DetailLoggedBy']

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private svcPAMatrix:PaMatrixService,
    private svcAuth: GeaMesCognitoAuthService,
  ) { 
  }

  ngOnInit(): void {
    this.userid = this.svcAuth.getUserAttribute('sso');

    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');

    this.getData();
  }

  getData() {
    if (this.paMatrixSub$) this.paMatrixSub$.unsubscribe();

    this.days ? null : this.days = 0; // If invalid days entered, set to 0

    this.paMatrixSub$ = this.svcPAMatrix.view(this.site, this.objectid, this.days).subscribe(
      out => {
        //this.paMatrixData = out.Body;
        this.paMatrixData = new MatTableDataSource(out.Body);
        this.paMatrixData.sort = this.sort;
        console.log(this.paMatrixData);
      }
    );

    //this.addProblem();
  }

  addProblem() {
    console.log("AddProblem");
    let newProblem:Problem = {
      ObjectID: this.objectid,
      Site: this.site,
      ItemNumber: null,
      Description: "",
      CreatedDate: "",
      DueDate: "",
      ClosedDate: null,
      CreatedBy: "",
      AssignedTo: "",
      LoggedBy: this.userid,
      Status: 1
    }

    let data:any = {
      type: "Add",
      problemData: newProblem
    }
  
    this.openAddEditProblem(data);

  }

  editProblem() {
    console.log("EditProblem", this.selectedRow);
    let data:any = {
        type: "Edit",
        problemData: this.selectedRow
      }
    
    this.openAddEditProblem(data);
  }

  openAddEditProblem(dialogInput:any) {
    const dialogRef = this.dialog.open(AddEditProblemComponent, {
      width: '500px',
      maxHeight: '800px',
      data: dialogInput
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Add/Edit Closed");
      this.getData();
    });
  }

  closeProblem() {
    console.log("CloseProblem", this.selectedRow);

    const dialogRef = this.dialog.open(CloseProblemComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        type: "Close",
        problemData: this.selectedRow
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close Problem Closed");
      this.getData();
    });
  }

  reopenProblem() {
    console.log("ReopenProblem", this.selectedRow);

    const dialogRef = this.dialog.open(ReopenProblemComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        type: "Reopen",
        problemData: this.selectedRow
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close Reopen");
      this.getData();
    });
  }


  addAction(problem:Problem) {
    console.log("Add Action");

    const dialogRef = this.dialog.open(AddActionComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {
        type: "Reopen",
        problemData: problem
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close addAction");
      this.getData();
    });
  }

  toggleDisplayActions(element:any, e:Event) {
    if (this.expandedActions[element.ItemNumber]) {
      this.expandedActions[element.ItemNumber] = false;
    } else {
      this.expandedActions[element.ItemNumber] = true;
    }

    if (this.selectedRow != element) {
      this.toggleRowSelect(element);
    }

    e.stopPropagation();
  }

  toggleRowSelect(element:any) {
    console.log("Select Row!!!",element);
    if (this.selectedRow == element) {
      this.selectedRow = null;
    } else {
      this.selectedRow = element;
    }
  }

}
