import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as dayjs from 'dayjs';
import { AnalysisService } from '../../analysis-service.service';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-rawdata',
  templateUrl: './rawdata.component.html',
  styleUrls: ['./rawdata.component.scss']
})
export class RawdataComponent implements OnInit {
  @Input() DatePickerIndex:number = 0;

  startDate:string;
  endDate:string;

  objectid:string;
  site:string;

  rawDataSub$: Subscription;
  rawData:any;
  loadingData:boolean=true;

  shifts:any[] = ['1ST SHIFT','2ND SHIFT','3RD SHIFT']
  shiftSelect = new FormControl();

  displayedColumns: string[]; // List of columns will mirror the output of the lambda dynamically

  constructor(
    private route: ActivatedRoute,
    private svcAnalysis: AnalysisService,
  ) { 
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
    this.shiftSelect.setValue(this.shifts);
  }

  ngOnInit(): void {
    this.getRawData();
  }

  ngOnChanges(changes:any) {
    console.log("Changes in Rawdata", changes);
    this.getRawData();
  }

  getRawData() {
    if (this.rawDataSub$) this.rawDataSub$.unsubscribe();

    this.rawDataSub$ = this.svcAnalysis.rawData(this.site, this.objectid, this.startDate, this.endDate, this.shiftSelect.value.toString()).subscribe(
      out => {
        // Check if we got a return, possible to get a null due to caching in the service
        if (out) {
          // Dynamically set the list of columns
          if (out.Body.length > 0) {
            this.displayedColumns = Object.keys(out.Body[0])
          } else {
            this.displayedColumns = [];
          }
          this.loadingData=false;
          
          this.rawData = out.Body;
          
        }
      }
    );
  }

  onDateSelection(e) {
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
    this.loadingData = true;
    this.getRawData();
  }

  /**
   * Converts rawData to a CSV (using displayedColumns as header) and downloads to the users computer
   */
  downloadAsCSV() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = this.displayedColumns; //Object.keys(this.rawData[0]);
    let csv = this.rawData.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    let fileName:string = this.objectid + ".csv";
    saveAs(blob, fileName);
}
}
