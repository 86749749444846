<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
<form [formGroup]="addFormGroup">
    <mat-table [dataSource]="data">
        <ng-container matColumnDef="AndonGroup">
            <mat-header-cell *matHeaderCellDef>Andon Group</mat-header-cell>
            <mat-cell *matCellDef="let d">
                <div *ngIf="(d.Andon_Group != '')">{{d.Andon_Group}}</div>

                <mat-form-field  *ngIf="(d.Andon_Group == '')" floatLabel="never">
                    <textarea  matInput cdkTextareaAutosize placeholder="New Andon Group" formControlName="NewAndonGroup"></textarea>
                </mat-form-field>
            </mat-cell>
        </ng-container>    

        
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index">
            <button [disabled]="!adminFlag"  *ngIf="(d.Andon_Group != '')" mat-button color="warn" (click)="delete(d.Andon_Group)"><mat-icon>delete_forever</mat-icon>Delete Group</button>
            <button [disabled]="!adminFlag"  *ngIf="(d.Andon_Group == '')" mat-button color="primary" (click)="add()"><mat-icon>add_circle</mat-icon>Add Group</button>
            </mat-cell>
        </ng-container> 
        
        

        <mat-header-row *matHeaderRowDef="displayedObjectColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedObjectColumns;"></mat-row>      
    </mat-table>
</form>
</div>   
<div mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</div>