import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';

export class TooltipValues {
  perfect:string = "Total minutes in shift / Cycle time";
  target:string = "Calculated perfect units * Demand %";
  actual:string = "Total units produced in this shift";
  efficiency:string = "Actual / Perfect";
  oa:string = "OA from Proficy";
  varianceAY:string = "Actual - Perfect";
  varianceOA:string = "Actual - Customer Demand";
  lost:string = "Total down time / Cycle time";
  notes:string = "What happened?";
  countermeasure:string = "What are we doing to stop from happening again?"
  problem:string = "Time that the most recent problem occurred.";
  duration:string = "Total duration of the most recent problem.";
  hourProblem:string = "Top problems for this hour by duration."
  hourDuration:string = "Duration of problems listed to the left."
}

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit, OnDestroy {
  objectid:string;
  site:string;
  environment:string;
  
  object:ObjectNode;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcObjectList:ObjectListService
  ) {}

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        console.log("ObjectNode", objectNode);
        this.object = objectNode;

        this.environment = objectNode.Environment;
      }
    );
  }

  navigateToProblems(hour:number):void { 
    console.log("Navigate to Problems", hour);
    let hourParam:string = (hour == -1 ? 'ALL' : hour.toString());
    this.router.navigate(["/problemlist",this.site, this.objectid, hourParam]);
  }

  ngOnDestroy(): void{ 
  }

}
