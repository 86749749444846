import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// Services
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-andon-groups-dialog',
  templateUrl: './andon-groups-dialog.component.html',
  styleUrls: ['./andon-groups-dialog.component.scss']
})
export class AndonGroupsDialogComponent implements OnInit {

  adminFlag: boolean;
  adminSub$: Subscription;

  input: AndonGroupsType;
  data: any[];

  title:string;

  addFormGroup: UntypedFormGroup;

  displayedObjectColumns: string[] = ['AndonGroup','actions']

  constructor(public dialogRef: MatDialogRef<AndonGroupsDialogComponent>,
    private svcAdmin: AdminService,
    private fb: UntypedFormBuilder,
    private geaMesCognito: GeaMesCognitoAuthService,
    @Inject(MAT_DIALOG_DATA) public inData: AndonGroupsType) { 
      this.input = inData['objData'];
    }

  ngOnInit(): void {
    this.title = `${this.input.ObjectID}: ${this.input.Description}`
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });    

    this.getAndonList();

    this.addFormGroup = this.fb.group({
      NewAndonGroup: ''
    });
  }

  getAndonList() {
    this.svcAdmin.andonGroupList(this.input.Site, this.input.Environment, this.input.ObjectID).subscribe((
      out => {
          console.debug(out);
          this.data = out.Body;
          this.data.push({"Andon_Group": ""})
    }));
  }

  delete(AndonGroup:string) {
    if (confirm(`Are you sure to delete ${AndonGroup} from ${this.input.ObjectID}?`)) {
      this.svcAdmin.andonGroupDelete(this.input.Site, this.input.Environment, this.input.ObjectID, AndonGroup).subscribe((
        out => {
            console.debug(out);
            this.getAndonList();
      }));    
    }
  }

  add() {
    let NewAndonGroup:string = this.addFormGroup.controls.NewAndonGroup.value;
    if (NewAndonGroup.trim() != "") {
      this.svcAdmin.andonGroupAdd(this.input.Site, this.input.Environment, this.input.ObjectID, NewAndonGroup).subscribe((
        out => {
            console.debug(out);
            this.addFormGroup = this.fb.group({
              NewAndonGroup: ''
            });          
            this.getAndonList();
      }));
    }
  }

  close() {
    this.dialogRef.close();
  }

}

export interface AndonGroupsType {
  Site: string;
  Environment: string;
  ObjectID: string;
  Description: string;
}