<div style="display: flex">
    <h3 mat-dialog-title>OA Challenge Configuration</h3>
    <span class="spacer"></span>
    <button mat-icon-button color="primary" (click)="helpEnabled = !helpEnabled"><mat-icon>help</mat-icon></button>
</div>
<div mat-dialog-content>
    
<form [formGroup]="fgConfig" autocomplete="off">
<ng-container formArrayName="rows">
    <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="StartDate">
            <mat-header-cell *matHeaderCellDef>StartDate (MM/DD/YYYY)</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing ? 'none' : 'legacy'" [style.width.px]="75">
                    <input matInput type="text" 
                           formControlName="StartDate"
                           maxlength="10"
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"   
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"
                           (blur)="formatDate(configRows.controls[i].get('StartDate'))"/>
                    <mat-error *ngIf="configRows.controls[i].get('StartDate').errors?.validateDate">Format must be MM/DD/YYYY</mat-error>
                    <mat-error *ngIf="configRows.controls[i].get('StartDate').errors?.required">StartDate required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="EndDate">
            <mat-header-cell *matHeaderCellDef>EndDate (MM/DD/YYYY)</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing? 'none' : 'legacy'" [style.width.px]="75">
                    <input matInput type="text" 
                           formControlName="EndDate"
                           maxlength="10"
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"  
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"
                           (blur)="formatDate(configRows.controls[i].get('EndDate'))" />
                    <mat-error *ngIf="configRows.controls[i].get('EndDate').errors?.validateDate">Format must be MM/DD/YYYY</mat-error>
                    <mat-error *ngIf="configRows.controls[i].get('EndDate').errors?.required">EndDate required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Challenge">
            <mat-header-cell *matHeaderCellDef>Challenge</mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                <mat-form-field [appearance]="!fgConfig.get('rows').value[i].isEditing? 'none' : 'legacy'" [style.width.px]="250">
                    <input matInput type="text"
                           formControlName="Challenge"  
                           maxlength="80"     
                           [class]="fgConfig.get('rows').value[i].isDeleted ? 'deleted' : 'none'"  
                           [readonly]="!fgConfig.get('rows').value[i].isEditing"/>
                    <mat-error *ngIf="configRows.controls[i].get('Challenge').errors?.required">Challenge required</mat-error>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let d; let i = index">
                <button *ngIf="!fgConfig.get('rows').value[i].isDeleted && !fgConfig.get('rows').value[i].isEditing" 
                         mat-button 
                         color="primary" 
                         (click)="editLine(fgConfig,i)">
                    <mat-icon>create</mat-icon>Edit
                </button>
                
                <span *ngIf="fgConfig.get('rows').value[i].id == -1" style="min-width: 75.05px; max-width: 75.05px;"></span> <!-- Space element for when adding new to line up Delete button with other delete buttons -->

                <button *ngIf="!fgConfig.get('rows').value[i].isDeleted && !fgConfig.get('rows').value[i].isEditing || fgConfig.get('rows').value[i].id == -1" 
                         mat-button color="warn" 
                         (click)="delete(fgConfig,i)">
                    <mat-icon>delete_forever</mat-icon>Delete
                </button>

                <button *ngIf="(fgConfig.get('rows').value[i].isEditing || fgConfig.get('rows').value[i].isDeleted) && fgConfig.get('rows').value[i].id != -1" 
                        mat-button 
                        color="primary" 
                        (click)="undo(fgConfig, i)">
                    <mat-icon>undo</mat-icon>
                    <span *ngIf="!fgConfig.get('rows').value[i].isDeleted">Undo - Edit</span>
                    <span *ngIf="fgConfig.get('rows').value[i].isDeleted">Undo - Delete</span>
                </button>
            </mat-cell>
        </ng-container> 

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>      
    </mat-table>
    <div>
        <button mat-button color="primary" (click)="add(fgConfig)"><mat-icon>add_circle</mat-icon>Add New</button>
    </div>
</ng-container>
</form>
</div>   
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!fgConfig.dirty || !fgConfig.valid" color="primary" (click)="save()">Save and Close</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="close()">Cancel</button>
</div>

<!-- Help Panel-->
<div *ngIf="helpEnabled" class="help" (click)="helpEnabled = !helpEnabled">
    <div class="help-content" (click)="$event.stopPropagation()">
        <h1>Help - OA Challenge Configuration</h1>
        <div>
            <p>
                OA Challenge is the percentage of OA Target to acheive.
            </p>

            <h2>Configuration</h2>
            
            <h3>Challenge, StartDate and EndDate</h3> 
            <p>
                Input the OA Challenge value and the effective time range between StartDate and EndDate.
            </p>
    
        </div>
        <button mat-raised-button color="primary" (click)="helpEnabled = !helpEnabled">Close</button>
    </div>
</div>