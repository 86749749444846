<mat-card class="metrics-card mat-elevation-z1">
    <mat-card-title class="metric-title">
            {{title}}
    </mat-card-title>
    <mat-card-content>
        <form [formGroup]="fgDemand" autocomplete="off">
            <ng-container formArrayName="rows">

                <ng-container>
                    <mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="ShiftDate">
                            <mat-header-cell *matHeaderCellDef>Shift Date</mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                                <mat-form-field [appearance]="(currentShift && d.value['isNew']) || !d.value['isEditing'] || !d.value['isNew'] ? 'none' : 'legacy'" [style.width.px]="90">
                                    <input matInput type="text"
                                        formControlName="ShiftDate"  
                                        maxlength="10"     
                                        [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                        [readonly]="currentShift || !d.value['isEditing']"/>
                                <mat-error *ngIf="demandRows.controls[i].get('ShiftDate').errors?.validateDate">Must be valid date YYYY-MM-DD or MM/DD/YYYY</mat-error>
                                <mat-error *ngIf="demandRows.controls[i].get('ShiftDate').errors?.required">Shift Date required</mat-error>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Shift">
                            <mat-header-cell *matHeaderCellDef>Shift</mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                                <mat-form-field [appearance]="(currentShift && d.value['isNew']) || !d.value['isEditing'] || !d.value['isNew'] ? 'none' : 'legacy'" [style.width.px]="90">
                                    <span *ngIf="!currentShift && d.value['isNew']">
                                        <mat-select formControlName="Shift">
                                            <mat-option *ngFor="let shift of availShifts" [value]="shift">{{shift}}</mat-option>
                                        </mat-select>
                                    </span>
                                    <span *ngIf="currentShift || !d.value['isNew']">
                                        <input matInput type="text"
                                            formControlName="Shift"  
                                            maxlength="80"     
                                            [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                            [readonly]="true"/>
                                    </span>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="JobID">
                            <mat-header-cell *matHeaderCellDef>Job ID</mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                                <mat-form-field appearance="none" [style.width.px]="250">
                                    <input matInput type="text"
                                            formControlName="JobID"  
                                            maxlength="80"     
                                            [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                            [readonly]="true"/>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="JobDescription">
                            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                                <mat-form-field appearance="none" >
                                    <span *ngIf="d.value['isNew']">
                                        <mat-select formControlName="JobIdx" (selectionChange)="selectJobIdx(fgDemand, i, $event)">
                                            <mat-option *ngFor="let config of jobConfig; let i = index" [value]="i">{{config.JobID}} - {{config.JobDescription}}</mat-option>
                                        </mat-select>
                                    </span>
                                    <span *ngIf="!d.value['isNew']">
                                        <input matInput type="text"
                                            formControlName="JobDescription"  
                                            maxlength="80"     
                                            [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                            [readonly]="true"/>
                                    </span>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="Qty">
                            <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index" [formGroup]="d">
                                <mat-form-field [appearance]="!d.value['isEditing'] ? 'none' : 'legacy'" [style.width.px]="50">
                                    <input matInput type="text"
                                            formControlName="Qty"  
                                            maxlength="10"     
                                            [class]="d.value['isDeleted'] ? 'deleted' : 'none'"  
                                            [readonly]="!d.value['isEditing']"/>
                                <mat-error *ngIf="demandRows.controls[i].get('Qty').errors?.validateQty">Invalid</mat-error>
                                <mat-error *ngIf="demandRows.controls[i].get('Qty').errors?.required">Required</mat-error>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="Actions">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let d; let i = index">
                                <button *ngIf="allowEdits && !d.value['isDeleted'] && !d.value['isEditing']" 
                                        mat-button 
                                        color="primary" 
                                        (click)="editLine(fgDemand,i)">
                                        <mat-icon>create</mat-icon>Edit
                                </button>
            
                                <span *ngIf="d.value['ConfigID'] == -1" style="min-width: 64px; max-width: 64px;"></span> 
            
                                <button *ngIf="allowEdits && (!d.value['isDeleted'] && !d.value['isEditing']) || d.value['isNew']" 
                                            mat-button color="warn" 
                                            (click)="delete(fgDemand,i)">
                                    <mat-icon>delete_forever</mat-icon>Delete
                                </button>
                
                                <button *ngIf="(d.value['isEditing'] || d.value['isDeleted']) && !d.value['isNew']" 
                                        mat-button 
                                        color="primary" 
                                        (click)="undo(fgDemand, i)">
                                    <mat-icon>undo</mat-icon>
                                    <span *ngIf="!d.value['isDeleted']">Undo - Edit</span>
                                    <span *ngIf="d.value['isDeleted']">Undo - Delete</span>
                                </button>
                            </mat-cell>
                        </ng-container>
                
                
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>      
                    </mat-table>
                    <div>
                        <button *ngIf="allowEdits" mat-button color="primary" (click)="add(fgDemand)"><mat-icon>add_circle</mat-icon>Add New</button>
                    </div>
                </ng-container>

            </ng-container>
        </form>

<!-- <button *ngIf="allowEdits" mat-raised-button [disabled]="!fgDemand.dirty || !fgDemand.valid" color="primary" (click)="save()">Save</button>
<span class="spacer"></span>
<button *ngIf="allowEdits" mat-raised-button [disabled]="!fgDemand.dirty || !fgDemand.valid" (click)="cancel()">Cancel</button> -->
</mat-card-content>
</mat-card>