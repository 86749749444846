import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, ValidatorFn, ValidationErrors, FormGroup, AbstractControl, FormControlStatus, FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MetricsService, team } from 'src/app/services/metrics.service';
import { FaultData } from '../components/problem-list/problem-list.component';
import { ChangeStatus, ProblemService } from '../services/problem.service';
import { MatDialog } from '@angular/material/dialog';
import { UngroupConfirmComponent } from './ungroup-confirm/ungroup-confirm.component';

@Component({
  selector: 'app-problem-entry',
  templateUrl: './problem-entry.component.html',
  styleUrls: ['./problem-entry.component.scss']
})
export class ProblemEntryComponent implements OnInit, OnDestroy {
  @Input() problemData:FaultData;
  @Input() index:number;
  @Input() showTeam:boolean;

  @Output() savedFaultEntry = new EventEmitter<boolean>();

  problemFormGroup: UntypedFormGroup;
  objectId: string;
  problemId:number;
  hour: number;
  start: string;
  startDate: string = '';
  end: string;
  manual: boolean;
  site:string;
  minutes: number;
  seconds: number;
  occurrences: number;

  duration:number;
  hours: any;
  problemHour: number;
  shiftStart: string;
  shiftEnd: string;
  currentValidationTime:string;

  clipboard$: Subscription;
  clipboardValue:any;

  clipboardSet:boolean=false;

  problemSelectSub$: Subscription;
  problemSelectData:[];

  statusChanges$: Subscription;
  saveTrigger$: Subscription;

  // The original problem options from the database
  problemLevelOne:    string[] = []
  problemLevelTwo:    string[] = []
  problemLevelThree:  string[] = []

  // The Subject used to hold the filter problems, 
  subFilteredProblemL1: BehaviorSubject<any> = new BehaviorSubject([]);
  subFilteredProblemL2: BehaviorSubject<any> = new BehaviorSubject([]);
  subFilteredProblemL3: BehaviorSubject<any> = new BehaviorSubject([]);
  subTeamList: BehaviorSubject<team[]> = new BehaviorSubject([]);

  // Observable used by mat-autocomplete
  obsFilteredproblemL1: Observable<string[]>;
  obsFilteredproblemL2: Observable<string[]>;
  obsFilteredproblemL3: Observable<string[]>;
  obsTeamList: Observable<team[]>;

  selectedLevelOne: string = ""
  selectedLevelTwo: string = ""
  selectedLevelThree: string = ""

  team:string = '';
  notes:string = '';

  changeStatus:ChangeStatus;


  constructor(
    private problemFormBuilder: UntypedFormBuilder,
    private svcMetrics:MetricsService,
    private svcProblem:ProblemService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
      this.site = this.problemData.site;
    this.objectId = this.problemData.objectid;
    this.problemHour = Number(this.problemData.Hour);
    this.hours = this.problemData.hours;
    this.selectedLevelOne = (this.problemData.Problem == 'SP') ? '' : this.problemData.Problem;
    this.selectedLevelTwo = this.problemData.Problem_Level2;
    this.selectedLevelThree = this.problemData.Problem_Level3;
    this.notes = (this.problemData.Notes == 'AUTO') ? '' : this.problemData.Notes; // If AUTO then blank out notes
    this.team = this.problemData.Team
    this.problemId = this.problemData.ID;
    this.occurrences = this.problemData.Occurrences;

    // Define all field validation
    let team:any[] = this.showTeam ? [Validators.required] : [];
    let optionsCategoryL1:any[] = [Validators.required]; // Always required level 1
    let optionsCategoryL2:any[] = (this.problemData.config.problemLevelRequired > 1) ? [Validators.required] : [];
    let optionsCategoryL3:any[] = (this.problemData.config.problemLevelRequired > 2) ? [Validators.required] : [];
    let optionsNotes:any[] = this.problemData.config.problemNoteRequired ? [Validators.required] : [];

    // If this is  planend downtime event disable changing the levels as they don't apply
    let disablePlannedDowntime:boolean = this.problemData.Source == 'PLANNED_DT' ? true : false;

    optionsCategoryL1.push(this.validateProblem(1));
    optionsCategoryL2.push(this.validateProblem(2));
    optionsCategoryL3.push(this.validateProblem(3));

    this.problemFormGroup = this.problemFormBuilder.group({
      form_team: [{value: this.team, disabled: false}, team],
      form_category: [{value: this.selectedLevelOne, disabled: disablePlannedDowntime}, optionsCategoryL1],
      form_categoryl2: [{value: this.selectedLevelTwo, disabled: disablePlannedDowntime}, optionsCategoryL2],
      form_categoryl3: [{value: this.selectedLevelThree, disabled: disablePlannedDowntime}, optionsCategoryL3],
      form_notes: [{value: this.notes, disabled: false}, optionsNotes]
    });

    this.obsFilteredproblemL1 = this.subFilteredProblemL1.asObservable();
    this.obsFilteredproblemL2 = this.subFilteredProblemL2.asObservable();
    this.obsFilteredproblemL3 = this.subFilteredProblemL3.asObservable();
    this.obsTeamList = this.subTeamList.asObservable();

    this.setDetectChanges();

    this.getClipboard();
    this.getData();
  }

  getData() {
    if (this.problemSelectSub$) this.problemSelectSub$.unsubscribe();

    this.problemSelectSub$ = this.svcMetrics.problemList(this.site, this.objectId).pipe(take(1)).subscribe(
      out => {
        this.problemSelectData = out.Body;
        this.problemLevelOne = Object.keys(this.problemSelectData)
        this.subFilteredProblemL1.next(this.problemLevelOne);

        this.setLevel2Values();
        this.setLevel3Values();

        this.checkValidation();

        this.problemFormGroup.controls['form_category'].valueChanges.subscribe(value => { this.filter(this.problemLevelOne, value, this.subFilteredProblemL1); });
        this.problemFormGroup.controls['form_categoryl2'].valueChanges.subscribe(value => { this.filter(this.problemLevelTwo, value, this.subFilteredProblemL2); });
        this.problemFormGroup.controls['form_categoryl3'].valueChanges.subscribe(value => { this.filter(this.problemLevelThree, value, this.subFilteredProblemL3); });

      }
    );

    this.svcMetrics.teamList(this.site, this.objectId).pipe(take(1)).subscribe(
      out => {
        this.subTeamList.next(out.Body.Teams);
        //this.teamList = out.Body.Teams;
        console.log(out.Body.Teams); 

        //this.showTeam = this.teamList.length != 0 ? true : false;
      }
    )
  }

  saveFaultEntry(): void {
    if (this.problemFormGroup.dirty || this.problemFormGroup.valid) {
      // if the occurrence is 1, then send faultvalue as 0 in order to process this entry separately
      var faultvalue = (this.occurrences==1) ? 0 : this.problemData.FaultValue;

      this.svcMetrics.updateFault(
        this.problemFormGroup.get("form_team").value,
        this.problemFormGroup.get("form_category").value,
        this.problemFormGroup.get("form_categoryl2").value,
        this.problemFormGroup.get("form_categoryl3").value,
        this.problemFormGroup.get("form_notes").value,
        this.problemId,
        faultvalue,
        this.problemData.FaultMessage,
        this.problemData.Hour,
        this.problemData.shift,
        this.problemData.shiftDate,
        this.problemData.objectid,
        this.problemData.site,
        this.problemData.Problem,
        this.problemData.Problem_Level2,
        this.problemData.Problem_Level3,
        this.problemData.Notes,
        this.problemData.Team
      ).subscribe(
        out => {
          console.debug("Saved Results", out);
          this.problemFormGroup.markAsPristine(); // Now that we've saved the record, the formgroup can be marked clean again so the Save button disables
          this.changeStatus = ChangeStatus.NO_CHANGES;
          this.svcProblem.setChangeStatus(this.index, this.changeStatus); // Set service change status so parent knows save has been completed
        }
      )
    }
  }

  // EVents from FormGroup controls and updating the options displayed
  onSelectLevelOne(problem: string){
    this.selectedLevelOne = problem;

    let myLevel2:string = this.problemFormGroup.controls['form_categoryl2'].getRawValue();
    if (!(myLevel2 in this.problemLevelTwo)) {
      this.problemFormGroup.controls['form_categoryl2'].patchValue('');
    }

    let myLevel3:string = this.problemFormGroup.controls['form_categoryl3'].getRawValue();
    if (!(myLevel3 in this.problemLevelThree)) {
      this.problemFormGroup.controls['form_categoryl3'].patchValue('');
    }

    this.setLevel2Values();
  }

  setLevel2Values() {
    if (this.selectedLevelOne in this.problemSelectData) {
      this.problemLevelTwo = Object.keys(this.problemSelectData[this.selectedLevelOne])
    } else {
      this.problemLevelTwo = ['']
    }

    this.subFilteredProblemL1.next(this.problemLevelOne); // Reset the autocomplete dropdown to full list
    this.subFilteredProblemL2.next(this.problemLevelTwo); // Set the autocomplete dropdown
  }

  onSelectLevelTwo(problem: string){
    this.selectedLevelTwo = problem

    this.setLevel3Values();

  }

  setLevel3Values() {
    if (this.selectedLevelOne in this.problemSelectData && this.selectedLevelTwo in this.problemSelectData[this.selectedLevelOne]) {
      this.problemLevelThree = this.problemSelectData[this.selectedLevelOne][this.selectedLevelTwo]
    } else {
      this.problemLevelThree = ['']
    }
    this.subFilteredProblemL2.next(this.problemLevelTwo); // Reset the autocomplete dropdown to full list
    this.subFilteredProblemL3.next(this.problemLevelThree); // Set the autocomplete dropdown
  }

  onSelectLevelThree() {
    this.subFilteredProblemL3.next(this.problemLevelThree); // Reset the autocomplete dropdown to full list
  }

  /**
   * This is triggered by the input for levels and resets the dropdown to the default values so every time the user comes into the 
   * field they get a valid list.
   * 
   * @param level Level that has focus
   */
  focus(level:string) {
    if (level == 'level1') {
      this.subFilteredProblemL1.next(this.problemLevelOne);
    } else if (level == 'level2') {
      this.subFilteredProblemL1.next(this.problemLevelTwo);
    } else if (level == 'level3') {
      this.subFilteredProblemL1.next(this.problemLevelThree);
    }
  }

  /**
   * Checks if all the fields in the FormGroup pass validation and sets the status appropriately on the FormGroup. This is
   * done for situations where validation is dependent on data loaded after the default values are set so need to trigger revalidation.
   */
  checkValidation() {
    Object.keys(this.problemFormGroup.controls).forEach(field => {
      let control = this.problemFormGroup.get(field);
      control.updateValueAndValidity();
    });
  }

  /**
   * This filters the list of problems for a given level based on the value passed
   * 
   * @param source  The source list of values
   * @param value   The value typed in, filter values from source
   * @param sub     The Subject that will have next value set based on the filtered list of source 
   */
  filter(source: string[], value, sub: BehaviorSubject<string[]>) {
    if (value) {
      let output: string[] = source.filter(function (option) {
        return option.toLowerCase().includes(value.toLowerCase())
      });

      sub.next(output);
    }
  }

  /**
   * Validates the value entered into the Levels (1, 2, and 3) is a valid value in the list provided.
   * 
   * @param level Level selected
   * @returns A FormGroup validator
   */

  validateProblem(level: number): ValidatorFn {
    console.log("validateProblem", level);
    return (control: AbstractControl): ValidationErrors | null => {
      let value: string = control.value;

      if ((level == 1 && this.problemLevelOne.includes(value)) ||    // If level 1, make sure problem a valid level 1 problem
        (level == 2 && this.problemLevelTwo.includes(value)) ||    // If level 2, make sure problem is a valid level 2 problem
        (level == 3 && this.problemLevelThree.includes(value)) ||  // If level 3, make sure problem is a valid level 3 problem
        (value == "") ||                                           // If value is blank, pass this validator and let required handle if needed
        (!value)                                                   // If value is undefined, pass this validator and let required handle if needed
      ) {
        return null;
      } else {
        return { invalidProblem: true };
      }
    }
  }

  /**
   * Detect changes to the formgroup, if it detects a change subscribe to the global save trigger and when the save trigger is set 
   * to true save the form if it has changed values and is valid.
   * 
   */
  setDetectChanges() {
    if (this.statusChanges$) this.statusChanges$.unsubscribe();

    // Monitor the FormGroup for changes and set the status in the service so parent component will know if changes have been made
    this.statusChanges$ = this.problemFormGroup.statusChanges.subscribe( 
      (formStatus:string) => 
      {
        let myStatus:ChangeStatus;

        // Only care about the status if the form is dirty (meaning a change has occurred to the data)
        if (this.problemFormGroup.dirty) {
          if (formStatus == 'VALID') {
            myStatus = ChangeStatus.CHANGES_VALID;
          } else {
            myStatus = ChangeStatus.CHANGES_INVALID;
          }
        } else {
          myStatus = ChangeStatus.NO_CHANGES;          
        }

        if (myStatus != this.changeStatus) {
          this.changeStatus = myStatus;
          this.svcProblem.setChangeStatus(this.index, myStatus);
        }
      }
    )

    if (this.saveTrigger$) this.saveTrigger$.unsubscribe();

    // Monitor for a signal from parent to save
    this.saveTrigger$ = this.svcProblem.getSaveTrigger().subscribe(
      (save) => {
        if (save) {
          console.debug("Force save", this.problemFormGroup);
          this.saveFaultEntry();
        }
      }
    );
  }

  // Methods for Clip board Operations and Save/Undo operations
  getClipboard() {
    if (this.clipboard$) this.clipboard$.unsubscribe();

    this.clipboard$ = this.svcProblem.getClipboard(this.site, this.objectId).subscribe(
      (value) => {
        this.clipboardValue = value;
        if (value) {
          this.clipboardSet = true;
        }
      }
    );
  }

  setClipboard() {
    this.svcProblem.setClipboard(this.problemFormGroup.value);
  }

  pasteClipboard() {
    this.selectedLevelOne = this.clipboardValue.form_category;
    this.selectedLevelTwo = this.clipboardValue.form_categoryl2;
    this.selectedLevelThree = this.clipboardValue.form_categoryl3;
    this.notes = (this.clipboardValue.form_notes == 'AUTO') ? '' : this.clipboardValue.form_notes; // If AUTO then blank out notes
    this.problemFormGroup.patchValue({
      form_category: this.selectedLevelOne,
      form_categoryl2: this.selectedLevelTwo,
      form_categoryl3: this.selectedLevelThree,
      form_notes: this.notes
    });

    this.problemFormGroup.markAsDirty();

    this.setLevel2Values();
    this.setLevel3Values();
    this.checkValidation();
  }

  undoChanges() {
    this.selectedLevelOne = (this.problemData.Problem == 'SP') ? '' : this.problemData.Problem;
    this.selectedLevelTwo = this.problemData.Problem_Level2;
    this.selectedLevelThree = this.problemData.Problem_Level3;
    this.notes = (this.problemData.Notes == 'AUTO') ? '' : this.problemData.Notes; // If AUTO then blank out notes
    this.problemFormGroup.patchValue({
      form_category: this.selectedLevelOne,
      form_categoryl2: this.selectedLevelTwo,
      form_categoryl3: this.selectedLevelThree,
      form_notes: this.notes
    });

    this.problemFormGroup.markAsPristine();

    this.setLevel2Values();
    this.setLevel3Values();

    this.checkValidation();
  }

  ngOnDestroy() {
    if (this.problemSelectSub$) this.problemSelectSub$.unsubscribe();
    if (this.statusChanges$) this.statusChanges$.unsubscribe();
  }

  formatDuration(seconds: number) {
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  ungroupFaults() {
    const dialogRef = this.dialog.open(UngroupConfirmComponent, {width: '500px', data:{occurances:this.occurrences}});

    dialogRef.afterClosed().subscribe(confirm => {
      if(confirm) {
        this.svcProblem.ungroupFault(
          this.objectId, 
          this.site, 
          this.problemData.shiftDate, 
          this.problemData.shift, 
          this.problemHour, 
          this.problemData.FaultValue,
          this.problemData.FaultMessage,
          this.problemData.Problem,
          this.problemData.Problem_Level2,
          this.problemData.Problem_Level3,
          this.problemData.Notes).pipe(take(1)).subscribe(out => 
          {
              this.svcProblem.setReloadTrigger(true);
          }
        );
      }
    })
  }
}
