<form [formGroup]="problemFormGroup">
    <h3 mat-dialog-title>{{type}} Problem</h3>
    <div mat-dialog-content>
        
        <mat-form-field [style.width.px]="450">
            <input matInput cdkTextareaAutosize placeholder="Description" formControlName="Description">
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <input matInput cdkTextareaAutosize placeholder="Assigned To" formControlName="AssignedTo">
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field *ngIf="type == 'Add'">
            <input matInput cdkTextareaAutosize placeholder="Created By" formControlName="CreatedBy">
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <input matInput cdkTextareaAutosize placeholder="Due Date" formControlName="DueDate">
        </mat-form-field>

    </div>
    <div mat-dialog-actions>
        <button class="mat-raised-button" (click)="saveData()" [disabled]="problemFormGroup.invalid">Save</button>
        <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
    </div>
</form>