import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MetricsService, shiftData } from 'src/app/services/metrics.service';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { ShiftNoteDialogComponent } from '../shift-note-dialog/shift-note-dialog.component';
import * as dayjs from 'dayjs';
import { ShiftSelectDialogComponent } from '../shift-select-dialog/shift-select-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.scss']
})
export class ButtonBarComponent implements OnInit, OnDestroy {
  currentRoute:string;

  @Input() site:string;
  @Input() objectid:string;
  @Input() shiftSelect:string;

  objecListSub$: Subscription;
  objectListData:any = {};

  currentShiftFlagSub$:Subscription;

  timestamp:string;
  
  shiftData:shiftData;

  siteObjects:ObjectNode[] = [];
  currentObject:ObjectNode;
  showTestRepair:boolean=false;
  objectType:string;
  
  currentShift:boolean= true;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private svcObjectList: ObjectListService,
    private svcMetrics:MetricsService
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.getCurrentRoute();

    this.getObjectList();
    this.getShiftData();
  }

  getCurrentRoute():string {
    let routeSections:string[] = this.router.url.split("/");
    let myCurrentRoute:string;

    if (routeSections[1] == "oa") {
      myCurrentRoute = routeSections[1] + "/" + routeSections[2];
    } else {
      myCurrentRoute = routeSections[1];
    }

    return myCurrentRoute;
  }

  getObjectList() {
    if (this.objecListSub$) this.objecListSub$.unsubscribe();

    this.objecListSub$ = this.svcObjectList.objectList().subscribe(
      out => {
        this.objectListData = out.Body;
        this.siteObjects = this.objectListData.find( x => x.search == this.site);

        this.getObjectNode();
      }
    );
    
  }

  getObjectNode() {
    // Load the Object Config for selected object
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(take(1)).subscribe(
      (objectNode:ObjectNode) => {
        if (objectNode) {
          this.currentObject = objectNode;

          this.objectType = this.currentObject['ObjectType']

          if (this.currentObject['TestLine'] != "" || this.currentObject['RepairLine'] != "")
            this.showTestRepair = true;
          else {
            this.showTestRepair = false;

            // If this is testrepair but object isn't configured for testrepair force to metrics
            if (this.currentRoute == "testrepair") {
              this.navToMetrics();
            }
          }
        } else {
          // Getting a null for objectNode means the object is invalid, send back to home
          this.navToHome();
        }
      }
    );
  }

  getShiftData() {
    // Get the last Metrics load time (will be displayed as timestamp)
    this.svcMetrics.getLastMetricsLoad().subscribe(
      (lastLoadDate:Date) => {
        if (lastLoadDate) {
          // dayjs() didn't like UTC so changed to Z
          this.timestamp = dayjs(lastLoadDate.toString().replace(" UTC", "Z")).format('MM/DD/YY HH:mm:ss');
        }
      }
    );

    // Get boolean flag to determine if looking at current shift or not
    this.currentShiftFlagSub$ = this.svcMetrics.currentShiftFlag().subscribe(
      (out:boolean) => {
        this.currentShift = out;
      }
    );

    // Get the shift data being dispalyed
    this.svcMetrics.getShiftData().subscribe(
      out => {
        this.shiftData = out;
      }
    );
  }

  changeShift() {
    this.currentShift = !this.currentShift; // Toggle between Curretnt and Previous shift

    if (this.currentShift) {
      this.svcMetrics.changeShiftDate(this.currentShift, this.shiftData.current.shiftDate, this.shiftData.current.shift);
    } else {
      this.svcMetrics.changeShiftDate(this.currentShift, this.shiftData.previous.shiftDate, this.shiftData.previous.shift);   
    }
    
  }

  openShiftNote() {
    const dialogRef = this.dialog.open(ShiftNoteDialogComponent, {
      width: '700px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close Shift Note");
    });
  }

  changeShiftDate() {
    const dialogRef = this.dialog.open(ShiftSelectDialogComponent, {
      width: '300px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        shiftData: this.shiftData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close ShiftSelectDialogComponent");
    });
  }

  changeObject(site:string, objectid:string) {
    if (this.currentRoute == "problemlist") {
      this.currentRoute = "metrics";
    }
    // Might be reloading same component with new parameters, this forces the component to reinitialize
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(["/" + this.currentRoute, site, objectid]));
  }

  navToHome() {
    this.svcMetrics.changeShiftDate(true); // Reset back to current shift
    this.router.navigate(["/objectlist"]);
  }

  navToMetrics() {
    this.router.navigate(["/metrics", this.site, this.objectid]);
  }

  navToOAPlannedDownTime() {
    this.router.navigate(["/oa/planneddowntime", this.site, this.objectid]);
  }

  navToOACustomerDemand() {
    this.router.navigate(["/oa/customerdemand", this.site, this.objectid]);
  }

  navToInventory() {
    this.router.navigate(["/inventory", this.site, this.objectid]);
  }

  navToPAMatrix() {
    this.router.navigate(["/pamatrix", this.site, this.objectid]);
  }

  navToTestRepair() {
    this.router.navigate(["/testrepair", this.site, this.objectid]);
  }

  navToPareto() {
    this.router.navigate(["/pareto", this.site, this.objectid]);
  }

  navToAnalysis() {
    this.router.navigate(["/analysis", this.site, this.objectid]);
  }

  ngOnDestroy(): void{ 
    this.svcMetrics.cleanupObservers();
  }

}
