<form [formGroup]="noteFormGroup">
    <h3 mat-dialog-title><span class="fx-row-center-center">Hour Notes</span></h3>
    <div mat-dialog-content>
        <div class="fx-column-center-center">
            <div>
                <div>
                    <mat-label>Note:</mat-label>
                </div>
                <mat-form-field [style.width.px]="450" appearance="outline">
                    <textarea matInput rows="2" cols="40" placeholder="Add/Edit Note" formControlName="Note"></textarea>
                </mat-form-field>
            </div>
            <div>
                <div>
                    <mat-label>Corrective Action:</mat-label>
                </div>
                <mat-form-field [style.width.px]="450" appearance="outline">
                    <textarea matInput rows="2" cols="40" placeholder="Add/Edit Corrective Action" formControlName="Action"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-card  class="metrics-card mat-elevation-z1">
                <mat-card-content>
                    <mat-table [dataSource]="hourNotes">
                        <ng-container matColumnDef="Note">
                            <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
                            <mat-cell *matCellDef="let d"><div class="hour-note">{{d.Note}}</div></mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                            <mat-cell *matCellDef="let d"><div class="hour-note">{{d.CorrectiveAction}}</div></mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="Edit">
                            <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                            <mat-cell *matCellDef="let d"><button mat-button color="primary" (click)="editNote(d)"><mat-icon>create</mat-icon></button></mat-cell>
                        </ng-container>
    
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div mat-dialog-actions class="fx-row-space-between-center">
        <button mat-raised-button color="primary" (click)="saveNote()" [disabled]="noteFormGroup.invalid || !dataLoaded">Save</button>
        <button mat-raised-button color="primary" (click)="openFaultPreview()">Fault Preview</button>
        <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>
</form>