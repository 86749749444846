import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ungroup-confirm',
  templateUrl: './ungroup-confirm.component.html',
  styleUrls: ['./ungroup-confirm.component.scss']
})
export class UngroupConfirmComponent {

  occurances: number;

  constructor(
    public dialogRef: MatDialogRef<UngroupConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) detailsData
    ) { 
      this.occurances = detailsData.occurances;
    }

  onClick(value:boolean) {
    this.dialogRef.close(value);
  }

}
