import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AdminService, s3Link } from '../../services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { MatDialog } from '@angular/material/dialog';
import { Subscription} from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  inputs: ['problemgroup','text','admin'],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass']
})
export class FileUploadComponent implements OnInit {

  problemgroup:number;
  text: string;
  
  adminFlag: boolean;
  adminSub$: Subscription;

  @Output() onUploadStart: EventEmitter<any> = new EventEmitter(); // Event Emitted when upload has started
  @Output() onUploadComplete: EventEmitter<any> = new EventEmitter(); // Event Emitted when upload has completed

  constructor(private service: AdminService,
              private snakBar: MatSnackBar,
              private geaMesCognito: GeaMesCognitoAuthService,
              private dialog: MatDialog) {
    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });

  }

  ngOnInit() {
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
          for (let index = 0; index < fileUpload.files.length; index++) {
                const file = fileUpload.files[index];
                this.uploadFile(file);
          }
    };

    fileUpload.click();
  }  

  private uploadFile(file: File) {

    if (confirm(`Are you sure you want replace all problems with the values from the Excel file?`)) {
      this.startUpload(file);
    }
  }

  startUpload(file: File) {
    this.onUploadStart.emit(file.name);
    this.service.getS3Link(this.problemgroup, file.name).subscribe((
      out => {
        if (out.Body.status.substr(0,5) == "ALLOW") {
          this.completeUpload(out.Body,file);
        } else {
          this.snakBar.open(`${out.Body.status}.`, `Upload Failed` )
        }
    }));
  }

  completeUpload(signedURLInfo: s3Link, file: File) {
    this.service.uploadFile(signedURLInfo, file).subscribe(out => {
      this.snakBar.open(`Problem Group Config Uploaded.`, "Complete", { duration: 2000 } );
      this.onUploadComplete.emit(file.name);
    });
  }
}