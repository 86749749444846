<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>
<div style="padding: 15px">
    <div>
        <button mat-button class="mat-raised-button" *ngIf="selectedRow == null" (click)="addPart()">Add Part</button>
        <button mat-button class="mat-raised-button" *ngIf="selectedRow != null" (click)="editCount()">Edit Part</button>
        <button mat-button class="mat-raised-button" *ngIf="selectedRow != null" (click)="submitCount()">Submit Part</button>
      </div>
    
    <mat-table [dataSource]="inventoryData">
        <ng-container matColumnDef="PartDescription">
            <mat-header-cell *matHeaderCellDef>Part Description</mat-header-cell>
            <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.PartDescription}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Prior">
            <mat-header-cell *matHeaderCellDef>Prior</mat-header-cell>
            <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.Prior}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actual">
            <mat-header-cell *matHeaderCellDef>Actual</mat-header-cell>
            <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.Actual}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="LastUpdate">
            <mat-header-cell *matHeaderCellDef>Last Update</mat-header-cell>
            <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.ShiftDate}} {{d.Shift}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Modified">
            <mat-header-cell *matHeaderCellDef>Modified</mat-header-cell>
            <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.Modified | date:'YYYY-MM-dd hh:mm:ss'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="toggleRowSelect(row)"></mat-row>
    </mat-table>

</div>