<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>

<div style="padding: 15px">
    <app-metrics-header [object]="object" section=""></app-metrics-header>
    <div *ngIf="proficyOASetup">
        <div class="actionButtons">
            <div *ngIf="currentShift">
                <button mat-raised-button [disabled]="!unsavedChanges['CURRENT'] && !unsavedChanges['FUTURE']" color="primary" (click)="saveChanges()">Save All Changes</button>
                <span class="spacer"></span>
                <button mat-raised-button [disabled]="!unsavedChanges['CURRENT'] && !unsavedChanges['FUTURE']" (click)="undoChanges()">Undo All Changes</button>
            </div>
            <div>
                <div style="min-width: 275px">
                    <button mat-icon-button color="primary" (click)="openDefaults()"><mat-icon>event</mat-icon> Configure Default Customer Demand</button>
                </div>
            </div>
        </div>

        <div>
            <app-demand-form 
                [title]="currentShift ? 'Current Shift' : 'Previous Shift (no changes allowed)'"
                [allowEdits]="currentShift ? true : false"
                [currentShift]="currentShift"
                [site]="site"
                [environment]="environment"
                [objectid]="objectid"
                [customerDemand]="customerDemandSelected"
                [jobConfig]="jobConfig"
                [shiftDate]="shiftDate"
                [shift]="shift"
                [triggerSave]="triggerSave"
                (needSave)="setUpdateStatus('CURRENT',$event)"
                (saveComplete)="saveComplete('CURRENT',$event)"
            ></app-demand-form>
        </div>

        <div *ngIf="currentShift">
            <app-demand-form 
                title="Future Shift(s)"
                [allowEdits]="true"
                [currentShift]="false"
                [site]="site"
                [environment]="environment"
                [objectid]="objectid"
                [customerDemand]="customerDemandFuture"
                [jobConfig]="jobConfig"
                [shiftDate]="shiftDate"
                [shift]="shift"
                [triggerSave]="triggerSave"
                (needSave)="setUpdateStatus('FUTURE',$event)"
                (saveComplete)="saveComplete('FUTURE',$event)"
            ></app-demand-form>
        </div>
    </div>



    <div *ngIf="!proficyOASetup">
        Proficy not setup for OA for this piece of equipment. Please reach out to FIS to have them complete the configuration.
    </div>
</div>