import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObjectListService extends AbstractRestService {
  private objectid:string;
  private site:string;

  private objectListSub$:Subscription;
  private objectNode:BehaviorSubject<ObjectNode> = new BehaviorSubject<ObjectNode>(null);
  
  objects$:ReplaySubject<GenericLambdaReturn<ObjectNode[]>>;

  postInit(): void {
    
  }

  objectList(reload:boolean = false):Observable<GenericLambdaReturn<ObjectNode[]>>  {
    let params:any={};

    if ((!this.objects$) || (reload === true)) {
      this.objects$ = new ReplaySubject();

      this.get({
        ApiResource: "/object",
        data: params,
        repeatCall: false
      }).subscribe(
        (out:GenericLambdaReturn<ObjectNode[]>) => {
          this.objects$.next(out);
        }
      );
    }

    return this.objects$;
  }

  getJobConfig(environment:string,objectid:string):Observable<GenericLambdaReturn<JobConfig[]>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Environment = environment;

    return this.get({
      ApiResource: "/jobconfig",
      data: params,
      repeatCall: false
    });
  }



  getObjectConfig(site:string, objectid:string):Observable<ObjectNode> {
    if (this.objectid != objectid || this.site != site) {
      this.objectid = objectid;
      this.site = site;
      this.objectNode.next(null);
      this.lookupObjectConfig();
    }

    return this.objectNode.asObservable();
  }

  private lookupObjectConfig() {
    if (this.objectListSub$) this.objectListSub$.unsubscribe();

    this.objectListSub$ = this.objectList().subscribe(
      out => {
        let plantNode = out.Body.find( plant => plant.search == this.site); // Get PlantNode
        let myObjectNode:ObjectNode = this.findObjectID(plantNode); // Get ObjectID node

        this.objectNode.next(myObjectNode);
      }
    );
  }

  private findObjectID(node:ObjectNode):ObjectNode {
    if (node.ObjectID && node.ObjectID == this.objectid) {
        return node;
    } else if (node.children) {
      let result:ObjectNode;

      for(let i = 0; result == null && i < node.children.length; i++) {
        result = this.findObjectID(node.children[i]);
      }
      return result;
    } 
    return null;
  }

}

export interface ObjectNode {
  Description: string;
  search:string;
  PlantID?: string;
  Site?: string;
  Environment?:string;
  ObjectID?:string;
  children?: ObjectNode[];
  ProbLevelReq?: number;
  ProbNoteReq?: number;
  ManProb?: number;
  ClassID?: string;
  PartCountAvail: number;
  PerJobObject: string;
  ObjectType: string;
}

export type JobConfig = {
  JobID: number;
  JobDescrpition: string;
  IdealCycleTime: number;
}