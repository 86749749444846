import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-planned-downtime-table-dialog',
  templateUrl: './planned-downtime-table-dialog.component.html',
  styleUrls: ['./planned-downtime-table-dialog.component.scss']
})
export class PlannedDowntimeTableDialog implements OnInit {
  objectid:string;
  site:string;
  environment:string;
  downtimedata:any[];
  displayedColumns: string[] = ['ShiftDate', 'Shift', 'Event', 'Occurances', 'DowntimeDuration']
  tableData:any[] =[];
  loadingData:boolean=true;

  constructor(
    public dialogRef: MatDialogRef<PlannedDowntimeTableDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inData: any,

  ) {

    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.environment = this.inData.environment;
    this.downtimedata = this.inData.data;

   }

  ngOnInit(): void {    
    this.tableData = this.processData(this.downtimedata);
  }

  close(updates:boolean = false) {
    this.dialogRef.close({
      updates: updates
    });
  }

  processData(data: any[]) {
  let summary: { [key: string]: { ShiftDate: string, Shift: string, Event: string, Occurances: number, DowntimeDuration: string } } = {};
  let sum:number=0;
  this.loadingData=true;

  // Iterate through data
  data.forEach(entry => {
    const key = `${entry.ShiftDate}_${entry.Shift}_${entry.Activity}`;

    if (entry.Duration !== "") {
      if (!summary[key]) {
        sum=0;
        summary[key] = {
          ShiftDate: entry.ShiftDate,
          Shift: entry.Shift,
          Event: entry.Activity,
          Occurances: 0,
          DowntimeDuration: "0",
        };
      }

      if(entry.ActivityType!="PLANNED_OT"){
        summary[key].Occurances++; 
      }           
      sum += parseInt(entry.Duration);
      summary[key].DowntimeDuration = this.formatDowntime(sum);
    }
  });
  console.log ("ObjectValues", Object.values(summary));
  // Convert summary object to array of values and filter where count > 0
  this.loadingData = false;
  return Object.values(summary).filter(entry => entry.Occurances > 0);
  }

  formatDowntime(downtime: number){
    return new Date(downtime*1000).toISOString().slice(11, 19);
  }
}