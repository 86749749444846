import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MetricsService } from 'src/app/services/metrics.service';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { HourNoteDialogComponent } from '../hour-note-dialog/hour-note-dialog.component';
import { FilterComponent } from './filter/filter.component';
import { ProblemConfig, ProblemDialogComponent, ProblemDialogData } from './problem-dialog/problem-dialog.component';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeStatus, ProblemService } from 'src/app/services/problem.service';
import { filter, take } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-problem-list',
  templateUrl: './problem-list.component.html',
  styleUrls: ['./problem-list.component.scss']
})
export class ProblemListComponent implements OnInit, OnDestroy {
  object:ObjectNode;

  problemFormGroup: UntypedFormGroup;
  objectid:string;
  site:string;
  hour:number;
  showAllHours:boolean;
  shiftDate:string;
  shift:string;
  hours:any[];
  minDuration:number=0;
  cycleTime:number;
  objectType:string = "ASSEMBLY";
  
  manualEntry:boolean = false;

  config:ProblemConfig = {
    problemLevelRequired:3,
    problemNoteRequired: false,
    showTeam: false

  }

  problemsSub$: Subscription;
  problemsData:any = {};

  problemSelectSub$: Subscription;
  problemSelectData:[] = [];

  changeSub$: Subscription;

  problemEntryData : ProblemDialogData[] = [];
  
  editMode:boolean = false;

  faultListSub$: Subscription;
  faultListData: FaultData[];
  faultListCurrent:boolean = false;
  
  metricsHeaderSub$: Subscription;
  metricsHeaderData:any = {};
  
  objecListSub$: Subscription;

  reloadSub$: Subscription;
  
  displayedColumns: string[] = []; // Hour is removed in ngOninit() if looking at just a single hour
  fullScreenDisplayedColumns: string[] = ['Hour','Fault','Duration','LostUnits','Occurrences','Notes'];
  mobileScreenDisplayedColumns: string[] = ['Hour','Fault','Duration','Notes'];

  constructor(    
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private svcMetrics:MetricsService,
    private svcObjectList: ObjectListService,
    private svcProblem: ProblemService,
    private breakPointObserver: BreakpointObserver
    ) { }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
    let hourParam:string = this.route.snapshot.paramMap.get('hour');

    if (hourParam == 'ALL') {
      this.hour = -1;
      this.showAllHours = true;
    } else {
      this.hour = +hourParam; // Convert string to number
      this.fullScreenDisplayedColumns.splice(0,1); // Delete first entry from columns, which should be Hour because we don't want to show it here.
      this.mobileScreenDisplayedColumns.splice(0,1); // Delete first entry from columns, which should be Hour because we don't want to show it here.
    }

    // Observe when going into mobile or desktop view to change columns being displayed
    const mobileView = this.breakPointObserver.observe('(max-width: 1300px)').subscribe(
      (out) => {
        if (out.matches) {
          this.displayedColumns = this.mobileScreenDisplayedColumns;
        } else {
          this.displayedColumns = this.fullScreenDisplayedColumns;
        }
      }
    );

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;

        (this.object.ManProb != 0) ? this.manualEntry = true : this.manualEntry = false;
        (this.object.ProbNoteReq != 0) ? this.config.problemNoteRequired = true : this.config.problemNoteRequired = false;
        this.config.problemLevelRequired = this.object.ProbLevelReq;

        this.getData();
      }
    );

  }

  getData() {

    if (!this.showAllHours) {
      // Only need data from problems if looking for specific hour
      if (this.problemsSub$) this.problemsSub$.unsubscribe();
      this.problemsSub$ = this.svcMetrics.problems(this.object, this.minDuration).subscribe(
        out => {

          if (out) {
            console.log("GETTING PROBVLEMS", out);
            const hours:any[] = out.Body;
            this.problemsData = hours.find(hour => hour.Hour == this.hour);
            this.hours = hours;
          }
        }
      );
    }

    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    this.metricsHeaderSub$ = this.svcMetrics.metricsHeader(this.object).subscribe(
      out => {
        if (out) {
          this.metricsHeaderData = out.Body.PRODUCTION;
          let oldShift:string = this.shift;
          let oldShiftDate:string = this.shiftDate;

          this.shift = this.metricsHeaderData['Shift'];
          this.shiftDate = this.metricsHeaderData['ShiftDate'];
          this.cycleTime = this.metricsHeaderData['idealCycleTime'];

          if (oldShift != this.shift || oldShiftDate != this.shiftDate) {
            // Detected shift and/or shiftdate changed, so immediately force a reload of fault list
            if (!this.editMode && !this.saveInProgress) {
              this.getFaultList();
            }  
          }
        }
      }
    );

    this.svcMetrics.teamList(this.site, this.objectid).pipe(take(1)).subscribe(
      out => {
        console.log("out.Body", out.Body);
        this.config.showTeam = out.Body.Teams.length != 0 ? true : false;
      }
    );

  }

  getFaultList() {
    this.closeFaultlist()

    this.faultListSub$ = this.svcMetrics.faultList(this.object, this.shiftDate, this.shift, this.hour).subscribe(
      out => {
        if (out && !this.editMode) {
          this.faultListData = out.Body.map(x => this.problemEntry(x));
          this.faultListCurrent = true; 
        }
      }
    );
  }

  closeFaultlist(){
    if (this.faultListSub$) this.faultListSub$.unsubscribe();
    this.svcMetrics.faultListClose();
  }


  filter() {
    const dialogRef = this.dialog.open(FilterComponent, {
      width: '250px',
      maxHeight: '800px',
      data: {
        minDuration: this.minDuration
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.minDuration = result.minDuration;
        this.getData();
      }
    });
  }

  openHourNote() {
    const dialogRef = this.dialog.open(HourNoteDialogComponent, {
      width: '700px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        hour: this.hour
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close Hour Notes");
    });
  }

  manualProblemEntry() {
    let problem:ProblemDialogData = {
      config: this.config,
      objectid: this.objectid,
      selectedhour: this.hour,
      site: this.site,
      manual: true,
      hours: this.hours,
      shift: this.shift,
      shiftDate: this.shiftDate
    }

    const dialogRef = this.dialog.open(ProblemDialogComponent, {
      width: '700px',
      maxHeight: '900px',
      data: problem
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close ProblemDialog for Manual");
      this.getData();
    });
  }

  problemEntry(x:FaultData) {
    x.config = this.config;
    x.objectid = this.objectid;
    x.site = this.site;
    x.hours = this.hours;
    x.shift = this.shift;
    x.shiftDate = this.shiftDate;
    x.site = this.site
    return x;
  }

  ngOnDestroy(): void { 
    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    if (this.problemsSub$) this.problemsSub$.unsubscribe();
    this.closeFaultlist();
    if (this.objecListSub$) this.objecListSub$.unsubscribe();
    if (this.changeSub$) this.changeSub$.unsubscribe();

    this.svcMetrics.cleanupObservers();
  }

  formatDuration(seconds:number) {
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  getLostUnits(downtime: number) {
    return (Math.round(downtime/this.cycleTime))
  }

  saveInProgress:boolean = false;
  editStatus:ChangeStatus = ChangeStatus.NO_CHANGES;
  ChangeStatusEnum = ChangeStatus;
  toggleEditMode() {
    this.editMode = !this.editMode


    if (this.editMode) {
      this.closeFaultlist(); // Stop pulling faults for now.
      if (this.changeSub$) this.changeSub$.unsubscribe();

      this.changeSub$ = this.svcProblem.getChangeStatus().subscribe(
        (status:ChangeStatus) => {
          this.editStatus = status;

          if (this.saveInProgress) {
            if (status == ChangeStatus.NO_CHANGES) {
              this.saveInProgress = false;
              this.getFaultList();
            }
          } 
        }
      )

      if (this.reloadSub$) this.reloadSub$.unsubscribe();
    
      this.reloadSub$ = this.svcProblem.getReloadTrigger().subscribe(
        (res) => {
          if (res) {
            this.toggleEditMode();
            if (this.reloadSub$) this.reloadSub$.unsubscribe();
          }
        }
      )

    } else {
      this.faultListCurrent = false; // Fault list data should no longer be considered current.
      this.saveInProgress = true;
      this.faultListData = [];
      this.svcProblem.setSavetrigger(true); // Trigger unsaved changes to be saved. TODO: The screen may display old versions until save is complete, should clean that up.

      // If no changes, then get fault list
      if (this.editStatus <= ChangeStatus.NO_CHANGES) {
        this.getFaultList();
      }
    }
  }
}

export interface FaultData {
  FaultMessage: string;
  FaultValue: number;
  Hour: string;
  Problem: string;
  Problem_Level2: string;
  Problem_Level3: string;
  Problem_time_amt: number;
  Notes: string;
  ID: number;
  objectid: string;
  site: string;
  hours: any;
  shift: string;
  shiftDate: string;
  config: ProblemConfig;
  Occurrences: number;
  Team:string,
  Source:string
}
