import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private Activatedroute:ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    let currentURL:string = window.location.toString();
    let url:string = "/hrxhr.html?";

    for (let key in this.Activatedroute.snapshot.queryParams) {
      url += key + '=' + encodeURIComponent(this.Activatedroute.snapshot.queryParams[key]) + '&';
    }
    console.log("Redirecting from Angular to JQuery App", url);
    if (currentURL.indexOf("localhost") >= 0) { 
      console.log("Localhost, go to new Angular version of HRXHR");
      this.router.navigate(["/objectlist"]); // If on localhost, navigate to objectlist to make testing new HRXHR Angular conversion easier to test locally
    } else {
      window.location.href = url;
    }
  }

}
