import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

import { ObjectConfigType } from '../components/object-dialog/object-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class AdminService extends AbstractRestService {

  postInit(): void {
    console.log("Post INIT!");
  }

  objectList():Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    return this.get({
      ApiResource: "/object",
      data: params,
      repeatCall: true
    });
  }

  editObject(objConfig: ObjectConfigType):Observable<GenericLambdaReturn<any>> {
    let params:any={}
    console.log("objConfig",objConfig);
    params.PlantName = objConfig.PlantName;
    params.Section = objConfig.Section;
    params.ObjectID = objConfig.ObjectID;
    params.ObjectDescription = objConfig.Description;
    params.ManCount = objConfig.ManCount;
    params.ManProb = objConfig.ManProb;
    params.ProblemGroup = objConfig.ProblemGroup;
    params.TeamGroup = objConfig.TeamGroup;
    params.ProbLevelReq = objConfig.ProbLevelReq;
    params.ProbNoteReq = objConfig.ProbNoteReq;
    params.ObjectType = objConfig.ObjectType;
   
    params.TestLine = objConfig.TestLine ? objConfig.TestLine : '';
    params.TestStation = objConfig.TestStation ? objConfig.TestStation : '';
    params.TestArea = objConfig.TestArea ? objConfig.TestArea : '';
    params.TestType = objConfig.TestType ? objConfig.TestType : '';
    params.RepairLine = objConfig.RepairLine ? objConfig.RepairLine : '';
    params.RepairLocation = objConfig.RepairLocation ? objConfig.RepairLocation : '';
    params.SchemaName = objConfig.SchemaName ? objConfig.SchemaName : '';

    console.log("params",params);

    return this.put({
      ApiResource: "/object",
      data: params,
      repeatCall: false
    });    

  }

  deleteObject(Site:string, Environment:string, ObjectID:string):Observable<GenericLambdaReturn<any>> {
    let params:any={}

    params.site = Site.toString();
    params.environment = Environment.toString();
    params.objectid = ObjectID.toString();

    return this.delete({
      ApiResource: "/objectlist",
      data: params,
      repeatCall: false
    });    

  }

  plantList():Observable<GenericLambdaReturn<PlantType>>  {
    return this.get({
      ApiResource: "/plants",
      repeatCall: false
    });    
  }    

  teamGroupsGet(plantName:string, teamGroup?:number):Observable<GenericLambdaReturn<TeamGroup[]>> {
    let params:any={};

    params.PlantName = plantName;
    teamGroup ? params.TeamGroup = teamGroup.toString():null;

    return this.get({
      ApiResource: "/teamgroupconfig",
      data: params,
      repeatCall: true,
      repeatRate: 30000
    });        
  }

  teamGroupAdd(description:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.TeamGroupDescription = description;

    return this.post({
      ApiResource: "/teamgroupconfig",
      data: params,
      repeatCall: false
    });
  }  

  teamGroupDelete(teamGroup:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.TeamGroup = teamGroup;

    return this.delete({
      ApiResource: "/teamgroupconfig",
      data: params,
      repeatCall: false
    });
  }

  teamDelete(teamGroup:number, team:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.TeamGroup = teamGroup;
    params.Team = team;

    return this.delete({
      ApiResource: "/teamconfig",
      data: params,
      repeatCall: false
    });
  } 

  teamAdd(teamGroup:number, team:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.TeamGroup = teamGroup;
    params.Team = team;

    params.CurrentTeam = "";
    params.CurrentTeamGroup = "";


    return this.post({
      ApiResource: "/teamconfig",
      data: params,
      repeatCall: false
    });
  }

  problemGroups(plantName:string):Observable<GenericLambdaReturn<ProblemGroupType>>  {
    let params:any={};

    params.PlantName = plantName;

    return this.get({
      ApiResource: "/problemconfig",
      data: params,
      repeatCall: false
    });    
  }

  problemGroup(ProblemGroup:number):Observable<GenericLambdaReturn<ProblemGroupType>>  {
    let params:any={};
    
    params.ProblemGroup = ProblemGroup.toString();

    return this.get({
      ApiResource: "/problemconfig",
      data: params,
      repeatCall: true,
      repeatRate: 5000
    });    
  }    


  problemListDetails(ProblemGroup: number):Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    params.ProblemGroup = ProblemGroup;
    return this.get({
      ApiResource: "/problemgroup",
      data: params,
      repeatCall: false
    });        
  }

  problemDelete(problemGroup:number, problems:ProblemNode[]):Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    params.Problems = [];
    for (let problem of problems) {
      params.Problems.push({
                              "ProblemGroup": problemGroup,
                              "Parent": problem.Parent,
                              "Problem": problem.Problem
                            });
    }

    return this.post({
      ApiResource: "/problemgroup",
      data: params,
      repeatCall: false
    });

  }  

  problemAdd(problemGroup:number, node:ProblemNode):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ChangeType = "Problem";
    params.ProblemGroup = problemGroup;
    params.Problem = node.Problem;
    params.ParentProblem = node.Parent;

    params.CurrentProblemGroup = "";
    params.ProblemGroupDescription = "";


    return this.post({
      ApiResource: "/problemconfig",
      data: params,
      repeatCall: false
    });
  }

  problemGroupAdd(problemGroupDescription:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ChangeType = "Group";
    params.ProblemGroup = "";
    params.Problem = "";
    params.ParentProblem = "";

    params.CurrentProblemGroup = "";
    params.ProblemGroupDescription = problemGroupDescription;


    return this.post({
      ApiResource: "/problemconfig",
      data: params,
      repeatCall: false
    });
  }

  problemGroupDelete(problemGroup:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ChangeType = "Group";
    params.ProblemGroup = problemGroup;
    params.Prob = "";
    params.ParentProblem = "";

    return this.delete({
      ApiResource: "/problemconfig",
      data: params,
      repeatCall: false
    });
  }  

  andonGroupList(Site:string, Environment:string, ObjectID:string):Observable<GenericLambdaReturn<any>> {
    let params:any={}

    params.Site = Site.toString();
    params.Environment = Environment.toString();
    params.ObjectID = ObjectID.toString();

    return this.get({
      ApiResource: "/andongroups",
      data: params,
      repeatCall: false
    });    

  }

  andonGroupDelete(Site:string, Environment:string, ObjectID:string, AndonGroup: string):Observable<GenericLambdaReturn<any>> {
    let params:any={}

    params.Site = Site.toString();
    params.Environment = Environment.toString();
    params.ObjectID = ObjectID.toString();
    params.AndonGroup = AndonGroup.toString();

    return this.delete({
      ApiResource: "/andongroups",
      data: params,
      repeatCall: false
    });    

  }  

  andonGroupAdd(Site:string, Environment:string, ObjectID:string, AndonGroup: string):Observable<GenericLambdaReturn<any>> {
    let params:any={}

    params.Site = Site.toString();
    params.Environment = Environment.toString();
    params.ObjectID = ObjectID.toString();
    params.AndonGroup = AndonGroup.toString();

    return this.put({
      ApiResource: "/andongroups",
      data: params,
      repeatCall: false
    });    

  }    

  trSchemaList():Observable<GenericLambdaReturn<trSchemaType>>  {
    return this.get({
      ApiResource: "/trschema",
      repeatCall: false
    });    
  }

  getS3Link(problemgroup:number, filename: string):Observable<GenericLambdaReturn<s3Link>>  {
    return this.get({
      ApiResource: "/admins3link",
      data: {
        ProblemGroup: problemgroup
      },
      repeatCall: false
    })
  }

  uploadFile(signedURLInfo: s3Link, file: File):Observable<GenericLambdaReturn<any>> {
    let fd = new FormData();
    
    for (var key in signedURLInfo.s3response.fields) {
      let fieldName = key.replace("_","-")
      fd.append(fieldName, signedURLInfo.s3response.fields[key])
    }

    fd.append("file",file);

    return this.post({
      ApiCompleteURL: signedURLInfo.s3response.url,
      data: fd,
      disableAuthHeader: true,
      repeatCall: false
    });
  }
}

export type PlantType = {
  displayName: string;
  site: string;
  environment; string
}

export type ProblemGroupType = {
  ProblemGroup: number;
  Description: string;
  Problems: ProblemType[];
}

export type ProblemType = {
  Problem: string;
  ParentProblem: string;
}

export type trSchemaType = {
  SCHEMA_NAME: string;
}

export type ProblemNode = {
  Problem?: string;
  Parent: string;
  isChild: boolean;
  Level: number;
  children?: ProblemNode[];
}

export type s3Link = {
  s3response: {
    url: string;
    fields: {
      key: string;
      x_amz_algorithm: string;
      x_amz_credential: string;
      x_amz_date: string;
      x_amz_security_token: string;
      policy: string;
      x_amz_signature: string;
    }
  };
  status: string
}

export type TeamGroup = {
  TeamGroup: number;
  Description: string;
  Teams: Team[];
}

export type Team = {
  Team: string;
}
