<form [formGroup]="actionFormGroup">
    <h3 mat-dialog-title>Add Action</h3>
    <div mat-dialog-content>
        
        <mat-form-field [style.width.px]="450">
            <textarea matInput rows="5" cols="40" placeholder="Description" formControlName="Description"></textarea>
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <input matInput cdkTextareaAutosize placeholder="Date" formControlName="ActionDate">
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-form-field>
            <input matInput cdkTextareaAutosize placeholder="Action By" formControlName="EnteredBy">
        </mat-form-field>


    </div>
    <div mat-dialog-actions>
        <button class="mat-raised-button" (click)="addAction()" [disabled]="actionFormGroup.invalid">Save</button>
        <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
    </div>
</form>