import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';


import { MetricsService } from 'src/app/services/metrics.service';
import { ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-fault-preview-dialog',
  templateUrl: './fault-preview-dialog.component.html',
  styleUrls: ['./fault-preview-dialog.component.scss']
})
export class FaultPreviewDialogComponent implements OnInit, OnDestroy {
  objectid:string;
  site:string;
  hour:number;
  object:ObjectNode;
  
  metricsTopProblemsSub$: Subscription;
  metricsTopProblemsData:any = [];

  problemsExist:number[] = [0];
  displayedColumns: string[] = ['Fault', 'Duration']

  constructor(
    public dialogRef: MatDialogRef<FaultPreviewDialogComponent>,
    private svcMetrics:MetricsService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.object = this.inData.object;
    this.hour = parseInt(this.inData.hour);
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();

    this.metricsTopProblemsSub$ = this.svcMetrics.metricsTopProblems(this.object, true).subscribe(
      out => {
        if (out) {
          this.metricsTopProblemsData = out.Body;
          this.filterAndSort(this.metricsTopProblemsData);

          for (let idx:number = 0; idx < this.metricsTopProblemsData.length; idx++) {
            this.problemsExist[idx] = this.metricsTopProblemsData[idx]['Faults'].length;
          }
        }
      }
    );
  }

  filterAndSort(data: any[]) {
    data.forEach((x) => {
      x['Faults'].sort((y, z) => z['Duration'] - y['Duration']).slice(0, 3);
    })
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

  ngOnDestroy(): void{ 
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();
    this.svcMetrics.cleanupObservers();
  }
}
