import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetricsService, shiftData } from 'src/app/services/metrics.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-shift-select-dialog',
  templateUrl: './shift-select-dialog.component.html',
  styleUrls: ['./shift-select-dialog.component.scss']
})
export class ShiftSelectDialogComponent implements OnInit {
  objectid:string;
  site:string;
  shiftData:shiftData;

  shiftFormGroup:UntypedFormGroup;
  shiftList:string[] = ['1ST SHIFT','2ND SHIFT','3RD SHIFT'];

  shiftdate:string;

  constructor(
    public dialogRef: MatDialogRef<ShiftSelectDialogComponent>,
    private fb: UntypedFormBuilder,
    private svcMetrics:MetricsService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;
    this.shiftData = this.inData.shiftData;

    this.shiftFormGroup = this.fb.group({
      Shift: new UntypedFormControl({value: this.shiftData.display.shift, disabled: false}, Validators.required)
    });

  }

  ngOnInit(): void {
  }

  onDateSelection(e) {
    this.shiftdate = dayjs(e.start).format('YYYY-MM-DD'); 
    console.log("New shiftdate", this.shiftdate);
  }

  changeShift():void {
    console.log("Change Shift", this.shiftdate);

    let shift:string = this.shiftFormGroup.get('Shift').value;

    this.svcMetrics.changeShiftDate(false, this.shiftdate, shift);

    this.dialogRef.close();
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}
