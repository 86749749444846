<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>
<div style="padding: 15px">
  <div>
    <button mat-button class="mat-raised-button" (click)="getData()">Show Closed Problems</button> For the Last: <mat-form-field  [style.width.px]="75"><input matInput type="number" size="1" min="0" [(ngModel)]="days"></mat-form-field> Days
  </div>

  <div>
    <button mat-button class="mat-raised-button" (click)="addProblem()">Add Problem</button>
    <button mat-button class="mat-raised-button" *ngIf="selectedRow != null && selectedRow.ClosedDate != null" (click)="reopenProblem()">Reopen Problem</button>
    <button mat-button class="mat-raised-button" *ngIf="selectedRow != null && selectedRow.ClosedDate == null" (click)="editProblem()">Edit Problem</button>
    <button mat-button class="mat-raised-button" *ngIf="selectedRow != null && selectedRow.ClosedDate == null" (click)="closeProblem()">Close Problem</button>
  </div>

  <div>
    <mat-table matSort [dataSource]="paMatrixData" multiTemplateDataRows>

      <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">
            <button mat-button (click)="toggleDisplayActions(d, $event)">
                <mat-icon *ngIf="!expandedActions[d.ItemNumber]" [ngStyle]="{'color':'green'}">add_circle</mat-icon>
                <mat-icon *ngIf="expandedActions[d.ItemNumber]" [ngStyle]="{'color':'red'}">remove_circle</mat-icon>
            </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Description">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Description</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.Description}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssignedTo">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Assigned To</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.AssignedTo}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="DueDate">
          <mat-header-cell *matHeaderCellDef>Due Date</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.DueDate | date:'yyyy-MM-dd hh:mm:ss'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">
            <span *ngIf="d.Status==1">Open</span>
            <span *ngIf="d.Status!=1">Closed</span>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CreatedBy">
          <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.CreatedBy}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="CreatedDate">
          <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.CreatedDate | date:'yyyy-MM-dd hh:mm:ss'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoggedBy">
          <mat-header-cell *matHeaderCellDef>Logged By</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.LoggedBy}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ClosedDate">
          <mat-header-cell *matHeaderCellDef>Closed Date</mat-header-cell>
          <mat-cell [ngClass]="d == selectedRow ? 'selectedRow' : null" *matCellDef="let d">{{d.ClosedDate | date:'yyyy-MM-dd hh:mm:ss'}}</mat-cell>
      </ng-container>
      
      <!-- ACTION Table -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="expandedActions[element.ItemNumber] ? 'expanded' : 'collapsed'"
          >
            <div class="inner-table mat-elevation-z8" *ngIf="expandedActions[element.ItemNumber]">
              <mat-table
                #innerTables
                [dataSource]="element.Actions"
                matSort
              >

                <ng-container matColumnDef="DetailAction">
                  <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.Action}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="DetailDate">
                  <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.Date | date:'MM/dd/yyyy hh:mm:ss'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="DetailEnteredBy">
                  <mat-header-cell *matHeaderCellDef>Action By</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.EnteredBy}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="DetailLoggedBy">
                  <mat-header-cell *matHeaderCellDef>Logged By</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.LoggedBy}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedDetailActionColumns"></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedDetailActionColumns;"
                ></mat-row>
              </mat-table>

              <button mat-button class="mat-raised-button"  [ngClass]="'add-action-button'" (click)="addAction(element)">Add Action</button>
            </div>
            
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let element; columns: displayedColumns;"
        (click)="toggleRowSelect(element)"
      ></mat-row>

      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
  </mat-table>
  </div>

</div>