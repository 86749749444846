import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaMatrixService extends AbstractRestService {

  postInit(): void {
    console.debug("PaMatrixService Init Complete");
  }

  view(site:string, objectid: string, days:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.Days = days;

    return this.get({
      ApiResource: "/pamatrixviewupdate",
      data: params,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  problemAddEdit(problem:Problem):Observable<GenericLambdaReturn<any>> {
    let params:any=problem;
    params.Object=`${problem.Site}/Other/${problem.ObjectID}`;

    return this.post({
      ApiResource: "/pamatrixviewupdate",
      data: params,
      repeatCall: false
    })
  }

  actionAdd(action:Action):Observable<GenericLambdaReturn<any>> {
    let params:any=action;

    return this.post({
      ApiResource: "/pamatrixnewaction",
      data: params,
      repeatCall: false
    })
  }
    
}

export type Problem = {
  ObjectID: string;
  Site: string;
  ItemNumber?: number;
  Description: string;
  CreatedDate: string;
  DueDate: string;
  ClosedDate: string;
  CreatedBy: string;
  AssignedTo: string;
  LoggedBy: string;
  Status: number;
}

export type Action = {
  ItemNumber: number; 
  ActionDate: string;
  Action: string;
  EnteredBy: string;
  LoggedBy: string;
}