import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { MetricsService } from 'src/app/services/metrics.service';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-test-repair',
  templateUrl: './test-repair.component.html',
  styleUrls: ['./test-repair.component.scss']
})
export class TestRepairComponent implements OnInit {
  objectid:string;
  site:string;

  object:ObjectNode;
  
  rejectsSub$: Subscription;
  rejectsData:any = {};

  repairSub$: Subscription;
  repairData:any = {};

  displayedColumns: string[] = ['Description','Count']

  constructor(
    private route: ActivatedRoute,
    private svcMetrics:MetricsService,
    private svcObjectList:ObjectListService
  ) { }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;
      }
    );

    console.log("problemlist ngOnInit", this.site, this.objectid);
    this.getData();
  }

  getData() {
    if (this.rejectsSub$) this.rejectsSub$.unsubscribe();

    this.rejectsSub$ = this.svcMetrics.rejects(this.site, this.objectid, 5).subscribe(
      out => {
        this.rejectsData = out.Body;

        console.log(this.rejectsData);
      }
    );

    if (this.repairSub$) this.repairSub$.unsubscribe();

    this.repairSub$ = this.svcMetrics.repairs(this.site, this.objectid,5 ).subscribe(
      out => {
        this.repairData = out.Body;

        console.log(this.repairData);
      }
    );
  }

  ngOnDestroy(): void { 
    if (this.rejectsSub$) this.rejectsSub$.unsubscribe();
    if (this.repairSub$) this.repairSub$.unsubscribe();
  }

}
