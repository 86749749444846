<div><button [disabled]="!adminFlag" mat-icon-button (click)="addNewProblemGroup()"><mat-icon>add</mat-icon>Create Problem Group</button></div>

<mat-table [dataSource]="mDataSource">
    <ng-container matColumnDef="Description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let d">{{d.Description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let d; let i = index">
          <button mat-button color="link" (click)="editProblems(d.ProblemGroup, d.Description)"><mat-icon>assignment</mat-icon>Edit Problems</button>          
          <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteProblemGroup(d.ProblemGroup, d.Description)"><mat-icon>delete_forever</mat-icon>Delete</button>
        </mat-cell>
      </ng-container>  

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>