import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaMatrixService, Problem } from '../../services/pa-matrix.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-close-problem',
  templateUrl: './close-problem.component.html',
  styleUrls: ['./close-problem.component.scss']
})
export class CloseProblemComponent implements OnInit {

  problem:Problem;
  closeFormGroup:UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CloseProblemComponent>,
    private svcPAMatrix:PaMatrixService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.problem = this.inData.problemData;

    if (!this.problem.ClosedDate || this.problem.ClosedDate == "") {
      this.problem.ClosedDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    }

    this.closeFormGroup = this.fb.group({
      ClosedDate: new UntypedFormControl({value: this.problem.ClosedDate, disabled: false}, Validators.required)
    });
  }

  ngOnInit(): void {
  }

  saveData():void {
    console.log("Close Problem");

    this.problem.Status = 0;

    this.svcPAMatrix.problemAddEdit(this.problem).subscribe(
      out => {
        console.log(out);
        this.dialogRef.close();
      }
    );
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}
