<app-metrics-header-assembly
    *ngIf="this.object && this.object.ObjectType != 'FAB'"
    [object]="object"
    [section]="section">
</app-metrics-header-assembly>


<app-metrics-header-fab 
    *ngIf="this.object && this.object.ObjectType == 'FAB'"
    [object]="object"
    [section]="section">
</app-metrics-header-fab>

