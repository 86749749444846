import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action, PaMatrixService, Problem } from '../../services/pa-matrix.service';
import * as dayjs from 'dayjs';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss']
})
export class AddActionComponent implements OnInit {
  problem:Problem;
  userid:string;

  actionFormGroup:UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddActionComponent>,
    private fb: UntypedFormBuilder,
    private svcPAMatrix:PaMatrixService,
    private svcAuth: GeaMesCognitoAuthService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.problem = this.inData.problemData;

    this.actionFormGroup = this.fb.group({
      Description: new UntypedFormControl({value: "", disabled: false}, Validators.required),
      ActionDate: new UntypedFormControl({value: dayjs().format("YYYY-MM-DD HH:mm:ss"), disabled: false}, Validators.required),
      EnteredBy: new UntypedFormControl({value: "", disabled: false})
    });
  }

  ngOnInit(): void {
    this.userid = this.svcAuth.getUserAttribute('sso');
  }

  addAction():void {
    console.log("Add Action");

    let action:Action = {
      ItemNumber: this.problem.ItemNumber,
      ActionDate: this.actionFormGroup.get('ActionDate').value,
      Action: this.actionFormGroup.get('Description').value,
      EnteredBy: this.actionFormGroup.get('EnteredBy').value,
      LoggedBy: this.userid
    }

    this.svcPAMatrix.actionAdd(action).subscribe(
      out => {
        console.log(out);

        this.dialogRef.close();
      }
    );
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}
