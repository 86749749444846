import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AdminService, Team } from '../../services/admin.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams-dialog.component.html',
  styleUrls: ['./teams-dialog.component.scss']
})
export class TeamsDialogComponent implements OnInit {
  
  adminFlag: boolean;
  adminSub$: Subscription;

  title:string;

  input: any;
  type:string;

  addFormGroup: UntypedFormGroup;

  displayedObjectColumns: string[] = ['Team','actions'];

  teams:Team[];

  constructor(public dialogRef: MatDialogRef<TeamsDialogComponent>,
    private svcAdmin: AdminService,
    private fb: UntypedFormBuilder,
    private geaMesCognito: GeaMesCognitoAuthService,
    @Inject(MAT_DIALOG_DATA) public inData: TeamsDialogType) { 

      this.input = inData['teamData'];
      this.type = inData['type'];
    }

  ngOnInit(): void {
    this.title = `Team Group: ${this.input.Description}`;

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
    this.getTeams();
    this.addFormGroup = this.fb.group({
      NewTeam: ''
    });
  }

  getTeams() {
    this.svcAdmin.teamGroupsGet('ALL',this.input.TeamGroup).pipe(first()).subscribe((
      out => {
        console.debug("TeamGroups", out);
        this.teams = out.Body[0].Teams.filter(i => i.Team != null);
        // Sort teams
        this.teams = this.teams.sort((n1,n2) => {
          if (n1.Team > n2.Team) {
              return 1;
          }
      
          if (n1.Team < n2.Team) {
              return -1;
          }
      
          return 0;
        });;
        this.teams.push({"Team": ""});
      }
    ));
  }  
  
  delete(team:string):void {
    this.svcAdmin.teamDelete(this.input.TeamGroup, team).pipe(first()).subscribe((
      out => {
        console.debug("Delete Team", out);
        this.getTeams();
      }
    ));    
  }

  add():void {
    let team:string = this.addFormGroup.controls.NewTeam.value.trim();

    if (team != "") {
      this.svcAdmin.teamAdd(this.input.TeamGroup, team).pipe(first()).subscribe((
        out => {
          console.debug("Add Team", out);
          this.getTeams();
          this.addFormGroup = this.fb.group({
            NewTeam: ''
          });
        }
      ));
    }
  }

  close() {
    this.dialogRef.close();
  }  
}

export interface TeamsDialogType {
  TeamGroup:number;
  Description:string;
}