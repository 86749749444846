<mat-dialog-content class="dialog-content">
    <div class="dialog-header">
      <h3 mat-dialog-title>Downtime Raw Data</h3>
    </div>
    <div class="table-container" *ngIf="this.tableData.length > 0"> <!-- style="overflow: auto;" -->
      <div *ngIf="!loadingData">
  
            <mat-table [dataSource]="tableData">
  
                  <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
                        <mat-header-cell *matHeaderCellDef>{{column}}</mat-header-cell>
                        <mat-cell *matCellDef="let d">{{d[column]}}</mat-cell>
                  </ng-container>
  
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>          
            </mat-table>
      </div>
      <div *ngIf="loadingData">
        <mat-spinner>Loading...</mat-spinner>
      </div>
    </div>
    <div align ="center" *ngIf="this.tableData.length == 0">
      No Data to Display
    </div>
  
    <div class="dialog-footer">
      <button mat-raised-button (click)="close()">Close</button>
    </div>
  </mat-dialog-content>