<div mat-dialog-title>
  <div style="font-weight: 600;" class="fx-row-center-center"> Problem Data Entry - Hour: {{selectedHour}}</div>
</div>

<div mat-dialog-content>
  <form [formGroup]="problemFormGroup">

    <mat-form-field *ngIf="manual" class="card-dialog" appearance="fill">
      <mat-label> Start Time </mat-label>
      <input matInput formControlName="form_startTime">
    </mat-form-field>

    <div *ngIf="selectedHour != problemHour">Start Time is in another hour, will allow saving but add to hour {{problemHour}}</div>
    <div *ngIf="problemFormGroup.get('form_startTime').invalid && problemFormGroup.get('form_startTime').errors && 
                  (problemFormGroup.get('form_startTime').dirty || problemFormGroup.get('form_startTime').touched)">
      <small class="error" *ngIf="problemFormGroup.get('form_startTime').hasError('outsideShift')">
        Start Time not within the currentshift ({{shiftStart}} - {{shiftEnd}})
      </small>
      <small class="error" *ngIf="problemFormGroup.get('form_startTime').hasError('futureTime')">
        Start Time entered is in the future, must start {{currentValidationTime}} or earlier.
      </small>
    </div>

    <mat-form-field *ngIf="manual" class="card-dialog" appearance="fill">
      <mat-label> Duration in Minutes </mat-label>
      <input matInput formControlName="form_minutes" type="number" placeholder="Ex. 0 - 59" min="0" max="59">
    </mat-form-field>

    <mat-form-field *ngIf="manual" class="card-dialog" appearance="fill">
      <mat-label> Duration in Seconds </mat-label>
      <input matInput formControlName="form_seconds" type="number" placeholder="Ex. 0 - 59" min="0" max="59">
    </mat-form-field>
    <div *ngIf="problemFormGroup.touched && problemFormGroup.errors && problemFormGroup.hasError('durationError')">
      <small class="error">
        Duration in Minutes and/or seconds required.
      </small>
    </div>

    <mat-form-field *ngIf="this.showTeam" class="card-dialog" appearance="fill">
      <mat-label> Team </mat-label>
      <mat-select formControlName="form_team">
        <mat-option *ngFor="let team of teamList" [value]="team.Team">
          {{team.Team}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="card-dialog" appearance="fill">
      <mat-label> Level 1 </mat-label>
      <mat-select formControlName="form_category">
        <mat-option *ngFor="let problem of problemLevelOne" [value]="problem" (click)="onSelectLevelOne(problem)">
          {{problem}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="problemFormGroup.get('form_category').invalid && problemFormGroup.get('form_category').errors && 
                  (problemFormGroup.get('form_category').dirty || problemFormGroup.get('form_category').touched)">
    </div>

    <mat-form-field class="card-dialog" appearance="fill">
      <mat-label> Level 2 </mat-label>
      <mat-select formControlName="form_categoryl2">
        <mat-option *ngFor="let problem of problemLevelTwo" [value]="problem" (click)="onSelectLevelTwo(problem)">
          {{problem}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="problemFormGroup.get('form_categoryl2').invalid && 
              problemFormGroup.get('form_categoryl2').errors && 
                (problemFormGroup.get('form_categoryl2').dirty || problemFormGroup.get('form_categoryl2').touched)">
    </div>

    <mat-form-field class="card-dialog" appearance="fill">
      <mat-label> Level 3 </mat-label>
      <mat-select formControlName="form_categoryl3">
        <mat-option *ngFor="let problem of problemLevelThree" [value]="problem">{{problem}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="problemFormGroup.get('form_categoryl3').invalid && 
              problemFormGroup.get('form_categoryl3').errors && 
                (problemFormGroup.get('form_categoryl3').dirty || problemFormGroup.get('form_categoryl3').touched)">
    </div>

    <mat-form-field class="card-dialog" appearance="fill">
      <mat-label>Notes: </mat-label>
      <textarea matInput formControlName="form_notes"></textarea>
    </mat-form-field>
    <div *ngIf="problemFormGroup.get('form_categoryl3').invalid && 
              problemFormGroup.get('form_categoryl3').errors && 
                (problemFormGroup.get('form_categoryl3').dirty || problemFormGroup.get('form_categoryl3').touched)">
    </div>
  </form>
  <section class="pa-matrix">
    <mat-checkbox class="add-matrix" [(ngModel)]="addToPAMatrix">Add to P & A Matrix</mat-checkbox>
  </section>
</div>
<div mat-dialog-actions class="fx-row-space-between">
  <div *ngIf="manual"><button *ngIf="problemIDs.length > 0" mat-raised-button color="warn" (click)="removeProblem('delete')">Delete</button></div>
  <div *ngIf="!manual"><button *ngIf="problemIDs.length > 0" mat-raised-button color="warn" (click)="removeProblem('clear')">Clear</button></div>
  <div class="fx-row">
    <div><button mat-raised-button color="primary" (click)="saveManualProblem()" [disabled]="problemFormGroup.invalid">Save</button></div>
    <div><button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button></div>
  </div>
</div>