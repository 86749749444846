<form [formGroup]="closeFormGroup">
    <h3 mat-dialog-title>Close Problem</h3>
    <div mat-dialog-content>
        
        <div> Are you sure you want to close Problem:</div>
        <div>{{problem.Description}}</div>

        <mat-form-field>
            <input matInput cdkTextareaAutosize placeholder="Close Date" formControlName="ClosedDate">
        </mat-form-field>

    </div>
    <div mat-dialog-actions>
        <button class="mat-raised-button" (click)="saveData()" [disabled]="closeFormGroup.invalid">Close Problem</button>
        <button class="mat-raised-button" (click)="onNoClick()">Cancel</button>
    </div>
</form>