import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OAService extends AbstractRestService {

  postInit(): void {
    console.debug("OAService Init Complete");
  }

  getCustomerDemand(environment:string,objectid:string, shiftdate:string, shift:string, currentShift:boolean):Observable<GenericLambdaReturn<CustomerDemand>> {
    let params:any={};

    params.Environment = environment;
    params.ObjectID = objectid;
    params.ShiftDate = shiftdate;
    params.Shift = shift;
    params.ShiftSelection = currentShift ? "CURRENT" : "PREVIOUS";

    return this.get({
      ApiResource: "/oa/demand",
      data: params,
      repeatCall: true,
      repeatRate: 120000
    });
  }

  addCustomerDemand(site:string, environment:string,objectid:string, add:CustomerDemandLine):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.ObjectID = objectid;
    params.ShiftDate = add.ShiftDate;
    params.Shift = add.Shift;
    params.JobID = add.JobID;
    params.Qty = add.Qty;

    return this.post({
      ApiResource: "/oa/demand",
      data: params,
      repeatCall: false
    });
  }

  updateCustomerDemand(site:string, environment:string,objectid:string, add:CustomerDemandLine):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.ObjectID = objectid;
    params.ShiftDate = add.ShiftDate;
    params.Shift = add.Shift;
    params.JobID = add.JobID;
    params.OldQty = add.OldQty;
    params.NewQty = add.Qty;
    params.Reason = "Making changes"

    return this.put({
      ApiResource: "/oa/demand",
      data: params,
      repeatCall: false
    });
  }

  deleteCustomerDemand(site:string, environment:string,objectid:string, add:CustomerDemandLine):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.ObjectID = objectid;
    params.ShiftDate = add.ShiftDate;
    params.Shift = add.Shift;
    params.JobID = add.JobID;
    params.Qty = add.Qty;
    params.Reason = "Making changes"

    return this.delete({
      ApiResource: "/oa/demand",
      data: params,
      repeatCall: false
    });
  }

  getCustomerDemandDefaults(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<DefaultCustomerDemand[]>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.get({
      ApiResource: "/oa/demand/default",
      data: params,
      repeatCall: true,
      repeatRate: 120000
    });
  }

  saveCustomerDemandDefault(environment:string,objectid:string, shift:string, jobid:number,newqty:number,newdays:string,olddays:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Shift = shift;
    params.Environment = environment;
    params.JobID = jobid;
    params.NewQuantity = newqty;
    params.NewDays = newdays;
    params.OldDays = olddays;

    return this.post({
      ApiResource: "/oa/demand/default",
      data: params,
      repeatCall: false
    });    

  }

  getPlannedDowntimeConfig(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.get({
      ApiResource: "/oa/config",
      data: params,
      repeatCall: true,
      repeatRate: 120000
    });
  }

  getPlannedDowntimeSched(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<PlannedSched[]>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.get({
      ApiResource: "/oa/eventsched",
      data: params,
      repeatCall: false
    });
  }

  addPlannedDowntimeSched(environment:string,objectid:string, updates:PlannedSched[]):Observable<GenericLambdaReturn<PlannedSched[]>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Environment = environment;
    params.Updates = updates;    

    return this.post({
      ApiResource: "/oa/eventsched",
      data: params,
      repeatCall: false
    });
  }

  deletePlannedDowntimeSched(environment:string,objectid:string, pkey:number[]):Observable<GenericLambdaReturn<PlannedSched[]>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Environment = environment;
    params.Pkey = pkey.join(',');

    return this.delete({
      ApiResource: "/oa/eventsched",
      data: params,
      repeatCall: false
    });
  }

  savePlannedDowntimeDefault(environment:string,objectid:string, shift:string, configid:number,newqty:number,newdays:string,olddays:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Shift = shift;
    params.Environment = environment;
    params.ConfigID = configid;
    params.NewQuantity = newqty;
    params.NewDays = newdays;
    params.OldDays = olddays;

    return this.post({
      ApiResource: "/oa/default",
      data: params,
      repeatCall: false
    });    

  }

  savePlannedDowntimConfig(environment:string,objectid:string, updates:ConfigItem[]):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Environment = environment;
    params.Updates = updates;

    return this.post({
      ApiResource: "/oa/config",
      data: params,
      repeatCall: false
    });    

  }

  getPlannedDowntimeDefaults(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.get({
      ApiResource: "/oa/default",
      data: params,
      repeatCall: true,
      repeatRate: 120000
    });
  }

  getCurrentPlannedDowntime(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.get({
      ApiResource: "/oa/event",
      data: params,
      repeatCall: true,
      repeatRate: 5000
    });
  }

  startPlannedDowntime(site:string,environment:string,objectid:string, configID:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;
    params.ConfigID = configID;

    return this.put({
      ApiResource: "/oa/event",
      data: params
    });
  }

  stopPlannedDowntime(site:string,environment:string,objectid:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;

    return this.delete({
      ApiResource: "/oa/event",
      data: params
    });
  }
}

export type ConfigItem = {
  ConfigID: number;
  Description: string;
  Duration: number;
  GracePeriod: number;
  Active: number; 
}

export type DefaultPlanned = {
  ConfigID: number;
  Shift: string;
  Description: string;
  Duration: number;
  Qty: number;
  DaysOfWeek: string;
  OldDaysOfWeek?: string;
}

export type PlannedSched = {
  pKey: number;
  ConfigID: number;
  ShiftDate: string;
  Shift: string;
  Description: string;
  Duration: number;
  GracePeriod: number;
  isDeleted?: boolean;
  isNew?: boolean;
}

export type DefaultCustomerDemand = {
  Shift: string;
  JobID: number;
  JobDescription: string;
  Qty: number;
  DaysOfWeek: string;
  OldDaysOfWeek?: string;
}

export type CustomerDemand = {
  SELECTED: CustomerDemandLine[];
  FUTURE?: CustomerDemandLine[];
}

export type CustomerDemandLine = {
  ShiftDate: string;
  Shift: string;
  Qty: number;
  Reason?: string;
  OldQty?: number;
  JobDescription: string;
  JobID: string;
  isDeleted?: boolean;
  isNew?: boolean;
}