<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>

<span *ngIf="object">
    <div class="togglebuttons">
        <mat-button-toggle-group    #toggleBtn="matButtonToggleGroup" value="problems">
            <mat-button-toggle value="problems">Problems</mat-button-toggle>
            <mat-button-toggle value="hourNotes">Hour Notes</mat-button-toggle>
            <mat-button-toggle *ngIf="object.PerJobObject != ''" value="partCount">Part Count</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div  style="padding: 15px">
        <app-metrics-header 
            (click)="navigateToProblems(-1)" 
            [object]="object"
            [section]="toggleBtn.value">
        </app-metrics-header>

        <app-metrics-detail-assembly
            *ngIf="object.ObjectType != 'FAB'"
            [object]="object" 
            [section]="toggleBtn.value">
        </app-metrics-detail-assembly>

        <app-metrics-detail-fab
            *ngIf="object.ObjectType == 'FAB'"
            [object]="object" 
            [section]="toggleBtn.value">
        </app-metrics-detail-fab>
    </div>
</span>